"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.sandvik = void 0;
exports.sandvik = {
  brandLogo: {
    url: 'http://ww1.prweb.com/prfiles/2014/04/14/12949811/Sandvik%20Coromant%20Logo.png',
    svg: null
  },
  colors: {
    primary: '#fae922',
    secondary: '#fae922',
    backgroundTertiary: '#333',
    bgStackPrimary: 'rgba(96,96,96)',
    bgStackSecondary: 'rgba(96,96,96)',
    bgStackTertiary: 'rgba(96,96,96)',
    borderSecondary: 'rgba(255,255,255,.15)',
    borderTertiary: 'rgba(255,255,255,.05)',
    buttonColors: {
      secondary: {
        text: '#fae922',
        border: '#fae922'
      },
      tertiary: {
        background: 'transparent',
        text: '#fae922',
        border: 'none'
      }
    }
  },
  gradients: {
    menu: `background: rgba(0,0,0,.8);`
  },
  typography: {},
  spacing: {}
};