"use strict";

var __createBinding = void 0 && (void 0).__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  var desc = Object.getOwnPropertyDescriptor(m, k);
  if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
    desc = {
      enumerable: true,
      get: function () {
        return m[k];
      }
    };
  }
  Object.defineProperty(o, k2, desc);
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});
var __setModuleDefault = void 0 && (void 0).__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, "default", {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o["default"] = v;
});
var __importStar = void 0 && (void 0).__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
  __setModuleDefault(result, mod);
  return result;
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GlobalNavFooter = void 0;
const react_1 = __importStar(require("react"));
const lodash_1 = require("lodash");
const utils_1 = require("../../utils");
const hooks_1 = require("../../../../hooks");
const DropdownButton_1 = require("../../../DropdownButton");
const foundations_1 = require("../../../../foundations");
const GlobalNavLogo_1 = require("./GlobalNavLogo");
const styles_1 = require("./styles");
const GlobalNavFooter = ({
  hideGlobalNavLogo,
  docs
}) => {
  const _ref = (0, hooks_1.useTranslation)(),
    t = _ref.t;
  const _ref2 = (0, utils_1.useGlobalNav)(),
    globalNavRef = _ref2.globalNavRef,
    setDisplayShadow = _ref2.setDisplayShadow,
    displayShadow = _ref2.displayShadow,
    setSize = _ref2.setSize,
    navbarConfigRef = _ref2.navbarConfigRef,
    designSystem = _ref2.designSystem,
    size = _ref2.size,
    disableHiddenView = _ref2.disableHiddenView;
  (0, react_1.useEffect)(() => {
    if (globalNavRef && globalNavRef.current) {
      const elem = globalNavRef.current;
      // set context value to scrollTop value
      const handleScroll = (0, lodash_1.throttle)(() => {
        const scrollPos = Math.round(elem.scrollHeight - elem.scrollTop);
        if (scrollPos > elem.clientHeight - 30 && scrollPos < elem.clientHeight + 30) {
          setDisplayShadow(false);
        } else if (!displayShadow) {
          setDisplayShadow(true);
        }
      }, 100);
      elem.addEventListener('scroll', handleScroll);
      return () => elem.removeEventListener('scroll', handleScroll);
    }
    // eslint-disable-next-line
  }, [displayShadow]);
  (0, react_1.useEffect)(() => {
    //Reset scroll on size change.
    if (globalNavRef.current) globalNavRef.current.scrollTop = 0;
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [size]);
  return react_1.default.createElement(styles_1.FooterContainer, null, displayShadow && react_1.default.createElement(styles_1.Shadow, {
    docs: docs,
    size: size
  }), react_1.default.createElement(styles_1.NavigationFooterWrapper, {
    docs: !!docs,
    displayShadow: displayShadow,
    size: size
  }, !hideGlobalNavLogo && react_1.default.createElement(GlobalNavLogo_1.GlobalNavLogo, {
    size: size
  }), hideGlobalNavLogo && size === 'Default' && react_1.default.createElement(foundations_1.Box, {
    width: "170px"
  }), react_1.default.createElement(foundations_1.Box, {
    mr: size === 'Default' ? 'l' : 'none',
    mb: size === 'Default' ? 'none' : 's'
  }, react_1.default.createElement(DropdownButton_1.DropdownButton, {
    buttonGroupRef: navbarConfigRef,
    mode: "tertiary",
    menuHorizontalAlign: "right",
    menuHorizontalOffset: foundations_1.spacing.l,
    menuVerticalAlign: "top",
    menuVerticalOffset: -124,
    icon: "Configure",
    iconFill: foundations_1.colors.tertiary,
    align: "right",
    usePortal: true,
    menuItems: [{
      items: [{
        text: !!designSystem ? 'Default' : t('components:navigationBar.sizes.default'),
        onClick: () => setSize('Default')
      }, {
        text: !!designSystem ? 'Minimal' : t('components:navigationBar.sizes.minimal'),
        onClick: () => setSize('Minimal')
      }, ...(disableHiddenView ? [] : [{
        text: !!designSystem ? 'Hidden' : t('components:navigationBar.sizes.hidden'),
        onClick: () => setSize('Hidden')
      }])]
    }]
  }))));
};
exports.GlobalNavFooter = GlobalNavFooter;