"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DurationUnitDefinition = void 0;
const UnitDefinition_1 = require("../UnitDefinition");
const Formatters_1 = require("../Formatters");
/**
 * Duration Unit Definition.
 */
class DurationUnitDefinition extends UnitDefinition_1.UnitDefinition {
    constructor(config) {
        super(config);
        this.scale = config.scale == null ? 1 : config.scale;
    }
    formatAsTokens(input) {
        if (input == null) {
            return [];
        }
        return (0, Formatters_1.formatDurationAsTokens)(input * this.scale);
    }
    formatDurationAsString(input, timeCondition) {
        return (0, Formatters_1.formatDurationAsString)(input, timeCondition);
    }
}
exports.DurationUnitDefinition = DurationUnitDefinition;
