"use strict";

var __createBinding = void 0 && (void 0).__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  var desc = Object.getOwnPropertyDescriptor(m, k);
  if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
    desc = {
      enumerable: true,
      get: function () {
        return m[k];
      }
    };
  }
  Object.defineProperty(o, k2, desc);
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});
var __exportStar = void 0 && (void 0).__exportStar || function (m, exports) {
  for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Modal = exports.Overlay = exports.ModalWrapper = exports.ModalSubTitle = exports.ModalTitle = exports.ModalHeader = exports.ModalFooter = exports.ModalBody = void 0;
var styles_1 = require("./styles");
Object.defineProperty(exports, "ModalBody", {
  enumerable: true,
  get: function () {
    return styles_1.ModalBody;
  }
});
Object.defineProperty(exports, "ModalFooter", {
  enumerable: true,
  get: function () {
    return styles_1.ModalFooter;
  }
});
Object.defineProperty(exports, "ModalHeader", {
  enumerable: true,
  get: function () {
    return styles_1.ModalHeader;
  }
});
Object.defineProperty(exports, "ModalTitle", {
  enumerable: true,
  get: function () {
    return styles_1.ModalTitle;
  }
});
Object.defineProperty(exports, "ModalSubTitle", {
  enumerable: true,
  get: function () {
    return styles_1.ModalSubTitle;
  }
});
Object.defineProperty(exports, "ModalWrapper", {
  enumerable: true,
  get: function () {
    return styles_1.ModalWrapper;
  }
});
Object.defineProperty(exports, "Overlay", {
  enumerable: true,
  get: function () {
    return styles_1.Overlay;
  }
});
var Modal_1 = require("./Modal");
Object.defineProperty(exports, "Modal", {
  enumerable: true,
  get: function () {
    return Modal_1.Modal;
  }
});
__exportStar(require("./ModalPortal"), exports);