"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Path = void 0;
const lodash_1 = require("lodash");
const assert_1 = require("./assert");
const Dictionary_1 = require("./Dictionary");
/**
 * A TypeError specific for Path helpers. The path where the error occurred will be appended to the message.
 */
class TypeErrorWithPath extends TypeError {
    constructor(message, path) {
        super(`${message} On path: "${path}".`);
        this.path = path;
    }
}
var Path;
(function (Path) {
    function of(value) {
        return value;
    }
    Path.of = of;
    /**
     * Get the path to the parent of a path.
     * @param path The path to find the parent of.
     */
    function parentPath(path) {
        if (path === '')
            return '';
        const segments = split(path);
        segments.pop();
        return segments.join('.');
    }
    Path.parentPath = parentPath;
    /**
     * Get the key of a path relative to its immediate parent.
     * @param path The full path.
     */
    function parentKey(path) {
        if (path === '')
            return '';
        const segments = split(path);
        return segments[segments.length - 1];
    }
    Path.parentKey = parentKey;
    function readKey(container, key, fullPath, autoIndexer) {
        if (key.length === 0) {
            throw new TypeErrorWithPath('Cannot read an empty key.', fullPath);
        }
        if (Array.isArray(container)) {
            try {
                const index = stringToArrayIndex(key, fullPath);
                if (index >= container.length) {
                    return undefined;
                }
                return container[index];
            }
            catch (e) {
                // inject the auto index if one is missing
                if (autoIndexer !== undefined) {
                    // pass this along as we still need the key fetching
                    return readKey(container[autoIndexer], key, fullPath, autoIndexer);
                }
                else {
                    // no auto indexer so assume we want to read across the entire array
                    if (container.length === 0) {
                        return [];
                    }
                    if (key in container[0]) {
                        return container.map(val => readKey(val, key, fullPath, autoIndexer));
                    }
                }
                throw e;
            }
        }
        if (Dictionary_1.Dictionary.isDictionary(container)) {
            return Dictionary_1.Dictionary.get(container, key);
        }
        if (container === undefined) {
            throw new TypeErrorWithPath('Cannot read key of undefined, expected an Array or Dictionary.', fullPath);
        }
        if (container === null) {
            throw new TypeErrorWithPath('Cannot read key of null, expected an Array or Dictionary.', fullPath);
        }
        throw new TypeErrorWithPath('Cannot read key of an invalid container, expected an Array or Dictionary.', fullPath);
    }
    function writeKey(container, key, value, fullPath) {
        if (key.length === 0) {
            throw new TypeErrorWithPath('Cannot write an empty key.', fullPath);
        }
        if (Array.isArray(container)) {
            const index = stringToArrayIndex(key, fullPath);
            if (index === container.length) {
                container.push(value);
            }
            else if (index > container.length) {
                throw new TypeErrorWithPath('Array index out of bounds.', fullPath);
            }
            container[index] = value;
        }
        else if (Dictionary_1.Dictionary.isDictionary(container)) {
            Dictionary_1.Dictionary.set(container, key, value);
        }
        else if (container === undefined) {
            throw new TypeErrorWithPath('Cannot write key of undefined, expected an Array or Dictionary.', fullPath);
        }
        else if (container === null) {
            throw new TypeErrorWithPath('Cannot write key of null, expected an Array or Dictionary.', fullPath);
        }
        else {
            throw new TypeErrorWithPath('Cannot write key of an invalid container, expected an Array or Dictionary.', fullPath);
        }
    }
    function stringToArrayIndex(key, fullPath) {
        for (let i = 0; i < key.length; i++) {
            const charCode = key.charCodeAt(i);
            if (charCode < 48 || charCode > 57) {
                throw new TypeErrorWithPath('Invalid array index.', fullPath);
            }
        }
        return Number(key);
    }
    function getInternal(root, path, fullPath, autoIndexer) {
        if (path === '')
            return root;
        let current = root;
        const parts = split(path);
        for (let i = 0; i < parts.length; i++) {
            const part = parts[i];
            if (current == null) {
                return undefined;
            }
            current = readKey(current, part, fullPath, autoIndexer);
        }
        return current;
    }
    function setInternal(root, path, value, fullPath = path) {
        if (path === '')
            return value;
        let current = root;
        const parts = split(path);
        for (let i = 0; i < parts.length - 1; i++) {
            const part = parts[i];
            current = readKey(current, part, fullPath);
        }
        writeKey(current, parts[parts.length - 1], value, fullPath);
        return root;
    }
    function get(root, path, autoIndexer) {
        return getInternal(root, path, path, autoIndexer);
    }
    Path.get = get;
    /**
     * Write a value to a path relative to a given root object.
     * Note: this mutates the object.
     * @param root The root object to write to.
     * @param path The path to the target, relative to the root.
     * @param value The value to write.
     */
    function set(root, path, value) {
        return setInternal(root, path, value, path);
    }
    Path.set = set;
    /**
     * Split a path into segments.
     * @param path The path to split.
     */
    function split(path) {
        if (path.length === 0)
            return [];
        return path
            .replace(/[[\]']+/g, '.') // replace ["key"] with ."key".
            .split('.')
            .filter(s => s && s.length);
    }
    Path.split = split;
    /**
     * Split a path in to 2 paths by the given key. Matches on the first match of the given key.
     * @param path The path to split.
     * @param key The key on which to break the path in 2
     * @example Path.splitAt('message.customer.message.name.text', 'customer') // ['message.customer', 'message.name.text']
     */
    function splitAt(path, key) {
        // match `key` entirely. if key is `resource` then should not match `resourceItems`.
        const keyRegExpWithWord = new RegExp(`(^|\\.)${key}(\\.|$)`);
        const match = path.match(keyRegExpWithWord);
        if (match) {
            let fieldPath = path.slice(0, (match.index || 0) + match[0].length);
            let childPath = path.slice((match.index || 0) + match[0].length);
            if (fieldPath.endsWith('.')) {
                fieldPath = fieldPath.slice(0, fieldPath.length - 1);
            }
            if (childPath.startsWith(`.`)) {
                childPath = childPath.slice(1);
            }
            return [fieldPath, childPath];
        }
        return [path, ''];
    }
    Path.splitAt = splitAt;
    /**
     * Split a path in to 2 paths by the given key. Matches on the last match of the given key.
     * @param path The path to split.
     * @param key The key on which to break the path in 2
     * @example Path.splitAtLast('message.customer.message.name.text', 'message') // ['message.customer.message', 'name.text']
     */
    function splitAtLast(path, key) {
        const parts = Path.split(path);
        const indexOf = parts.lastIndexOf(key);
        const fieldPath = Path.join(parts.slice(0, indexOf + 1));
        const childPath = Path.join(parts.slice(indexOf + 1));
        return [fieldPath, childPath];
    }
    Path.splitAtLast = splitAtLast;
    /**
     * Join a string and clean any empty values
     * @param parts
     */
    function join(parts) {
        return (0, lodash_1.compact)(parts).join('.');
    }
    Path.join = join;
    /**
     * Remove * or .1. indexers from the path
     */
    function removeIndexers(path) {
        return join(split(path).filter(f => f !== '*' && !Number.isInteger(Number(f))));
    }
    Path.removeIndexers = removeIndexers;
    /**
     * Concatenate the given paths.
     * @param paths The paths to concatenate.
     */
    function concat(...paths) {
        return paths
            .flat()
            .filter(segment => segment !== null && segment !== undefined && String(segment).length > 0 && segment !== '.')
            .map(s => {
            if (typeof s === 'string' && s.length > 0) {
                // restore a quoted value with array notation
                return String(split(s).join('.')).replace('"', `[`).replace('"', `]`);
            }
            return s;
        })
            .join('.')
            .replace('[', `["`)
            .replace('.[', `[`)
            .replace(']', `"]`);
    }
    Path.concat = concat;
    /**
     * Is the parent path a container of the given child path?
     * @param parent The parent path.
     * @param child The child path.
     */
    function contains(parent, child) {
        if (parent.length > child.length) {
            // To be a valid parent, all child paths must be at least as long.
            return false;
        }
        if (parent === child) {
            return true;
        }
        const parentSegments = split(parent);
        const childSegments = split(child);
        for (let i = 0; i < parentSegments.length; i++) {
            const p = parentSegments[i];
            const c = childSegments[i];
            if (p !== c)
                return false;
        }
        return true;
    }
    Path.contains = contains;
    /**
     * Similar to contains but for many paths
     */
    function containsAny(parents, children) {
        return children.some(child => parents.some(parent => contains(parent, child)));
    }
    Path.containsAny = containsAny;
    /**
     * The path ends with the given target
     */
    function endsWith(path, target) {
        if (!path)
            return false;
        const split = Path.split(path);
        if (!split || split.length < 1)
            return false;
        return split[split.length - 1] === target;
    }
    Path.endsWith = endsWith;
    /**
     * Helper to split a path with an index on the end
     * @example Path.getIndexAndPath('message.lineItems.9'); // ['message.lineItems', 9]
     */
    function getIndexAndPath(path) {
        const split = Path.split(path);
        const index = Number(split.pop());
        assert_1.Assert.isNumber(index, `Expected a path ending in an index but got "${path}"`);
        return [Path.join(split), Number(index)];
    }
    Path.getIndexAndPath = getIndexAndPath;
    /**
     * Remove collisions and keep the least granular path
     */
    function removeCollisions(paths) {
        paths.sort((a, b) => a.length - b.length);
        const result = [];
        const set = new Set();
        for (const path of paths) {
            let isColliding = false;
            for (const existing of set) {
                if (path.startsWith(existing + '.')) {
                    // current path is a subpath of an existing path
                    isColliding = true;
                    break;
                }
                if (existing.startsWith(path + '.')) {
                    // current path is a superpath of an existing path
                    set.delete(existing);
                    result.splice(result.indexOf(existing), 1);
                }
            }
            if (!isColliding) {
                set.add(path);
                result.push(path);
            }
        }
        return result;
    }
    Path.removeCollisions = removeCollisions;
    /**
     * Remove prefix path if matched, otherwise do nothing.
     */
    function removePrefixPath(path, prefix) {
        if (path.startsWith(prefix)) {
            if (path === prefix) {
                return '';
            }
            return path.replace(`${prefix}.`, '');
        }
        return path;
    }
    Path.removePrefixPath = removePrefixPath;
})(Path || (exports.Path = Path = {}));
