"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatNumberAsNumber = exports.formatNumberAsString = exports.formatNumberAsTokens = void 0;
const FormatToken_1 = require("../FormatToken");
/**
 * Format a number as tokens.
 * @param input The input to format.
 * @param precision The precision with which to format the input.
 * @param symbol The symbol for the number.
 */
function formatNumberAsTokens(input, precision, symbol) {
    if (input == null || isNaN(+input)) {
        return [];
    }
    const value = precision == null ? String(+input) : (+input).toFixed(precision);
    const tokens = [
        {
            type: 'value',
            value
        }
    ];
    if (symbol != null && symbol.length > 0) {
        tokens.push({
            type: 'symbol',
            symbol
        });
    }
    return tokens;
}
exports.formatNumberAsTokens = formatNumberAsTokens;
/**
 * Format a number as a string.
 * @param input The input to format.
 * @param precision The precision with which to format the input.
 * @param symbol The symbol for the number.
 */
function formatNumberAsString(input, precision, symbol) {
    return (0, FormatToken_1.joinFormatTokens)(formatNumberAsTokens(input, precision, symbol), '') || '-';
}
exports.formatNumberAsString = formatNumberAsString;
/**
 * Format a number as a rounded number.
 * @param input The input to format.
 * @param precision The precision with which to format the input.
 */
function formatNumberAsNumber(input, precision) {
    if (input == null || isNaN(+input)) {
        return null;
    }
    return precision == null || isNaN(precision) ? +input : Number((+input).toFixed(precision));
}
exports.formatNumberAsNumber = formatNumberAsNumber;
