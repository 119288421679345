"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
var __createBinding = void 0 && (void 0).__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  var desc = Object.getOwnPropertyDescriptor(m, k);
  if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
    desc = {
      enumerable: true,
      get: function () {
        return m[k];
      }
    };
  }
  Object.defineProperty(o, k2, desc);
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});
var __setModuleDefault = void 0 && (void 0).__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, "default", {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o["default"] = v;
});
var __importStar = void 0 && (void 0).__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
  __setModuleDefault(result, mod);
  return result;
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Messages = void 0;
const react_1 = __importStar(require("react"));
const utils_core_1 = require("@oqton/utils-core");
const Message_1 = require("../Message");
const Icon_1 = require("../../../Icon");
const Text_1 = require("../../../Text");
const foundations_1 = require("../../../../foundations");
const styles_1 = require("./styles");
const Messages = ({
  messages,
  onScrollTop,
  isLoading,
  messageContainerStyles
}) => {
  const containerRef = (0, react_1.useRef)(null);
  const _ref = (0, react_1.useState)(0),
    _ref2 = _slicedToArray(_ref, 2),
    scrollPos = _ref2[0],
    setScrollPos = _ref2[1];
  const handleScroll = e => {
    setScrollPos(e.currentTarget.scrollTop);
    const scrolledToTop = e.currentTarget.scrollHeight + e.currentTarget.scrollTop === e.currentTarget.clientHeight;
    if (scrolledToTop && onScrollTop) onScrollTop();
  };
  (0, react_1.useLayoutEffect)(() => {
    if (containerRef === null || containerRef === void 0 ? void 0 : containerRef.current) containerRef.current.scrollTop = scrollPos;
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages]);
  const content = (0, react_1.useMemo)(() => {
    return messages === null || messages === void 0 ? void 0 : messages.reduce((prev, cur) => {
      var _a;
      const lastMessage = prev[prev.length - 1];
      const currDate = new Date(cur.date);
      const lastDate = new Date((_a = lastMessage === null || lastMessage === void 0 ? void 0 : lastMessage.date) !== null && _a !== void 0 ? _a : 0);
      const diff = currDate.getTime() - lastDate.getTime();
      if (diff > 10000 * 60) return [...prev, {
        date: currDate,
        isSelf: false
      }, cur];
      return [...prev, cur];
    }, []);
  }, [messages]);
  return react_1.default.createElement(styles_1.ScrollWrapper, {
    ref: containerRef,
    onScroll: onScrollTop && handleScroll,
    justifyContent: isLoading ? 'center' : undefined,
    style: messageContainerStyles
  }, isLoading ? react_1.default.createElement(foundations_1.Flex, {
    justifyContent: "center",
    alignItems: "center"
  }, react_1.default.createElement(Icon_1.Icon, {
    name: "LoadingIndeterminateRing"
  })) : react_1.default.createElement(styles_1.MessagesWrapper, null, !content ? react_1.default.createElement(styles_1.Placeholder, null, react_1.default.createElement(Text_1.Text, null, "no messages to show")) : content.map((item, index) => {
    if (item.content) return react_1.default.createElement(Message_1.Message, _objectSpread({
      key: `message-${index}`
    }, item));
    return react_1.default.createElement(foundations_1.Flex, {
      key: `date-${index}`,
      justifyContent: "center",
      mt: "s"
    }, react_1.default.createElement(Text_1.Text, null, utils_core_1.date.format({
      date: item.date,
      format: 'YYYY-MM-DD, HH:mm'
    })));
  })));
};
exports.Messages = Messages;