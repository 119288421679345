"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WindowTitle = void 0;
const react_1 = require("react");
function updateWindowTitle(title, titleFallback) {
  // set document title
  if (typeof window !== `undefined`) {
    if (title) {
      document.title = `${title}`;
    } else {
      document.title = `${titleFallback}`;
    }
  }
}
function WindowTitle({
  title = ''
}) {
  (0, react_1.useEffect)(() => {
    const titleFallback = 'Oqton';
    // set document title
    updateWindowTitle(title, titleFallback);
    return () => {
      updateWindowTitle(null, titleFallback);
    };
  }, [title]);
  return null;
}
exports.WindowTitle = WindowTitle;