"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MillimetrePerSecond = exports.MetrePerSecond = void 0;
const SpeedUnitDefinition_1 = require("./../UnitTypes/SpeedUnitDefinition");
exports.MetrePerSecond = new SpeedUnitDefinition_1.SpeedUnitDefinition({
    name: 'METRE PER SECOND',
    label: 'Metre per Second',
    symbol: 'm/s',
    scaleToSI: 1
});
exports.MillimetrePerSecond = new SpeedUnitDefinition_1.SpeedUnitDefinition({
    name: 'MILLIMETRE PER SECOND',
    label: 'Millimetre per Second',
    symbol: 'mm/s',
    scaleToSI: 0.001
});
