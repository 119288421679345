"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getShiftPeriod = exports.getShiftEnd = exports.getShiftStart = void 0;
const utils_core_1 = require("@oqton/utils-core");
const getShiftTimesForDay_1 = require("./utils/getShiftTimesForDay");
/**
 * Get the start of a shift.
 */
function getShiftStart(targetDate, shift) {
  const d = utils_core_1.date.getUTCDate(targetDate);
  if (shift.days.includes(utils_core_1.date.get({
    date: d,
    unit: 'day'
  }))) {
    const _ref = (0, getShiftTimesForDay_1.getShiftTimesForDay)(shift, d),
      shiftStart = _ref.shiftStart;
    return utils_core_1.date.getISOString({
      date: shiftStart
    });
  }
  return targetDate;
}
exports.getShiftStart = getShiftStart;
/**
 * Get the end of a shift.
 */
function getShiftEnd(targetDate, shift) {
  const d = utils_core_1.date.getUTCDate(targetDate);
  if (shift.days.includes(utils_core_1.date.get({
    date: d,
    unit: 'day'
  }))) {
    const _ref2 = (0, getShiftTimesForDay_1.getShiftTimesForDay)(shift, d),
      shiftEnd = _ref2.shiftEnd;
    return utils_core_1.date.getISOString({
      date: shiftEnd
    });
  }
  return targetDate;
}
exports.getShiftEnd = getShiftEnd;
const getShiftPeriod = (shift, start, end) => {
  const shifts = [];
  const startDate = utils_core_1.date.getUTCDate(start);
  const endDate = utils_core_1.date.getUTCDate(end);
  let currentDay = startDate;
  function processDay() {
    const shiftStart = utils_core_1.date.getUTCDate(getShiftStart(utils_core_1.date.getDate(currentDay), shift));
    const shiftEnd = utils_core_1.date.getUTCDate(getShiftEnd(utils_core_1.date.getDate(currentDay), shift));
    if (shiftEnd < endDate) {
      shifts.push({
        start: shiftStart.toISOString(),
        end: shiftEnd.toISOString()
      });
      currentDay = utils_core_1.date.add({
        date: shiftEnd,
        amount: 1,
        unit: 'day'
      });
      if (currentDay < endDate) {
        // continue to process forward
        processDay();
      }
    }
  }
  // initial
  processDay();
  return shifts;
};
exports.getShiftPeriod = getShiftPeriod;