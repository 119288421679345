"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useTranslation = void 0;
const react_1 = require("react");
const theme_1 = require("../theme");
const useTranslation = () => {
  const _ref = (0, theme_1.useSettingsContext)(),
    translate = _ref.translate;
  const t = (0, react_1.useCallback)((input, options) => {
    if (translate) {
      return translate(input, options);
    }
    return input;
  }, [translate]);
  return {
    t
  };
};
exports.useTranslation = useTranslation;