"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.multiChartColors = exports.sortChartColors = exports.randomHsl = void 0;
/**
 * Random color generator
 * This function generates vibrant, "evenly spaced" colours (i.e. no clustering).
 * @param {number} numOfSteps
 * @param {number} step
 * @return {string}
 */
const randomHsl = (numOfSteps = 1, step = 1) => `hsla(${360 / numOfSteps * step}, ${~~(50 + 30 / numOfSteps * step)}%, 40%, 1)`;
exports.randomHsl = randomHsl;
/**
 * Sort Chart colors
 */
const sortChartColor = colorArray => {
  const sorted = [];
  let colorOrder = [3, 5, 1, 4, 2, 6, 0];
  colorOrder.forEach(i => {
    sorted.push(colorArray[i]);
  });
  return sorted;
};
const sortChartColors = chartColors => {
  const chartColorsKeys = Object.keys(chartColors);
  let colors = _objectSpread({}, chartColors);
  chartColorsKeys.forEach(color => colors[color] = sortChartColor(chartColors[color]));
  return colors;
};
exports.sortChartColors = sortChartColors;
const multiChartColors = chartColors => {
  const monochrome = ['blue', 'red', 'gray', 'yellow', 'violet', 'green', 'indigo', 'orange', 'pink', 'teal'];
  let colorOrder = [3, 5, 1, 4, 2, 6, 0];
  let colors = [];
  colorOrder.forEach(order => {
    monochrome.forEach(element => {
      colors.push(chartColors[element][order]);
    });
  });
  return colors;
};
exports.multiChartColors = multiChartColors;