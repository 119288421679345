"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PSI = exports.MegaPascal = exports.KiloPascal = exports.Pascal = void 0;
const PressureUnitDefinition_1 = require("../UnitTypes/PressureUnitDefinition");
exports.Pascal = new PressureUnitDefinition_1.PressureUnitDefinition({
    name: 'PASCAL',
    label: 'Pascal',
    symbol: 'Pa',
    scaleToSI: 1
});
exports.KiloPascal = new PressureUnitDefinition_1.PressureUnitDefinition({
    name: 'KILOPASCAL',
    label: 'Kilo Pascal',
    symbol: 'kPa',
    scaleToSI: 1000 // [Pa] = 1000*[kPa]
});
exports.MegaPascal = new PressureUnitDefinition_1.PressureUnitDefinition({
    name: 'MEGAPASCAL',
    label: 'Mega Pascal',
    symbol: 'MPa',
    scaleToSI: 1000000
});
exports.PSI = new PressureUnitDefinition_1.PressureUnitDefinition({
    name: 'PSI',
    label: 'Pound Force Per Square Inch',
    symbol: 'PSI',
    scaleToSI: 6894.757 // [Pa] = 6894.757*[PSI]
});
