"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getLocalStorageSize = void 0;
const getLocalStorageSize = () => {
  var _a, _b;
  const size = (_b = (_a = localStorage.getItem('localSize')) === null || _a === void 0 ? void 0 : _a.replace(/"/g, '')) !== null && _b !== void 0 ? _b : 'Default';
  if (!['Default', 'Minimal', 'Hidden'].includes(size)) return 'Default';
  return size;
};
exports.getLocalStorageSize = getLocalStorageSize;