"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useAutofocus = void 0;
const react_1 = require("react");
/**
 * @return {Object} details
 * @property {Object} details.ref - The ref the consumer should use
 */
function useAutofocus() {
  // all we need is a single ref that the consumer can add to an <input>
  const ref = (0, react_1.useRef)();
  // after mounting, focus the element
  (0, react_1.useEffect)(() => {
    var _a, _b;
    (_a = ref === null || ref === void 0 ? void 0 : ref.current) === null || _a === void 0 ? void 0 : _a.focus();
    (_b = ref === null || ref === void 0 ? void 0 : ref.current) === null || _b === void 0 ? void 0 : _b.select();
  }, [ref]);
  return ref;
}
exports.useAutofocus = useAutofocus;