"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getConfigValue = exports.setConfig = void 0;
const object_1 = require("./object");
let _configStore = {};
const setConfig = (settings = {}) => {
    // Set default cluster domain
    let CLUSTER_DOMAIN = (settings === null || settings === void 0 ? void 0 : settings.clusterDomain) || 'cloud.oqton.ai';
    let EXECUTIONER_API_PATH = (settings === null || settings === void 0 ? void 0 : settings.executionerApiPath) || 'exec';
    if (process.env.REACT_APP_CLUSTER_DOMAIN) {
        // Checks for command line variable passed in
        CLUSTER_DOMAIN = process.env.REACT_APP_CLUSTER_DOMAIN;
    }
    else if (global.OQTON_CLUSTER_DOMAIN && !global.OQTON_CLUSTER_DOMAIN.startsWith('%')) {
        // check if we have a CLUSTER_DOMAIN defined in our index.html (written by nginx)
        CLUSTER_DOMAIN = global.OQTON_CLUSTER_DOMAIN;
    }
    if (process.env.REACT_APP_EXECUTIONER_API_PATH) {
        // Checks for command line variable passed in
        EXECUTIONER_API_PATH = process.env.REACT_APP_EXECUTIONER_API_PATH;
    }
    else if (global.EXECUTIONER_API_PATH && !global.EXECUTIONER_API_PATH.startsWith('%')) {
        // check if we have a EXECUTIONER_API_PATH defined in our index.html (written by nginx)
        EXECUTIONER_API_PATH = global.EXECUTIONER_API_PATH;
    }
    const API_ENDPOINT = process.env.REACT_APP_DEBUG_API_ENDPOINT || `https://api.${CLUSTER_DOMAIN}`;
    const ITEM_ENDPOINT = process.env.REACT_APP_DEBUG_ITEM_ENDPOINT || API_ENDPOINT;
    const ITEM_WEBSOCKET_ENDPOINT = process.env.REACT_APP_DEBUG_ITEM_WEBSOCKET_ENDPOINT || `wss://socket.${CLUSTER_DOMAIN}`;
    const IOT_WEBSOCKET_ENDPOINT = process.env.REACT_APP_DEBUG_IOT_WEBSOCKET_ENDPOINT || `wss://websockets.${CLUSTER_DOMAIN}`;
    const TENANT_API_ENDPOINT = process.env.REACT_APP_DEBUG_TENANT_API_ENDPOINT || API_ENDPOINT;
    const FILES_API_ENDPOINT = process.env.REACT_APP_DEBUG_FILES_API_ENDPOINT || API_ENDPOINT;
    const ALIYUN_API_ENDPOINT = process.env.REACT_APP_DEBUG_ALIYUN_API_ENDPOINT || API_ENDPOINT;
    const EXECUTIONER_API_ENDPOINT = process.env.REACT_APP_DEBUG_EXECUTIONER_API_ENDPOINT ||
        `${API_ENDPOINT}/${EXECUTIONER_API_PATH.replace(/^\/+/g, '')}`;
    const SIGNING_API_ENDPOINT = process.env.REACT_APP_DEBUG_SIGNING_API_ENDPOINT || API_ENDPOINT;
    const DMC_URL = `https://graphql-ws.${CLUSTER_DOMAIN}/graphql-ws/graphql`;
    const urls = {
        CLUSTER_DOMAIN,
        API_ENDPOINT,
        ITEM_ENDPOINT,
        ITEM_WEBSOCKET_ENDPOINT,
        IOT_WEBSOCKET_ENDPOINT,
        TENANT_API_ENDPOINT,
        FILES_API_ENDPOINT,
        ALIYUN_API_ENDPOINT,
        EXECUTIONER_API_ENDPOINT,
        DMC_URL,
        SIGNING_API_ENDPOINT
    };
    const integrations = {};
    // Extrapolate & set defaults
    const { appName = 'OQTON', itemSchemaVersion = 'SCHEMA_VERSION_LAYOUT_BUILDER_MIGRATE_INDEX', socketSchemaVersion = 'SOCKET_VERSION_ACKED_SUBS', toolVersions = {}, ...rest } = settings;
    // Store
    _configStore = {
        appName,
        urls,
        clusterDomain: CLUSTER_DOMAIN,
        executionerApiPath: EXECUTIONER_API_PATH,
        itemSchemaVersion,
        socketSchemaVersion,
        toolVersions,
        integrations,
        ...rest
    };
};
exports.setConfig = setConfig;
const getConfigValue = (key) => {
    const v = (0, object_1.getIn)(_configStore, key);
    if (!v) {
        //TODO: should throw an error. console.log should not be used in node
        //eslint-disable-next-line no-console
        console.error("🌋 Trying to get a Config Value that doesn't exist: ", key);
    }
    return v;
};
exports.getConfigValue = getConfigValue;
