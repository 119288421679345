"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.boxShadows = void 0;
exports.boxShadows = {
  primary: '0 2px 10px #00000012',
  secondary: '0 4px 16px #00000010',
  tertiary: '0 1px 5px #00000010',
  quaternary: '0 4px 24px #00000025',
  dragElement: '0px 32px 80px #00000030'
};