"use strict";

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
var __createBinding = void 0 && (void 0).__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  var desc = Object.getOwnPropertyDescriptor(m, k);
  if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
    desc = {
      enumerable: true,
      get: function () {
        return m[k];
      }
    };
  }
  Object.defineProperty(o, k2, desc);
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});
var __setModuleDefault = void 0 && (void 0).__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, "default", {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o["default"] = v;
});
var __importStar = void 0 && (void 0).__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
  __setModuleDefault(result, mod);
  return result;
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TimelineBar = void 0;
const react_1 = __importStar(require("react"));
const utils_core_1 = require("@oqton/utils-core");
const index_1 = require("../index");
const index_2 = require("./index");
function getClickedTimeFromPixels(x, scale) {
  // Convert the px value in to ms value
  const positionInMs = x / scale.msInPixels;
  // Add the start and the click position ms together to get the actual time value clicked
  const clickedTimePositionMs = new Date(scale.start).getTime() + positionInMs;
  return new Date(clickedTimePositionMs);
}
function TimelineBar(props) {
  var _a;
  const scale = props.scale,
    onSelected = props.onSelected,
    _props$rows = props.rows,
    rows = _props$rows === void 0 ? [] : _props$rows,
    allowSelecting = props.allowSelecting;
  const _ref = (0, react_1.useState)(null),
    _ref2 = _slicedToArray(_ref, 2),
    selected = _ref2[0],
    setSelected = _ref2[1];
  const wrapperRef = (0, react_1.useRef)(null);
  function setSelectedFromPixels(x) {
    // calculate the time position clicked form the px value
    const clickedTime = getClickedTimeFromPixels(x, scale);
    // round the value by split precision to it lines up with hour for example when in daily time precision
    const roundedTime = new Date((0, utils_core_1.toStartOfTime)(clickedTime, scale.splitPrecision));
    // set the selected state
    setSelected(roundedTime);
    if (onSelected) {
      onSelected(clickedTime, roundedTime, x);
    }
  }
  function handleClick(e) {
    var _a;
    // position of the click container from left
    const wrappingPositionX = (_a = wrapperRef.current) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect().left;
    // position of the click relative to the container
    const relativeClickPositionX = e.clientX - (wrappingPositionX !== null && wrappingPositionX !== void 0 ? wrappingPositionX : 0);
    setSelectedFromPixels(relativeClickPositionX);
  }
  const durationBetweenBeginAndSelected = (0, utils_core_1.getTimeDurationInMs)((_a = scale.timeUnits[0]) !== null && _a !== void 0 ? _a : 0, selected !== null && selected !== void 0 ? selected : 0);
  const left = durationBetweenBeginAndSelected * scale.msInPixels;
  // ToDo: These are being hard coded for FormNext but probably should come from data eventually
  const shift = {
    days: [1, 2, 3, 4, 5],
    startTime: '07:00:00',
    endTime: '19:00:00'
  };
  return react_1.default.createElement(index_2.TimelineBarStyles.HorizontalBar, {
    ref: wrapperRef,
    rows: rows.length,
    onClick: handleClick
  }, react_1.default.createElement(index_2.TimelineBarStyles.TimelineBarBackgound, {
    timeUnits: scale.timeUnits.length,
    rows: rows.length
  }), react_1.default.createElement(index_2.TimelineBarStyles.TimeIndicatorAccentStripe, {
    timeUnits: scale.timeUnits.length,
    rows: rows.length
  }), react_1.default.createElement(index_1.TimelineShifts, {
    shift: shift,
    scale: scale,
    rows: rows.length
  }), react_1.default.createElement(index_1.TimelineBarItems, {
    rows: rows,
    scale: scale
  }), selected && allowSelecting && react_1.default.createElement(index_2.TimelineBarStyles.SelectedTimeSlotMarker, {
    left: left,
    rows: rows.length
  }));
}
exports.TimelineBar = TimelineBar;