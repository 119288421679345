"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.formatIncludesDays = exports.convertSecsToTimeStringDays = exports.convertSecsToTimeString = exports.convertSecsToDuration = exports.getTotalSecondsDays = exports.getTotalSeconds = void 0;
const getDays = val => val * 60 * 60 * 24;
const getHours = val => val * 60 * 60;
const getMinutes = val => val * 60;
const getTotalSeconds = (division, val, value) => {
  let time = null;
  switch (division) {
    case 'hour':
      time = getHours(parseInt(val, 10));
      break;
    case 'minute':
      time = getMinutes(parseInt(val, 10));
      break;
    case 'second':
      time = parseInt(val, 10);
      break;
  }
  const duration = (0, exports.convertSecsToDuration)(value);
  if (!duration) {
    return time;
  }
  const mergedTime = _objectSpread(_objectSpread({}, duration), {
    [division]: val
  });
  return getHours(parseInt(mergedTime.hour, 10)) + getMinutes(parseInt(mergedTime.minute, 10)) + parseInt(mergedTime.second, 10);
};
exports.getTotalSeconds = getTotalSeconds;
const getTotalSecondsDays = (division, val, value) => {
  let time = null;
  switch (division) {
    case 'day':
      time = getDays(parseInt(val, 10));
      break;
    case 'hour':
      time = getHours(parseInt(val, 10));
      break;
    case 'minute':
      time = getMinutes(parseInt(val, 10));
      break;
    case 'second':
      time = parseInt(val, 10);
      break;
  }
  const duration = convertSecsToDurationDays(value);
  if (!duration) {
    return time;
  }
  const mergedTime = _objectSpread(_objectSpread({}, duration), {
    [division]: val
  });
  return getDays(parseInt(mergedTime.day, 10)) + getHours(parseInt(mergedTime.hour, 10)) + getMinutes(parseInt(mergedTime.minute, 10)) + parseInt(mergedTime.second, 10);
};
exports.getTotalSecondsDays = getTotalSecondsDays;
const convertSecsToDuration = value => {
  let _value = value || 0;
  if (value === null) {
    return null;
  }
  if (value < 0) {
    _value = Math.abs(value);
  }
  let secondsInAMinute = 60;
  let secondsInAnHour = 60 * secondsInAMinute;
  let secondsInADay = 24 * secondsInAnHour;
  // extract days
  const d = Math.floor(_value / secondsInADay);
  // extract hours
  let hourSeconds = _value % secondsInADay;
  let h = Math.floor(hourSeconds / secondsInAnHour);
  // extract minutes
  let minuteSeconds = hourSeconds % secondsInAnHour;
  const m = Math.floor(minuteSeconds / secondsInAMinute);
  // extract the remaining seconds
  let remainingSeconds = minuteSeconds % secondsInAMinute;
  const s = Math.ceil(remainingSeconds);
  h += d * 24;
  const hour = h.toString().padStart(2, '0');
  const minute = `0${m || 0}`.slice(-2);
  const second = `0${s || 0}`.slice(-2);
  return {
    hour,
    minute,
    second
  };
};
exports.convertSecsToDuration = convertSecsToDuration;
const convertSecsToDurationDays = value => {
  let _value = value || 0;
  if (value === null) {
    return null;
  }
  if (value < 0) {
    _value = Math.abs(value);
  }
  let secondsInAMinute = 60;
  let secondsInAnHour = 60 * secondsInAMinute;
  let secondsInADay = 24 * secondsInAnHour;
  // extract days
  const d = Math.floor(_value / secondsInADay);
  // extract hours
  let hourSeconds = _value % secondsInADay;
  const h = Math.floor(hourSeconds / secondsInAnHour);
  // extract minutes
  let minuteSeconds = hourSeconds % secondsInAnHour;
  const m = Math.floor(minuteSeconds / secondsInAMinute);
  // extract the remaining seconds
  let remainingSeconds = minuteSeconds % secondsInAMinute;
  const s = Math.ceil(remainingSeconds);
  const day = d.toString().padStart(2, '0');
  const hour = h.toString().padStart(2, '0');
  const minute = `0${m || 0}`.slice(-2);
  const second = `0${s || 0}`.slice(-2);
  return {
    day,
    hour,
    minute,
    second
  };
};
const convertSecsToTimeString = value => {
  const duration = (0, exports.convertSecsToDuration)(value);
  if (!duration) {
    return null;
  }
  return `${duration.hour}:${duration.minute}:${duration.second}`;
};
exports.convertSecsToTimeString = convertSecsToTimeString;
const convertSecsToTimeStringDays = value => {
  const duration = convertSecsToDurationDays(value);
  if (!duration) {
    return null;
  }
  return `${duration.day}:${duration.hour}:${duration.minute}:${duration.second}`;
};
exports.convertSecsToTimeStringDays = convertSecsToTimeStringDays;
const formatIncludesDays = format => {
  return format.startsWith('d:');
};
exports.formatIncludesDays = formatIncludesDays;