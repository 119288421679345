"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NumberUnitDefinition = void 0;
const UnitDefinition_1 = require("../UnitDefinition");
const Formatters_1 = require("../Formatters");
/**
 * Numeric Unit Definition.
 */
class NumberUnitDefinition extends UnitDefinition_1.UnitDefinition {
    constructor(config) {
        super(config);
        this.precision = config.precision == null ? 2 : config.precision;
        this.scale = config.scale == null ? 1 : config.scale;
        this.offset = config.offset == null ? 0 : config.offset;
    }
    /**
     * Protected method to calculate the actual value of a unit based on the provided scale and offset.
     * @param input The input to calculate the value for.
     */
    calculateScaleAndOffset(input) {
        const { scale, offset } = this;
        return input * scale + offset;
    }
    formatAsTokens(input, precision = this.precision, withSymbol = true) {
        const { symbol } = this;
        if (input == null || isNaN(+input)) {
            return [];
        }
        return (0, Formatters_1.formatNumberAsTokens)(this.calculateScaleAndOffset(input), precision, withSymbol ? symbol : undefined);
    }
    /**
     * A function to return the value of a numerical unit as a number
     * Useful for example if the numerical value is needed after a conversion
     * @param input The input to format.
     * @param precision The precision with which to format the input.
     */
    convert(input, precision = 'maxPrecision') {
        if (input == null || isNaN(+input)) {
            return null;
        }
        return (0, Formatters_1.formatNumberAsNumber)(this.calculateScaleAndOffset(input), precision === 'maxPrecision' ? null : precision);
    }
}
exports.NumberUnitDefinition = NumberUnitDefinition;
