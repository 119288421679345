"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
var __createBinding = void 0 && (void 0).__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  var desc = Object.getOwnPropertyDescriptor(m, k);
  if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
    desc = {
      enumerable: true,
      get: function () {
        return m[k];
      }
    };
  }
  Object.defineProperty(o, k2, desc);
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});
var __setModuleDefault = void 0 && (void 0).__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, "default", {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o["default"] = v;
});
var __importStar = void 0 && (void 0).__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
  __setModuleDefault(result, mod);
  return result;
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Control = void 0;
const react_1 = __importStar(require("react"));
const react_select_1 = require("react-select");
const index_1 = require("../index");
const Tooltip_1 = require("../../Tooltip");
const foundations_1 = require("../../../foundations");
const SelectLabel = props => {
  const selectProps = props.selectProps,
    hasValue = props.hasValue,
    isDisabled = props.isDisabled,
    isFocused = props.isFocused,
    handleLabelClick = props.handleLabelClick,
    width = props.width;
  const placeholder = selectProps.placeholder,
    tooltip = selectProps.tooltip,
    label = selectProps.label,
    required = selectProps.required;
  const finalLabel = required && label ? label + ' *' : label;
  const labelComponent = react_1.default.createElement(index_1.SelectStyles.SelectLabel, {
    onClick: handleLabelClick,
    hasValue: hasValue || placeholder,
    isDisabled: isDisabled,
    // don't allow control to be focused if it's disabled
    isFocused: !isDisabled && isFocused,
    selectProps: selectProps,
    tooltip: tooltip,
    width: width
  }, finalLabel || '');
  if (tooltip) return react_1.default.createElement(Tooltip_1.Tooltip, {
    content: tooltip,
    placement: "top-start",
    arrow: true
  }, labelComponent);
  return labelComponent;
};
const Control = props => {
  var _a;
  const selectProps = props.selectProps,
    isDisabled = props.isDisabled,
    isFocused = props.isFocused;
  const openMenu = selectProps.openMenu;
  const handleLabelClick = (0, react_1.useCallback)(() => {
    if (openMenu && typeof openMenu === 'function') openMenu();
  }, [openMenu]);
  const containerRef = (0, react_1.useRef)(null);
  return react_1.default.createElement(foundations_1.Box, {
    ref: containerRef
  }, react_1.default.createElement(react_select_1.components.Control
  // don't allow control to be focused if it's disabled
  , _objectSpread(_objectSpread({}, props), {}, {
    isFocused: !isDisabled && isFocused
  })), react_1.default.createElement(index_1.SelectStyles.SelectLabelWrapper, {
    onClick: handleLabelClick,
    title: props.selectProps.label
  }, react_1.default.createElement(SelectLabel, _objectSpread(_objectSpread({}, props), {}, {
    handleLabelClick: handleLabelClick,
    width: (_a = containerRef.current) === null || _a === void 0 ? void 0 : _a.clientWidth
  }))));
};
exports.Control = Control;