"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
var __createBinding = void 0 && (void 0).__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  var desc = Object.getOwnPropertyDescriptor(m, k);
  if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
    desc = {
      enumerable: true,
      get: function () {
        return m[k];
      }
    };
  }
  Object.defineProperty(o, k2, desc);
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});
var __setModuleDefault = void 0 && (void 0).__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, "default", {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o["default"] = v;
});
var __importStar = void 0 && (void 0).__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
  __setModuleDefault(result, mod);
  return result;
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Content = void 0;
const react_1 = __importStar(require("react"));
const lodash_1 = require("lodash");
const Section_1 = require("../Section");
const Tabs_1 = require("../../../Tabs");
const hooks_1 = require("../../../../hooks");
const foundations_1 = require("../../../../foundations");
const Content = ({
  sections,
  additionalTabs,
  isTabless,
  selectedTabId,
  disabledTabs
}) => {
  const _ref = (0, hooks_1.useTranslation)(),
    t = _ref.t;
  const availableTabs = (0, react_1.useMemo)(() => [{
    text: t('pages:LayoutEditor.tabs.data.label'),
    id: 'data',
    disabled: disabledTabs === null || disabledTabs === void 0 ? void 0 : disabledTabs.includes('data')
  }, {
    text: t('pages:LayoutEditor.tabs.presentation.label'),
    id: 'presentation',
    disabled: disabledTabs === null || disabledTabs === void 0 ? void 0 : disabledTabs.includes('presentation')
  }, ...(additionalTabs ? additionalTabs === null || additionalTabs === void 0 ? void 0 : additionalTabs.map(({
    title,
    id
  }) => ({
    text: title,
    id,
    disabled: disabledTabs === null || disabledTabs === void 0 ? void 0 : disabledTabs.includes(id)
  })) : [])], [t, additionalTabs, disabledTabs]);
  const getSectionsInTab = (0, react_1.useCallback)(id => sections.filter(section => section.tabId === id && section.title), [sections]);
  const selectedTabIndex = (0, react_1.useMemo)(() => availableTabs.findIndex(tab => tab.id === selectedTabId), [availableTabs, selectedTabId]);
  const getSections = (0, react_1.useCallback)(id => {
    return (0, lodash_1.compact)(sections.map((section, index) => {
      var _a;
      if (section.tabId !== id) return null;
      return react_1.default.createElement(Section_1.Section, {
        section: section,
        amount: getSectionsInTab(id).length,
        key: `section-${section.tabId}-${(_a = section.title) !== null && _a !== void 0 ? _a : index}`
      });
    }));
  }, [getSectionsInTab, sections]);
  const tabs = (0, react_1.useMemo)(() => {
    return (0, lodash_1.compact)(availableTabs.map(tab => {
      let sections = getSections(tab.id);
      if ((sections === null || sections === void 0 ? void 0 : sections.length) === 0) sections = null;
      return sections ? _objectSpread(_objectSpread({}, tab), {}, {
        content: sections
      }) : null;
    }));
  }, [availableTabs, getSections]);
  if (isTabless) return react_1.default.createElement(foundations_1.Box, {
    mb: "xxxl"
  }, getSections(undefined));
  return react_1.default.createElement(Tabs_1.Tabs, {
    mb: "xxxl",
    sticky: 48,
    items: tabs,
    selectedIndex: selectedTabIndex,
    navigationBox: {
      px: 'm',
      style: {
        backgroundColor: foundations_1.colors.white
      }
    }
  });
};
exports.Content = Content;