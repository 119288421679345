"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConvertableUnitDefinition = void 0;
const NumberUnitDefinition_1 = require("./NumberUnitDefinition");
/**
 * Convertable Unit Definition.
 *
 * Base class for convertable units, that provides two additional parameters
 * (scaleToSI and offsetToSI) and a convertTo function to enable linear
 * conversion between units.
 *
 * Converts as such:
 * [Value SI unit] = [Value custom unit] * scaleToSI + offsetToSI
 * e.g. [Value Kelvin] = [Value MilliCelsius] * 0.001 - 273.15
 */
class ConvertableUnitDefinition extends NumberUnitDefinition_1.NumberUnitDefinition {
    constructor(config) {
        super(config);
        this.scaleToSI = config.scaleToSI == null ? 1 : config.scaleToSI;
        this.offsetToSI = config.offsetToSI == null ? 0 : config.offsetToSI;
    }
    /**
     * Converts the unit to a new convertable unit with pre-set scale and offset, to do the conversion
     * @param targetUnit The target unit to convert to.
     * @returns A new convertable unit that has the correct scale and zeroValue pre-set to convert the incoming values
     */
    convertTo(targetUnit) {
        return new ConvertableUnitDefinition({
            name: targetUnit.name,
            label: targetUnit.label,
            symbol: targetUnit.symbol,
            scaleToSI: this.scaleToSI,
            offsetToSI: this.offsetToSI,
            offset: (targetUnit.scale * (this.offsetToSI - targetUnit.offsetToSI)) / targetUnit.scaleToSI,
            scale: targetUnit.scale * (this.scaleToSI / targetUnit.scaleToSI)
        });
    }
}
exports.ConvertableUnitDefinition = ConvertableUnitDefinition;
