"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Acres = exports.Hectares = exports.SquareInch = exports.SquareMile = exports.SquareYard = exports.SquareFeet = exports.SquareKilometre = exports.SquareMillimetre = exports.SquareCentimetre = exports.SquareMetre = void 0;
const AreaUnitDefinition_1 = require("../UnitTypes/AreaUnitDefinition");
exports.SquareMetre = new AreaUnitDefinition_1.AreaUnitDefinition({
    name: 'SQUAREMETRE',
    label: 'SquareMetre',
    symbol: 'm²',
    scaleToSI: 1
});
exports.SquareCentimetre = new AreaUnitDefinition_1.AreaUnitDefinition({
    name: 'SQUARECENTIMETRE',
    label: 'SquareCentimetre',
    symbol: 'cm²',
    scaleToSI: 1e-4
});
exports.SquareMillimetre = new AreaUnitDefinition_1.AreaUnitDefinition({
    name: 'SQUAREMILLIMETRE',
    label: 'SquareMillimetre',
    symbol: 'mm²',
    scaleToSI: 1e-6
});
exports.SquareKilometre = new AreaUnitDefinition_1.AreaUnitDefinition({
    name: 'SQUAREKILOMETRE',
    label: 'SquareKilometre',
    symbol: 'km²',
    scaleToSI: 1e6
});
exports.SquareFeet = new AreaUnitDefinition_1.AreaUnitDefinition({
    name: 'SQUAREFEET',
    label: 'SquareFeet',
    symbol: 'ft²',
    scaleToSI: 0.09290304
});
exports.SquareYard = new AreaUnitDefinition_1.AreaUnitDefinition({
    name: 'SQUAREYARD',
    label: 'SquareYard',
    symbol: 'yd²',
    scaleToSI: 0.83612736
});
exports.SquareMile = new AreaUnitDefinition_1.AreaUnitDefinition({
    name: 'SQUAREMILE',
    label: 'SquareMile',
    symbol: 'mi²',
    scaleToSI: 2589988.110336
});
exports.SquareInch = new AreaUnitDefinition_1.AreaUnitDefinition({
    name: 'SQUAREINCH',
    label: 'SquareInch',
    symbol: 'in²',
    scaleToSI: 0.00064516
});
exports.Hectares = new AreaUnitDefinition_1.AreaUnitDefinition({
    name: 'HECTARES',
    label: 'Hectares',
    symbol: 'ha',
    scaleToSI: 10000
});
exports.Acres = new AreaUnitDefinition_1.AreaUnitDefinition({
    name: 'ACRES',
    label: 'Acres',
    symbol: 'acre',
    scaleToSI: 4046.8564224
});
