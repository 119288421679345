"use strict";
var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _TaskQueue_tasks, _TaskQueue_lastQueue, _TaskQueue_lastResults, _TaskQueue_running, _TaskQueue_index;
Object.defineProperty(exports, "__esModule", { value: true });
exports.TaskQueue = void 0;
/**
 * Manages a queue of async tasks
 */
class TaskQueue {
    /**
     * Creates an instance of TaskQueue.
     */
    constructor(tasks = []) {
        /**
         * The tasks.
         */
        _TaskQueue_tasks.set(this, void 0);
        /**
         * The most recent running queue
         */
        _TaskQueue_lastQueue.set(this, void 0);
        /**
         * Results of a last queue execution
         */
        _TaskQueue_lastResults.set(this, []);
        /**
         * `true` if the queue is running
         */
        _TaskQueue_running.set(this, false);
        /**
         * An index at which the queue is currently running
         */
        _TaskQueue_index.set(this, -1);
        __classPrivateFieldSet(this, _TaskQueue_tasks, tasks, "f");
    }
    /**
     * Results of a last queue execution.
     *
     * Empty, if execution hasn't started yet.
     */
    get lastResults() {
        return __classPrivateFieldGet(this, _TaskQueue_lastResults, "f").slice();
    }
    /**
     * `true` if the queue is running
     */
    get isRunning() {
        return __classPrivateFieldGet(this, _TaskQueue_running, "f");
    }
    /**
     * A task index at which the queue is currently running
     *
     * `-1` if the queue is not currently running
     */
    get currentTaskIndex() {
        return __classPrivateFieldGet(this, _TaskQueue_index, "f");
    }
    /**
     * A task which is currently running in the queue
     *
     * `undefined` if the queue is not currently running
     */
    get currentRunningTask() {
        return __classPrivateFieldGet(this, _TaskQueue_tasks, "f")[this.currentTaskIndex];
    }
    /**
     * Remove a task from queue by its index
     *
     * @returns a removed task if found
     */
    dequeueByIndex(index) {
        if (index > -1 && __classPrivateFieldGet(this, _TaskQueue_tasks, "f")[index]) {
            const task = __classPrivateFieldGet(this, _TaskQueue_tasks, "f")[index];
            __classPrivateFieldGet(this, _TaskQueue_tasks, "f").splice(index, 1);
            return task;
        }
        return undefined;
    }
    /**
     * Remove a task from queue by its reference. If no task was given, removes the last task.
     * @param {T} [task] a reference to the task function to remove by
     * @returns a removed task if found
     */
    dequeueByTask(task) {
        return this.dequeueByIndex(task ? __classPrivateFieldGet(this, _TaskQueue_tasks, "f").findIndex(t => t === task) : this.length - 1);
    }
    /**
     * Start executing the queue from a certain point.
     * Halts if `running` flag is off (pause has occured).
     *
     * If any error in any task is raised - pauses queue execution and throws the error upstack.
     *
     * @param {number} from
     *    A point to execute a queue from.
     * @param {Array<any>} lastResults
     *    Saved results to add to.
     * @param {boolean} running
     *    Internal indication if the method should continue running.
     *    Passing `false` will result in the method not running.
     * @returns a promise that resolves to task results array when the queue is finished
     */
    launchFrom(from, lastResults = [], running = true) {
        __classPrivateFieldSet(this, _TaskQueue_lastResults, lastResults, "f");
        if (from < __classPrivateFieldGet(this, _TaskQueue_tasks, "f").length) {
            __classPrivateFieldSet(this, _TaskQueue_index, from, "f");
            if (running) {
                __classPrivateFieldSet(this, _TaskQueue_running, running, "f");
                return __classPrivateFieldGet(this, _TaskQueue_tasks, "f")[from]().then(result => {
                    lastResults.push(result);
                    return this.launchFrom(from + 1, lastResults, __classPrivateFieldGet(this, _TaskQueue_running, "f"));
                }, e => {
                    __classPrivateFieldSet(this, _TaskQueue_running, false, "f");
                    throw e;
                });
            }
        }
        else {
            __classPrivateFieldSet(this, _TaskQueue_running, false, "f");
        }
        return Promise.resolve(this.lastResults);
    }
    /**
     * Adds one or more tasks to queue.
     */
    enqueue(...tasks) {
        __classPrivateFieldGet(this, _TaskQueue_tasks, "f").push.apply(__classPrivateFieldGet(this, _TaskQueue_tasks, "f"), tasks);
    }
    dequeue() {
        const arg = arguments[0];
        if (typeof arg === 'number') {
            return this.dequeueByIndex(arg);
        }
        else if (typeof arg === 'function' || !arg) {
            return this.dequeueByTask(arg);
        }
        throw new TypeError("Argument's type must either be number, function or undefined!");
    }
    /**
     * Removes the last task from the queue.
     * @returns a removed task if found
     */
    pop() {
        return this.dequeue();
    }
    /**
     * Get last added task without mutating the queue
     */
    peek() {
        return __classPrivateFieldGet(this, _TaskQueue_tasks, "f")[__classPrivateFieldGet(this, _TaskQueue_tasks, "f").length - 1];
    }
    /**
     * Last added task
     */
    get last() {
        return this.peek();
    }
    /**
     * Queue length
     */
    get length() {
        return __classPrivateFieldGet(this, _TaskQueue_tasks, "f").length;
    }
    /**
     * Completely clears the queue and stops executions.
     */
    clear() {
        __classPrivateFieldSet(this, _TaskQueue_index, -1, "f");
        __classPrivateFieldSet(this, _TaskQueue_running, false, "f");
        __classPrivateFieldSet(this, _TaskQueue_lastQueue, undefined, "f");
        __classPrivateFieldGet(this, _TaskQueue_tasks, "f").splice(0);
    }
    /**
     * Pauses the queue's execution flow after a nearest task is completed.
     *
     * @returns a promise that resolves as soon as the queue is paused
     */
    pause() {
        __classPrivateFieldSet(this, _TaskQueue_running, false, "f");
        return __classPrivateFieldGet(this, _TaskQueue_lastQueue, "f") || Promise.resolve([]);
    }
    /**
     * Resumes a previously paused queue.
     *
     * @returns a promise that resolves as soon as the queue is completed
     */
    resume() {
        return (__classPrivateFieldSet(this, _TaskQueue_lastQueue, this.launchFrom(__classPrivateFieldGet(this, _TaskQueue_index, "f"), __classPrivateFieldGet(this, _TaskQueue_lastResults, "f")), "f"));
    }
    /**
     * Stops queue execution and clears results.
     *
     * @returns a promise that resolves to queue results (or `undefined` if the queue has already been stopped) as soon as the queue completely stops executing
     */
    async stop() {
        const results = await this.pause();
        this.clear();
        return results;
    }
    /**
     * Starts task queue execution.
     *
     * Returns currently executed queue if execution already started
     *
     * @returns promise with task results as an array sorted by task execution order
     */
    start() {
        return (__classPrivateFieldSet(this, _TaskQueue_lastQueue, __classPrivateFieldGet(this, _TaskQueue_lastQueue, "f") || this.launchFrom(0), "f"));
    }
}
exports.TaskQueue = TaskQueue;
_TaskQueue_tasks = new WeakMap(), _TaskQueue_lastQueue = new WeakMap(), _TaskQueue_lastResults = new WeakMap(), _TaskQueue_running = new WeakMap(), _TaskQueue_index = new WeakMap();
