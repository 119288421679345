"use strict";

const _excluded = ["align", "mode", "menuWidth", "loading", "menuItems", "width", "text", "onClose", "onOpen", "size", "disabled", "icon", "buttonGroupRef", "isOpen", "usePortal", "iconFill", "menuHorizontalAlign", "menuHorizontalOffset", "menuVerticalAlign", "menuVerticalOffset", "overrides", "buttonStyle", "closeOnBlur", "allowPropagation"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }
function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }
var __createBinding = void 0 && (void 0).__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  var desc = Object.getOwnPropertyDescriptor(m, k);
  if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
    desc = {
      enumerable: true,
      get: function () {
        return m[k];
      }
    };
  }
  Object.defineProperty(o, k2, desc);
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});
var __setModuleDefault = void 0 && (void 0).__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, "default", {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o["default"] = v;
});
var __importStar = void 0 && (void 0).__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
  __setModuleDefault(result, mod);
  return result;
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DropdownButton = void 0;
const react_1 = __importStar(require("react"));
const Portal_1 = require("../Portal");
const __1 = require("..");
const dropdownHelpers_1 = require("../../utils/dropdownHelpers");
const DropdownButtonStyles_1 = require("./DropdownButtonStyles");
exports.DropdownButton = (0, react_1.forwardRef)((props, ref) => {
  const _props$align = props.align,
    align = _props$align === void 0 ? 'right' : _props$align,
    _props$mode = props.mode,
    mode = _props$mode === void 0 ? 'secondary' : _props$mode,
    menuWidth = props.menuWidth,
    _props$loading = props.loading,
    loading = _props$loading === void 0 ? false : _props$loading,
    _props$menuItems = props.menuItems,
    menuItems = _props$menuItems === void 0 ? [] : _props$menuItems,
    width = props.width,
    text = props.text,
    onClose = props.onClose,
    onOpen = props.onOpen,
    size = props.size,
    disabled = props.disabled,
    icon = props.icon,
    buttonGroupRef = props.buttonGroupRef,
    _props$isOpen = props.isOpen,
    isOpen = _props$isOpen === void 0 ? false : _props$isOpen,
    _props$usePortal = props.usePortal,
    usePortal = _props$usePortal === void 0 ? false : _props$usePortal,
    iconFill = props.iconFill,
    menuHorizontalAlign = props.menuHorizontalAlign,
    menuHorizontalOffset = props.menuHorizontalOffset,
    menuVerticalAlign = props.menuVerticalAlign,
    menuVerticalOffset = props.menuVerticalOffset,
    overrides = props.overrides,
    buttonStyle = props.buttonStyle,
    _props$closeOnBlur = props.closeOnBlur,
    closeOnBlur = _props$closeOnBlur === void 0 ? true : _props$closeOnBlur,
    _props$allowPropagati = props.allowPropagation,
    allowPropagation = _props$allowPropagati === void 0 ? false : _props$allowPropagati,
    rest = _objectWithoutProperties(props, _excluded);
  const _ref = (0, react_1.useState)(false),
    _ref2 = _slicedToArray(_ref, 2),
    isMenuOpen = _ref2[0],
    setIsMenuOpen = _ref2[1];
  const _ref3 = (0, react_1.useState)((0, dropdownHelpers_1.getUniqueId)()),
    _ref4 = _slicedToArray(_ref3, 1),
    id = _ref4[0];
  const outerElement = (0, react_1.useRef)(null);
  const toggleOpen = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const closeMenu = (0, react_1.useCallback)(() => {
    setIsMenuOpen(false);
    if (onClose) {
      onClose();
    }
  }, [onClose]);
  (0, react_1.useEffect)(() => {
    setIsMenuOpen(isOpen);
  }, [isOpen]);
  (0, react_1.useEffect)(() => {
    const closeIfBlur = e => {
      const current = outerElement.current;
      if (current !== null && !current.contains(e.target)) {
        closeMenu();
      }
    };
    if (isMenuOpen && onOpen) onOpen();
    if (typeof window !== `undefined` && closeOnBlur) {
      //click events get negated by stopPropagation
      document.body.addEventListener('mouseup', closeIfBlur);
    }
    return () => {
      if (typeof window !== `undefined`) {
        document.body.removeEventListener('mouseup', closeIfBlur);
      }
    };
  }, [closeMenu, closeOnBlur, isMenuOpen, onClose, onOpen]);
  const handleOnButtonKeyDown = e => {
    const current = outerElement.current;
    switch (e.key) {
      case 'ArrowDown':
        if (!isMenuOpen) {
          toggleOpen();
        }
        if (isMenuOpen && current !== null) {
          current.querySelectorAll('button')[1].focus();
        }
        break;
      case 'ENTER':
        if (!isMenuOpen) {
          toggleOpen();
        }
        if (isMenuOpen && current !== null) {
          current.querySelectorAll('button')[1].focus();
        }
        break;
      case 'ArrowUp':
        if (isMenuOpen) {
          toggleOpen();
        }
        break;
      default:
        break;
    }
  };
  const handleOnClick = e => {
    e.preventDefault();
    if (!allowPropagation) {
      e.stopPropagation();
    }
    // Prevent button from focusing onClick
    // this is already done on buttons but not on the trigger used here for the icon only button
    // if e.detail !== 0 it means a return was fired on a button so we keep the button focussed
    if (ref && ref.current && e.detail !== 0) {
      ref.current.blur();
    }
    toggleOpen();
  };
  const dropdownList = react_1.default.createElement(__1.DropdownList, {
    "aria-labelledby": `dropdown-button-${id}`,
    menuItems: menuItems,
    align: align,
    listWidth: menuWidth,
    onSelect: closeMenu,
    menuHorizontalAlign: menuHorizontalAlign,
    menuVerticalAlign: menuVerticalAlign,
    menuHorizontalOffset: menuHorizontalOffset,
    menuVerticalOffset: menuVerticalOffset,
    overrides: overrides
  });
  const portalDropdown = react_1.default.createElement(Portal_1.Portal, {
    maintainPosition: true
  }, parentRect => {
    if (!parentRect || (parentRect === null || parentRect === void 0 ? void 0 : parentRect.top) === undefined) return null;
    return react_1.default.createElement(DropdownButtonStyles_1.PanelWrapper, {
      parentRect: parentRect,
      ref: outerElement
    }, dropdownList);
  });
  const DropdownListComponent = () => usePortal ? portalDropdown : dropdownList;
  return react_1.default.createElement(DropdownButtonStyles_1.Wrapper, _objectSpread(_objectSpread({}, rest), {}, {
    ref: outerElement
  }), react_1.default.createElement(__1.Button, {
    ref: ref,
    "data-testid": "dropdown-button",
    id: `dropdown-button-${id}`,
    "aria-haspopup": "true",
    "aria-expanded": isOpen,
    mode: mode,
    onClick: handleOnClick,
    icon: icon || loading && 'LoadingIndeterminateRing' || undefined,
    onKeyDown: handleOnButtonKeyDown,
    text: text,
    size: size,
    width: width,
    disabled: disabled || menuItems.length === 0,
    buttonGroupRef: buttonGroupRef,
    iconFill: iconFill,
    buttonStyle: buttonStyle,
    iconRight: true
  }), isMenuOpen && react_1.default.createElement(DropdownListComponent, null));
});