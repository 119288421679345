import styled from 'styled-components';

import { Box } from '@oqton/design-system';
import React from 'react';
import { Logo } from './Logo';

const defaultWidth = 360;

const ModalBackground = styled(Box)`
  position: fixed;
  top: 50%;
  left: 50%;
  max-height: 100%;
  overflow-x: auto;
  transform: translate(-50%, -50%);
  max-width: ${p => p.width || defaultWidth}px;
`;

const ModalWhite = styled(Box).attrs({ frameStyle: 'bgStackPrimary' })`
  padding: ${p => p.theme.spacing.xl}px;
`;

export const Modal = ({ children }: { children: React.ReactNode }) => (
  <ModalBackground>
    <Box style={{ textAlign: 'center', marginBottom: 64 }}>{Logo}</Box>
    <ModalWhite>{children}</ModalWhite>
  </ModalBackground>
);
