"use strict";

var __createBinding = void 0 && (void 0).__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  var desc = Object.getOwnPropertyDescriptor(m, k);
  if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
    desc = {
      enumerable: true,
      get: function () {
        return m[k];
      }
    };
  }
  Object.defineProperty(o, k2, desc);
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});
var __setModuleDefault = void 0 && (void 0).__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, "default", {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o["default"] = v;
});
var __importStar = void 0 && (void 0).__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
  __setModuleDefault(result, mod);
  return result;
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BasicLayoutHeader = void 0;
const react_1 = __importStar(require("react"));
const lodash_1 = require("lodash");
const utils_1 = require("../../utils");
const foundations_1 = require("../../../../foundations");
const theme_1 = require("../../../../theme");
const components_1 = require("../../../../components");
/**
 * If you use the header as a component you won't be able to override its values with the BasicLayoutContext.
 * Use headerProps on the main BasicLayout component to override them with the context.
 */
const BasicLayoutHeader = ({
  title: initTitle,
  headerButtons: initHeaderButtons,
  actions: initActions,
  leftContent,
  rightContent,
  titleDropdown
}) => {
  const _ref = (0, components_1.useGlobalNav)(),
    size = _ref.size;
  const _ref2 = (0, utils_1.useBasicLayoutContext)(),
    contextTitle = _ref2.title,
    contextHeaderButtons = _ref2.headerButtons,
    contextActions = _ref2.actions;
  const _ref3 = (0, theme_1.useResponsiveContext)(),
    isCurrentBreakpointLargerThan = _ref3.isCurrentBreakpointLargerThan;
  const isWiderThanM = isCurrentBreakpointLargerThan('m');
  const title = (0, react_1.useMemo)(() => contextTitle !== null && contextTitle !== void 0 ? contextTitle : initTitle, [contextTitle, initTitle]);
  const actions = (0, react_1.useMemo)(() => contextActions !== null && contextActions !== void 0 ? contextActions : initActions, [contextActions, initActions]);
  const headerButtons = (0, react_1.useMemo)(() => contextHeaderButtons !== null && contextHeaderButtons !== void 0 ? contextHeaderButtons : initHeaderButtons, [contextHeaderButtons, initHeaderButtons]);
  const leftTitle = titleDropdown ? react_1.default.createElement(foundations_1.Flex, null, react_1.default.createElement(components_1.Heading, null, title), react_1.default.createElement(foundations_1.Box, null, titleDropdown)) : react_1.default.createElement(components_1.Heading, {
    showTitle: (0, lodash_1.isString)(title)
  }, title);
  return react_1.default.createElement(foundations_1.Grid, {
    mt: "xl",
    mb: "l",
    pl: size === 'Hidden' ? 'xxl' : 'none',
    gridTemplateColumns: `1fr ${rightContent ? 'auto' : 'auto auto'}`
  }, isWiderThanM && (leftContent || leftTitle), rightContent || react_1.default.createElement(react_1.default.Fragment, null, react_1.default.createElement(foundations_1.Flex, null, actions), react_1.default.createElement(foundations_1.Flex, {
    ml: {
      xs: 's',
      m: 'xl',
      l: 'xxxl',
      xl: '184px'
    },
    gap: "xs"
  }, headerButtons)));
};
exports.BasicLayoutHeader = BasicLayoutHeader;