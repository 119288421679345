"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
var __createBinding = void 0 && (void 0).__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  var desc = Object.getOwnPropertyDescriptor(m, k);
  if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
    desc = {
      enumerable: true,
      get: function () {
        return m[k];
      }
    };
  }
  Object.defineProperty(o, k2, desc);
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});
var __setModuleDefault = void 0 && (void 0).__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, "default", {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o["default"] = v;
});
var __importStar = void 0 && (void 0).__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
  __setModuleDefault(result, mod);
  return result;
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TimeInput = void 0;
const react_1 = __importStar(require("react"));
const TimeInputField_1 = require("./TimeInputField");
const utils_1 = require("./utils");
const TIME_DIVIDER = '/';
const focus = element => element && element.focus();
const TimeInput = ({
  format,
  className,
  pickerRef,
  disabled,
  maxTime,
  minTime,
  required,
  onChange,
  onBlur,
  value,
  onFocus
}) => {
  const _ref = (0, react_1.useState)(null),
    _ref2 = _slicedToArray(_ref, 2),
    hour = _ref2[0],
    setHour = _ref2[1];
  const _ref3 = (0, react_1.useState)(null),
    _ref4 = _slicedToArray(_ref3, 2),
    minute = _ref4[0],
    setMinute = _ref4[1];
  const _ref5 = (0, react_1.useState)(null),
    _ref6 = _slicedToArray(_ref5, 2),
    second = _ref6[0],
    setSecond = _ref6[1];
  (0, react_1.useEffect)(() => {
    var _a, _b, _c;
    if (value) {
      const val = value.split(':');
      setHour((_a = val[0]) !== null && _a !== void 0 ? _a : null);
      setMinute((_b = val[1]) !== null && _b !== void 0 ? _b : null);
      if (format === 'HH:MM:SS') setSecond((_c = val[2]) !== null && _c !== void 0 ? _c : null);
    }
  }, [value, format]);
  const onClick = event => {
    var _a;
    if (event.target === event.currentTarget) {
      const firstInput = (_a = event.target) === null || _a === void 0 ? void 0 : _a.children[1];
      focus(firstInput);
    }
  };
  const onKeyDown = event => {
    switch (event.key) {
      case 'ArrowLeft':
      case 'ArrowRight':
      case TIME_DIVIDER:
        {
          event.preventDefault();
          const input = event.target;
          const property = event.key === 'ArrowLeft' ? 'previousElementSibling' : 'nextElementSibling';
          const nextInput = input && (0, utils_1.findInput)(input, property);
          focus(nextInput);
          break;
        }
      default:
    }
  };
  /**
   * Called when non-native date input is changed.
   */
  const handleOnChange = ({
    value,
    event
  }) => {
    const target = event.target.name;
    const val = value !== null ? value.toString() : null;
    switch (target) {
      case 'hour':
        setHour(val);
        if (onChange) onChange({
          hour: val,
          minute,
          second
        });
        break;
      case 'minute':
        setMinute(val);
        if (onChange) onChange({
          hour,
          minute: val,
          second
        });
        break;
      case 'second':
        setSecond(val);
        if (onChange) onChange({
          hour,
          minute,
          second: val
        });
        break;
      default:
        throw new Error(`Unrecognised time input, ${target}`);
    }
  };
  const commonInputProps = () => {
    return {
      className,
      disabled,
      maxTime,
      minTime,
      onChange: handleOnChange,
      onKeyDown: onKeyDown,
      onBlur,
      // This is only for showing validity when editing
      required
    };
  };
  const renderHour = currentMatch => {
    if (currentMatch && currentMatch.length > 2) throw new Error(`Unsupported token: ${currentMatch}`);
    return react_1.default.createElement(TimeInputField_1.TimeInputField, _objectSpread(_objectSpread({
      key: "hour",
      name: "hour",
      placeholder: "HH"
    }, commonInputProps()), {}, {
      value: hour,
      onFocus: onFocus
    }));
  };
  const renderMinute = currentMatch => {
    if (currentMatch && currentMatch.length > 2) throw new Error(`Unsupported token: ${currentMatch}`);
    return react_1.default.createElement(TimeInputField_1.TimeInputField, _objectSpread(_objectSpread({
      key: "minute",
      name: "minute",
      placeholder: "MM"
    }, commonInputProps()), {}, {
      value: minute,
      onFocus: onFocus
    }));
  };
  const renderSecond = currentMatch => {
    if (currentMatch && currentMatch.length > 2) throw new Error(`Unsupported token: ${currentMatch}`);
    return react_1.default.createElement(TimeInputField_1.TimeInputField, _objectSpread(_objectSpread({
      key: "second",
      name: "second",
      placeholder: "SS"
    }, commonInputProps()), {}, {
      value: second,
      onFocus: onFocus
    }));
  };
  const renderCustomInputs = () => {
    const elementFunctions = {
      HH: renderHour,
      MM: renderMinute,
      SS: renderSecond
    };
    return (0, utils_1.renderInputs)({
      placeholder: format,
      elementFunctions: elementFunctions
    });
  };
  return react_1.default.createElement("div", {
    ref: pickerRef,
    className: className,
    onClick: onClick,
    role: "presentation"
  }, renderCustomInputs());
};
exports.TimeInput = TimeInput;