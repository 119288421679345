"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.groupToMap = exports.swap = exports.set = exports.move = exports.appendIfMissing = exports.toggle = exports.clean = exports.compact = exports.uniq = exports.includes = void 0;
const lodash_1 = require("lodash");
/**
 * Checks if an element exists in an array, resulting with type narrowing, with support for const read only arrays.
 * @example
 * const arr = ['a', 'b', 'c'] as const;
 * includes(arr, 'a') // => true (with type safety)
 */
function includes(arr, el) {
    return arr.includes(el);
}
exports.includes = includes;
/**
 * De-duplicated array values
 * @example uniq([`a`, `b`, `a`]) // => [`a`, `b`]
 */
function uniq(array) {
    return (0, lodash_1.uniq)(array);
}
exports.uniq = uniq;
/**
 * Creates an array with all falsey values removed. The values `false`, `null`,
 * `0`, `""`, `undefined`, and `NaN` are falsey
 * @example
 *
 * compact([0, 1, false, 2, '', 3])
 * // => [1, 2, 3]
 */
function compact(array) {
    let resIndex = 0;
    const result = [];
    if (array == null) {
        return result;
    }
    for (const value of array) {
        if (value) {
            result[resIndex++] = value;
        }
    }
    return result;
}
exports.compact = compact;
/**
 * Uniq and compacted array
 */
function clean(array) {
    return uniq(compact(array));
}
exports.clean = clean;
/**
 * Toggle the presence of a (primitive) value in an array.
 * If an array contains the given element, return a new array which excludes it,
 * otherwise return a new array with the element inserted at the end.
 * @param arr The array that possibly contains an element.
 * @param element The element to add or remove.
 */
function toggle(arr, element) {
    if (arr.length === 0)
        return [element];
    const collected = [];
    for (let i = 0; i < arr.length; i++) {
        const candidate = arr[i];
        if (candidate !== element) {
            collected.push(candidate);
        }
    }
    if (collected.length < arr.length) {
        return collected;
    }
    collected.push(element);
    return collected;
}
exports.toggle = toggle;
/**
 * Append an element to an array if it is not already present.
 * Returns a new array if the element was not present, otherwise return the original array.
 * @param arr The array that possibly contains an element.
 * @param element The element to add.
 */
function appendIfMissing(arr, element) {
    for (const candidate of arr) {
        if (candidate === element)
            return arr;
    }
    return [...arr, element];
}
exports.appendIfMissing = appendIfMissing;
/**
 * Move an element from a source index to a destination index, returning a new array.
 * @param arr The array.
 * @param from The source index.
 * @param to The destination index.
 */
function move(arr, from, to) {
    const output = arr.slice();
    const value = output[from];
    output.splice(from, 1);
    output.splice(to, 0, value);
    return output;
}
exports.move = move;
/**
 * Set a value at a specific index of an array.
 * @param arr The array.
 * @param index The index to set.
 * @param value The value to set.
 */
function set(arr, index, value) {
    const output = arr.slice();
    output[index] = value;
    return output;
}
exports.set = set;
/**
 * Swap the elements at the given indices.
 * @param arr The array.
 * @param indexA The first index.
 * @param indexB The second index.
 */
function swap(arr, indexA, indexB) {
    const output = arr.slice();
    const a = output[indexA];
    const b = output[indexB];
    output[indexA] = b;
    output[indexB] = a;
    return output;
}
exports.swap = swap;
function groupToMap(arr, getKey) {
    const output = new Map();
    for (const entry of arr) {
        const key = getKey(entry);
        if (!output.has(key)) {
            output.set(key, [entry]);
        }
        else {
            output.get(key).push(entry);
        }
    }
    return output;
}
exports.groupToMap = groupToMap;
