"use strict";

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
var __createBinding = void 0 && (void 0).__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  var desc = Object.getOwnPropertyDescriptor(m, k);
  if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
    desc = {
      enumerable: true,
      get: function () {
        return m[k];
      }
    };
  }
  Object.defineProperty(o, k2, desc);
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});
var __setModuleDefault = void 0 && (void 0).__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, "default", {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o["default"] = v;
});
var __importStar = void 0 && (void 0).__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
  __setModuleDefault(result, mod);
  return result;
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TimelineItem = void 0;
const react_1 = __importStar(require("react"));
const utils_units_1 = require("@oqton/utils-units");
const Button_1 = require("../Button");
const Text_1 = require("../Text");
const foundations_1 = require("../../foundations");
const hooks_1 = require("../../hooks");
const BorderWrapper_1 = require("./components/BorderWrapper");
const CommentsWrapper_1 = require("./components/CommentsWrapper");
const TimelineItem = ({
  content,
  indicator,
  comments,
  isLastItem,
  date,
  attachments,
  instances,
  isUndone
}) => {
  const _ref = (0, hooks_1.useTranslation)(),
    t = _ref.t;
  const _ref2 = (0, react_1.useState)(false),
    _ref3 = _slicedToArray(_ref2, 2),
    isOpen = _ref3[0],
    setIsOpen = _ref3[1];
  return react_1.default.createElement("article", null, react_1.default.createElement(foundations_1.Flex, {
    height: comments ? null : '40px'
  }, react_1.default.createElement(foundations_1.Box, null, indicator, !isLastItem || isOpen ? react_1.default.createElement(BorderWrapper_1.BorderWrapper, null) : null), react_1.default.createElement(foundations_1.Box, {
    "flex-direction": "column",
    ml: "m"
  }, react_1.default.createElement(foundations_1.Flex, {
    height: "m"
  }, react_1.default.createElement(foundations_1.Box, null, react_1.default.createElement(Text_1.SmallText, {
    style: isUndone ? {
      textDecoration: 'line-through'
    } : {}
  }, content, ".")), react_1.default.createElement(foundations_1.Box, {
    ml: "m"
  }, react_1.default.createElement(Text_1.SmallText, {
    color: "tertiary"
  }, (0, utils_units_1.formatDateTimeAsString)(date, utils_units_1.TIME_PRECISION.SECOND)))), comments ? react_1.default.createElement(foundations_1.Box, null, react_1.default.createElement(CommentsWrapper_1.CommentsWrapper, {
    comments: comments,
    attachments: attachments
  })) : null)), instances && react_1.default.createElement(react_1.default.Fragment, null, react_1.default.createElement(foundations_1.Box, {
    ml: "xxl",
    mt: -foundations_1.spacing.m,
    pb: "m"
  }, react_1.default.createElement(Button_1.Button, {
    size: "s",
    iconRight: true,
    mode: "tertiary",
    onClick: () => setIsOpen(prevState => !prevState),
    icon: isOpen ? 'TriangleUp' : 'TriangleDown'
  }, isOpen ? t('general:collapse') : t('general:expand'))), isOpen && react_1.default.createElement(foundations_1.Box, null, instances)));
};
exports.TimelineItem = TimelineItem;