"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.formatSecondsToUnit = exports.formatUnitToSeconds = exports.TIME_UNIT = exports.formatUnit = exports.formatValueWithUnit = exports.formatWatts = exports.formatHertz = exports.formatAmps = exports.formatRPM = exports.formatPascal = exports.formatValuta = exports.formatSecond = exports.formatPercentageFromFloat = exports.formatPercentage = exports.formatMmPerSecond = exports.formatVolumeMm = exports.formatAreaMm = exports.formatLengthMm = exports.formatDegrees = exports.formatDegreesC = exports.formatValue = exports.formatDateNoZone = exports.formatDate = exports.formatDateTimeNoZone = exports.formatDateTime = exports.formatTimeNoZone = exports.formatTime = exports.formatTimeByFormat = exports.TIME_PRECISION = exports.DATE_TIME_PRECISION = exports.DATE_FORMAT = exports.formatTimeDurationSec = exports.formatTimeDuration = void 0;
const lodash_1 = require("lodash");
const utils_units_1 = require("@oqton/utils-units");
Object.defineProperty(exports, "DATE_FORMAT", {
  enumerable: true,
  get: function () {
    return utils_units_1.DATE_FORMAT;
  }
});
Object.defineProperty(exports, "DATE_TIME_PRECISION", {
  enumerable: true,
  get: function () {
    return utils_units_1.DATE_TIME_PRECISION;
  }
});
Object.defineProperty(exports, "TIME_PRECISION", {
  enumerable: true,
  get: function () {
    return utils_units_1.TIME_PRECISION;
  }
});
const utils_core_1 = require("@oqton/utils-core");
/**
 * format the time duration
 */
const formatTimeDuration = (t, timeCondition) => {
  let durationInMilliseconds;
  if ((0, lodash_1.isObject)(t)) {
    durationInMilliseconds = 1e3 * (t.sec + t.nanos * 1e-9);
  } else {
    durationInMilliseconds = t;
  }
  return (0, utils_units_1.formatDurationAsString)(durationInMilliseconds, timeCondition);
};
exports.formatTimeDuration = formatTimeDuration;
const formatTimeDurationSec = durationInSeconds => (0, utils_units_1.formatDurationAsString)(durationInSeconds ? durationInSeconds * 1000 : durationInSeconds);
exports.formatTimeDurationSec = formatTimeDurationSec;
/**
 * get formatted time base on the format string
 * @param {string} date should be ISO format if it is string
 * @param {string} formatString format string supported by dayjs (non-unicode).
 */
const formatTimeByFormat = (date, formatString) => {
  return utils_core_1.date.format({
    date,
    format: formatString
  });
};
exports.formatTimeByFormat = formatTimeByFormat;
exports.formatTime = utils_units_1.formatTimeAsString;
exports.formatTimeNoZone = utils_units_1.formatTimeAsStringNoZone;
exports.formatDateTime = utils_units_1.formatDateTimeAsString;
exports.formatDateTimeNoZone = utils_units_1.formatDateTimeAsStringNoZone;
exports.formatDate = utils_units_1.formatDateAsString;
exports.formatDateNoZone = utils_units_1.formatDateAsStringNoZone;
/** ****************** FORMAT DATA WITH UNITS ************************* */
const formatValue = (value, precision = 0) => {
  if (!(0, lodash_1.isNumber)(value) || (0, lodash_1.isNaN)(value)) {
    return '-';
  }
  return Number(value.toFixed(precision));
};
exports.formatValue = formatValue;
const formatValueWithSuffix = (value, precision = 0, includeSuffix = true, suffix = '') => {
  if (!(0, lodash_1.isNumber)(value) || (0, lodash_1.isNaN)(value)) {
    return '-';
  }
  const precisionValue = Number(value.toFixed(precision));
  return includeSuffix ? `${precisionValue.toString()}${suffix}` : precisionValue.toString();
};
/**
 * @deprecated The @oqton/utils-units package should be used for all formatting and converting of temperatures
 */
const formatDegreesC = (val, precision = 0, includeSuffix = true) => formatValueWithSuffix(val, precision, includeSuffix, '℃');
exports.formatDegreesC = formatDegreesC;
const formatDegrees = (val, precision = 0, includeSuffix = true) => formatValueWithSuffix(val, precision, includeSuffix, '°');
exports.formatDegrees = formatDegrees;
/**
 * @deprecated The @oqton/utils-units package should be used for all formatting and converting of lengths
 */
const formatLengthMm = (val, precision = 0, includeSuffix = true) => formatValueWithSuffix(val, precision, includeSuffix, ' mm');
exports.formatLengthMm = formatLengthMm;
/**
 * @deprecated The @oqton/utils-units package should be used for all formatting and converting of areas
 */
const formatAreaMm = (val, precision = 0, includeSuffix = true) => formatValueWithSuffix(val, precision, includeSuffix, ' mm²');
exports.formatAreaMm = formatAreaMm;
/**
 * @deprecated The @oqton/utils-units package should be used for all formatting and converting of volumes
 */
const formatVolumeMm = (val, precision = 0, includeSuffix = true) => formatValueWithSuffix(val, precision, includeSuffix, ' mm³');
exports.formatVolumeMm = formatVolumeMm;
const formatMmPerSecond = (val, precision = 0, includeSuffix = true) => formatValueWithSuffix(val, precision, includeSuffix, ' mm/s');
exports.formatMmPerSecond = formatMmPerSecond;
const formatPercentage = (val, precision = 0, includeSuffix = true) => formatValueWithSuffix(val, precision, includeSuffix, '%');
exports.formatPercentage = formatPercentage;
const formatPercentageFromFloat = (val, precision = 0, includeSuffix = true) => (0, exports.formatPercentage)(
// add null/empty check as null/undefined * 100 is 0 which changed the meaning of the value
val === null || val === undefined ? val : val * 100, precision, includeSuffix);
exports.formatPercentageFromFloat = formatPercentageFromFloat;
const formatSecond = val => {
  if (!(0, lodash_1.isNumber)(val) || (0, lodash_1.isNaN)(val)) return '-';
  return (0, exports.formatTimeDurationSec)(val);
};
exports.formatSecond = formatSecond;
const formatValuta = (value, precision = 0, prefix = '€') => `${prefix || ''}${(0, exports.formatValue)(value, precision)}`;
exports.formatValuta = formatValuta;
/**
 * @deprecated The @oqton/utils-units package should be used for all formatting and converting of pressures
 */
const formatPascal = (val, precision = 0, includeSuffix = true) => formatValueWithSuffix(val, precision, includeSuffix, ' Pa');
exports.formatPascal = formatPascal;
const formatRPM = (val, precision = 0, includeSuffix = true) => formatValueWithSuffix(val, precision, includeSuffix, ' RPM');
exports.formatRPM = formatRPM;
const formatAmps = (val, precision = 0, includeSuffix = true) => formatValueWithSuffix(val, precision, includeSuffix, ' A');
exports.formatAmps = formatAmps;
const formatHertz = (val, precision = 0, includeSuffix = true) => formatValueWithSuffix(val, precision, includeSuffix, ' Hz');
exports.formatHertz = formatHertz;
const formatWatts = (val, precision = 0, includeSuffix = true) => formatValueWithSuffix(val, precision, includeSuffix, ' W');
exports.formatWatts = formatWatts;
const formatValueWithUnit = (value, unit, precision, includeSuffix = true) => {
  switch (unit) {
    // units with known formatting (allows custom formatting or precision per unit)
    case 'DEGREE':
      return (0, exports.formatDegrees)(value, precision, includeSuffix);
    case 'HERTZ':
      return (0, exports.formatHertz)(value, precision, includeSuffix);
    case 'WATT':
      return (0, exports.formatWatts)(value, precision, includeSuffix);
    case 'AMPERE':
      return (0, exports.formatAmps)(value, precision, includeSuffix);
    case 'CELSIUS':
      return (0, exports.formatDegreesC)(value, precision, includeSuffix);
    case 'REVOLUTION/MINUTE':
      return (0, exports.formatRPM)(value, precision, includeSuffix);
    case 'MILLIMETER_3D':
      return (0, exports.formatVolumeMm)(value, precision, includeSuffix);
    case 'mm':
    case 'MILLIMETER':
      return (0, exports.formatLengthMm)(value, precision, includeSuffix);
    case 'MILLIMETER/SECOND':
      return (0, exports.formatMmPerSecond)(value, precision, includeSuffix);
    case 'PASCAL':
    case 'pa':
    case 'Pa':
      return (0, exports.formatPascal)(value, precision, includeSuffix);
    case '%':
    case 'PERCENT':
      return (0, exports.formatPercentage)(value, precision, includeSuffix);
    case 'SECOND':
      return (0, exports.formatSecond)(value);
    case '': // no unit
    case undefined:
    case null:
      return (0, exports.formatValue)(value, precision);
    default:
      // custom unit
      return formatValueWithSuffix(value, precision, includeSuffix, ` ${unit}`);
  }
};
exports.formatValueWithUnit = formatValueWithUnit;
const formatUnit = unit => {
  switch (unit) {
    case 'DEGREE':
      return '°';
    case 'HERTZ':
      return 'Hz';
    case 'WATT':
      return 'W';
    case 'AMPERE':
      return 'A';
    case 'CELSIUS':
      return '℃';
    case 'REVOLUTION/MINUTE':
      return 'RPM';
    case 'MILLIMETER_3D':
      return 'mm³';
    case 'mm':
    case 'MILLIMETER':
      return 'mm';
    case 'MILLIMETER/SECOND':
      return 'mm/s';
    case 'PASCAL':
    case 'pa':
    case 'Pa':
      return 'Pa';
    case '%':
    case 'PERCENT':
      return '%';
    case undefined:
    case null:
      return '';
    default:
      return unit;
  }
};
exports.formatUnit = formatUnit;
exports.TIME_UNIT = {
  YEARS: 'years',
  MONTHS: 'months',
  WEEKS: 'weeks',
  DAYS: 'days',
  HOURS: 'hours',
  MINUTES: 'minutes',
  SECONDS: 'seconds',
  MILLISECONDS: 'milliseconds'
};
const formatUnitToSeconds = (value, unit = exports.TIME_UNIT.SECONDS) => {
  let seconds;
  let nanoseconds;
  switch (unit) {
    case exports.TIME_UNIT.YEARS:
      seconds = (value * 31536000).toFixed(0);
      nanoseconds = (value * 31536000 % 1 * 1e9).toFixed(0);
      break;
    case exports.TIME_UNIT.MONTHS:
      seconds = (value * 2592000).toFixed(0);
      nanoseconds = (value * 2592000 % 1 * 1e9).toFixed(0);
      break;
    case exports.TIME_UNIT.WEEKS:
      seconds = (value * 604800).toFixed(0);
      nanoseconds = (value * 604800 % 1 * 1e9).toFixed(0);
      break;
    case exports.TIME_UNIT.DAYS:
      seconds = (value * 86400).toFixed(0);
      nanoseconds = (value * 86400 % 1 * 1e9).toFixed(0);
      break;
    case exports.TIME_UNIT.HOURS:
      seconds = (value * 3600).toFixed(0);
      nanoseconds = (value * 3600 % 1 * 1e9).toFixed(0);
      break;
    case exports.TIME_UNIT.MINUTES:
      seconds = (value * 60).toFixed(0);
      nanoseconds = (value * 60 % 1 * 1e9).toFixed(0);
      break;
    case exports.TIME_UNIT.MILLISECONDS:
      seconds = Math.floor(value / 1000).toFixed(0);
      nanoseconds = (value / 1000 % 1 * 1e9).toFixed(0);
      break;
    case exports.TIME_UNIT.SECONDS:
    default:
      seconds = value.toFixed(0);
      nanoseconds = (value % 1 * 1e9).toFixed(0);
      break;
  }
  return {
    sec: parseInt(seconds, 10),
    nanos: parseInt(nanoseconds, 10)
  };
};
exports.formatUnitToSeconds = formatUnitToSeconds;
const formatSecondsToUnit = (sec, nanos, unit = exports.TIME_UNIT.SECONDS) => {
  const totalNanos = sec * 1e9 + nanos;
  switch (unit) {
    case exports.TIME_UNIT.YEARS:
      return parseFloat((totalNanos / (31536000 * 1e9)).toFixed(2));
    case exports.TIME_UNIT.MONTHS:
      return parseFloat((totalNanos / (2592000 * 1e9)).toFixed(2));
    case exports.TIME_UNIT.WEEKS:
      return parseFloat((totalNanos / (604800 * 1e9)).toFixed(2));
    case exports.TIME_UNIT.DAYS:
      return parseFloat((totalNanos / (86400 * 1e9)).toFixed(2));
    case exports.TIME_UNIT.HOURS:
      return parseFloat((totalNanos / (3600 * 1e9)).toFixed(2));
    case exports.TIME_UNIT.MINUTES:
      return parseFloat((totalNanos / (60 * 1e9)).toFixed(2));
    case exports.TIME_UNIT.MILLISECONDS:
      return parseFloat((totalNanos / 1e6).toFixed(2));
    case exports.TIME_UNIT.SECONDS:
    default:
      return parseFloat((totalNanos / 1e9).toFixed(2));
  }
};
exports.formatSecondsToUnit = formatSecondsToUnit;