"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAmountOfButtonsToKeep = exports.DROPDOWNBUTTON_WIDTH = void 0;
exports.DROPDOWNBUTTON_WIDTH = 46;
// Work out how many buttons to keep in button group
const getAmountOfButtonsToKeep = ({
  widthsArray,
  buttonCount,
  hasDropdown,
  width,
  useMaxWidth
}) => {
  const localArray = [...widthsArray];
  if (localArray.length > 0) {
    // get total value of all widths of buttons
    const total = localArray.reduce((acc, curr) => acc + curr, 0);
    const totalWidths = hasDropdown ? total + exports.DROPDOWNBUTTON_WIDTH : total;
    // if width of all buttons is greater that width of outer element
    if (localArray.length > 0 && totalWidths > width && !useMaxWidth) {
      // if we already have menu items we must have the dropdown button width in array
      // so we remove the second to last value
      localArray.splice(-1, 1);
      // set local hasdropdown to either hasDropdown param or result of length of defaultDropdownMenuItems
      let _hasDropdown = hasDropdown;
      // if the current widthsArray is less than the original amount of children buttons
      // and doesn't currently have a dropdown button we need to add a dropdown to array
      if (localArray.length < buttonCount && !_hasDropdown) {
        // set this on a ref so as not to cause rerender
        _hasDropdown = true;
      }
      // if the current array of buttons is equal to the children buttons
      // and did not receive default menu items
      // we need to remove the dropdown button width value
      if (localArray.length === buttonCount && _hasDropdown) {
        // set this on a ref so as not to cause rerender
        _hasDropdown = false;
      }
      // and call again with smaller array
      return (0, exports.getAmountOfButtonsToKeep)({
        widthsArray: localArray,
        buttonCount,
        hasDropdown: _hasDropdown,
        width
      });
    }
    // if we have a dropdown button visible
    // we remove one from the array value length
    return localArray.length;
  }
  return 0;
};
exports.getAmountOfButtonsToKeep = getAmountOfButtonsToKeep;