"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.InspectorAccordionItem = exports.InspectorAccordion = void 0;
var InspectorAccordion_1 = require("./InspectorAccordion");
Object.defineProperty(exports, "InspectorAccordion", {
  enumerable: true,
  get: function () {
    return InspectorAccordion_1.InspectorAccordion;
  }
});
var InspectorAccordionItem_1 = require("./components/InspectorAccordionItem");
Object.defineProperty(exports, "InspectorAccordionItem", {
  enumerable: true,
  get: function () {
    return InspectorAccordionItem_1.InspectorAccordionItem;
  }
});