"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.usePosition = void 0;
const react_1 = require("react");
const useWindowSize_1 = require("../../hooks/useWindowSize");
let root;
if (typeof window !== `undefined`) {
  root = document.getElementById('root');
}
const usePosition = ({
  contentRef,
  parentRect,
  placement = 'TOP'
}) => {
  const _ref = (0, react_1.useState)({
      bottom: 'auto',
      top: 'auto',
      left: 'auto',
      right: 'auto'
    }),
    _ref2 = _slicedToArray(_ref, 2),
    position = _ref2[0],
    setPosition = _ref2[1];
  const windowSize = (0, useWindowSize_1.useWindowSize)();
  (0, react_1.useEffect)(() => {
    if (contentRef.current && (windowSize === null || windowSize === void 0 ? void 0 : windowSize.height) && root) {
      const _position = _objectSpread({}, position);
      // we have to do this because of the animation it gets measured on enter
      const offset = contentRef.current.getBoundingClientRect().width - parentRect.width;
      switch (placement) {
        case 'TOP':
          _position.bottom = `${windowSize.height - parentRect.top + 5}px`;
          _position.left = `${parentRect.left - offset / 2}px`;
          setPosition(_position);
          break;
        case 'TOP_LEFT':
          _position.bottom = `${windowSize.height - parentRect.top + 5}px`;
          _position.left = `${parentRect.left - root.getBoundingClientRect().left}px`;
          setPosition(_position);
          break;
        case 'TOP_RIGHT':
          _position.bottom = `${windowSize.height - parentRect.top + 5}px`;
          _position.left = `${parentRect.left - root.getBoundingClientRect().left - offset}px`;
          setPosition(_position);
          break;
        case 'BOTTOM':
          _position.top = `${parentRect.bottom - root.getBoundingClientRect().top + 5}px`;
          _position.left = `${parentRect.left - offset / 2}px`;
          setPosition(_position);
          break;
        case 'BOTTOM_LEFT':
          _position.top = `${parentRect.bottom - root.getBoundingClientRect().top + 5}px`;
          _position.left = `${parentRect.left - root.getBoundingClientRect().left}px`;
          setPosition(_position);
          break;
        case 'BOTTOM_RIGHT':
          _position.top = `${parentRect.bottom - root.getBoundingClientRect().top + 5}px`;
          _position.left = `${parentRect.left - root.getBoundingClientRect().left - offset}px`;
          setPosition(_position);
          break;
        default:
          break;
      }
    }
    // eslint-disable-next-line
  }, [contentRef.current, parentRect.top, parentRect.bottom, parentRect.left]);
  return position;
};
exports.usePosition = usePosition;