"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
var __createBinding = void 0 && (void 0).__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  var desc = Object.getOwnPropertyDescriptor(m, k);
  if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
    desc = {
      enumerable: true,
      get: function () {
        return m[k];
      }
    };
  }
  Object.defineProperty(o, k2, desc);
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});
var __setModuleDefault = void 0 && (void 0).__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, "default", {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o["default"] = v;
});
var __importStar = void 0 && (void 0).__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
  __setModuleDefault(result, mod);
  return result;
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DateInput = void 0;
const react_1 = __importStar(require("react"));
const utils_core_1 = require("@oqton/utils-core");
const utils_1 = require("./utils");
const DateInputField_1 = require("./DateInputField");
const DATE_DIVIDER = '/';
const isValidInput = element => element.tagName === 'INPUT' && element.type === 'number';
const findInput = (element, property) => {
  let nextElement = element;
  do {
    nextElement = nextElement[property];
  } while (nextElement && !isValidInput(nextElement));
  return nextElement;
};
const focus = element => element && element.focus();
const DateInput = ({
  format,
  disabled,
  value,
  className,
  pickerRef,
  isCalendarOpen,
  required,
  onChange,
  onFocus
}) => {
  const _ref = (0, react_1.useState)(null),
    _ref2 = _slicedToArray(_ref, 2),
    year = _ref2[0],
    setYear = _ref2[1];
  const _ref3 = (0, react_1.useState)(null),
    _ref4 = _slicedToArray(_ref3, 2),
    month = _ref4[0],
    setMonth = _ref4[1];
  const _ref5 = (0, react_1.useState)(null),
    _ref6 = _slicedToArray(_ref5, 2),
    day = _ref6[0],
    setDay = _ref6[1];
  (0, react_1.useEffect)(() => {
    if (value) {
      setYear(utils_core_1.date.get({
        date: value,
        unit: 'year'
      }));
      setMonth(utils_core_1.date.get({
        date: value,
        unit: 'month'
      }) + 1);
      setDay(utils_core_1.date.get({
        date: value,
        unit: 'date'
      }));
    }
  }, [value]);
  const onClick = event => {
    if (event.target === event.currentTarget) {
      const firstInput = event.currentTarget.children[1];
      focus(firstInput);
    }
  };
  const onKeyDown = event => {
    switch (event.key) {
      case 'ArrowLeft':
      case 'ArrowRight':
      case DATE_DIVIDER:
        {
          event.preventDefault();
          const input = event.target;
          const property = event.key === 'ArrowLeft' ? 'previousElementSibling' : 'nextElementSibling';
          const nextInput = findInput(input, property);
          focus(nextInput);
          break;
        }
      default:
    }
  };
  /**
   * Called when non-native date input is changed.
   */
  const handleOnChange = ({
    value,
    event
  }) => {
    switch (event.target.name) {
      case 'year':
        setYear(value);
        if (onChange) onChange({
          year: value,
          month,
          day
        });
        break;
      case 'month':
        setMonth(value);
        if (onChange) onChange({
          year,
          month: value,
          day
        });
        break;
      case 'day':
        setDay(value);
        if (onChange) onChange({
          year,
          month,
          day: value
        });
        break;
      default:
        throw new Error(`unknown name ${event.target.name}`);
    }
  };
  const commonInputProps = () => {
    return {
      className,
      disabled,
      onChange: handleOnChange,
      onKeyDown: onKeyDown,
      required: required || isCalendarOpen
    };
  };
  const getFullDate = () => {
    if (!year || !month || !day) return;
    return new Date(year, month - 1, 1);
  };
  const renderDay = currentMatch => {
    if (currentMatch && currentMatch.length > 2) throw new Error(`Unsupported token: ${currentMatch}`);
    return react_1.default.createElement(DateInputField_1.DateInputField, _objectSpread(_objectSpread({
      key: "day",
      name: "day",
      placeholder: "DD"
    }, commonInputProps()), {}, {
      value: day,
      fullDate: getFullDate(),
      onFocus: onFocus
    }));
  };
  const renderMonth = currentMatch => {
    if (currentMatch && currentMatch.length > 2) throw new Error(`Unsupported token: ${currentMatch}`);
    return react_1.default.createElement(DateInputField_1.DateInputField, _objectSpread(_objectSpread({
      key: "month"
    }, commonInputProps()), {}, {
      value: month,
      name: "month",
      placeholder: "MM",
      fullDate: getFullDate(),
      onFocus: onFocus
    }));
  };
  const renderYear = () => {
    return react_1.default.createElement(DateInputField_1.DateInputField, _objectSpread(_objectSpread({
      key: "year"
    }, commonInputProps()), {}, {
      name: "year",
      placeholder: "YYYY",
      value: year,
      fullDate: getFullDate(),
      onFocus: onFocus
    }));
  };
  const renderCustomInputs = () => {
    const elementFunctions = {
      DD: renderDay,
      MM: renderMonth,
      YYYY: renderYear
    };
    return (0, utils_1.renderInputs)(format, elementFunctions, disabled);
  };
  return react_1.default.createElement("div", {
    ref: pickerRef,
    className: className,
    onClick: onClick,
    role: "presentation"
  }, renderCustomInputs());
};
exports.DateInput = DateInput;