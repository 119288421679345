"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getUnitDefinition = void 0;
const Definitions_1 = require("./Definitions");
/**
 * Get a unit definition by name.
 * @param name The name of the unit.
 */
function getUnitDefinition(name) {
    const byKey = Definitions_1.Units[name];
    if (byKey !== undefined) {
        return byKey;
    }
    for (const key of Object.keys(Definitions_1.Units)) {
        const unit = Definitions_1.Units[key];
        if (unit.name === name) {
            return unit;
        }
    }
}
exports.getUnitDefinition = getUnitDefinition;
