"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UUID = void 0;
const uuid_1 = require("uuid");
var UUID;
(function (UUID) {
    function create() {
        return (0, uuid_1.v4)();
    }
    UUID.create = create;
})(UUID || (exports.UUID = UUID = {}));
