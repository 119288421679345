"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ge = void 0;
exports.ge = {
  brandLogo: {
    url: 'https://www.ge.com/sites/all/themes/ge_2016/images/ge-monogram-white-text.svg',
    svg: null
  },
  colors: {
    backgroundTertiary: '#fff',
    borderSecondary: '#b1d4fa',
    borderTertiary: '#b1d4fa',
    inversePrimary: '#fff',
    buttonColors: {
      secondary: {
        text: '#025fba',
        border: '#025fba'
      }
    }
  },
  gradients: {
    menu: `background: linear-gradient(0deg, #5389cc 0%, #025fba 100%);`
  },
  typography: {},
  spacing: {}
};