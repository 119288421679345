"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
var __createBinding = void 0 && (void 0).__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  var desc = Object.getOwnPropertyDescriptor(m, k);
  if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
    desc = {
      enumerable: true,
      get: function () {
        return m[k];
      }
    };
  }
  Object.defineProperty(o, k2, desc);
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});
var __setModuleDefault = void 0 && (void 0).__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, "default", {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o["default"] = v;
});
var __importStar = void 0 && (void 0).__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
  __setModuleDefault(result, mod);
  return result;
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Theme = exports.useSettingsContext = exports.SettingsContext = exports.useResponsiveContext = exports.ResponsiveContext = void 0;
const react_1 = __importStar(require("react"));
const styled_components_1 = require("styled-components");
const lodash_1 = require("lodash");
const utils_1 = require("./utils");
const components_1 = require("./components");
const index_1 = require("./index");
// Create a responsive Context
exports.ResponsiveContext = react_1.default.createContext({});
const useResponsiveContext = () => (0, react_1.useContext)(exports.ResponsiveContext);
exports.useResponsiveContext = useResponsiveContext;
exports.SettingsContext = react_1.default.createContext({});
const useSettingsContext = () => (0, react_1.useContext)(exports.SettingsContext);
exports.useSettingsContext = useSettingsContext;
// Export our Theme
class Theme extends react_1.PureComponent {
  constructor(props) {
    super(props);
    // Use timeout for throttling animation events
    this.timeout = undefined;
    this.onWindowResize = () => {
      if (typeof window !== 'undefined') {
        if (this.timeout) {
          window.cancelAnimationFrame(this.timeout);
        }
        this.timeout = window.requestAnimationFrame(() => {
          const newBreakpoint = this.calculateCurrentBreakpoint();
          if (newBreakpoint !== this.state.currentBreakpoint) {
            this.setState({
              currentBreakpoint: newBreakpoint,
              windowWidth: window.innerWidth
            });
          }
        });
      }
    };
    this.isCurrentBreakpointLargerThan = breakpoint => {
      return (0, utils_1.widerThan)(breakpoint, this.state.currentBreakpoint, this.state.currentTheme.breakpoints);
    };
    this.isCurrentBreakpointSmallerThan = breakpoint => {
      return (0, utils_1.narrowerThan)(breakpoint, this.state.currentBreakpoint, this.state.currentTheme.breakpoints);
    };
    // this is the padding to be used everywhere in the app
    this.getResponsivePadding = () => ({
      xs: this.state.currentTheme.spacing.m,
      s: null,
      m: this.state.currentTheme.spacing.l,
      l: null,
      xl: this.state.currentTheme.spacing.xl
    });
    this.state = {
      currentTheme: props.theme || (0, utils_1.createTheme)(index_1.themes.oqton),
      currentBreakpoint: null,
      windowWidth: null
    };
  }
  componentDidMount() {
    if (typeof window !== 'undefined') {
      if (this.timeout) {
        window.cancelAnimationFrame(this.timeout);
      }
      window.addEventListener('resize', this.onWindowResize);
      this.setState({
        windowWidth: window.innerWidth,
        currentBreakpoint: this.calculateCurrentBreakpoint()
      });
    }
  }
  componentWillUnmount() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onWindowResize);
    }
  }
  calculateCurrentBreakpoint() {
    const bpToPairs = (0, lodash_1.toPairs)(this.state.currentTheme.breakpoints);
    // convert pixel values to numbers
    const pixelsToNumbers = bpToPairs.map(pair => [pair[0], parseFloat(pair[1])]);
    const sortedBreakpoints = (0, lodash_1.orderBy)(pixelsToNumbers, bp => bp[1]); // convert to pairs+sort to guarantee order
    let currentBreakpoint = sortedBreakpoints[0][0];
    (0, lodash_1.forEach)(sortedBreakpoints, bpPair => {
      if (typeof window !== 'undefined' && window.innerWidth < Number(bpPair[1])) return false;
      currentBreakpoint = bpPair[0];
    });
    return currentBreakpoint;
  }
  render() {
    // Use the styled component ThemeProvider Context to pass theme stuff down stream
    const responsiveValue = _objectSpread(_objectSpread({}, this.state), {}, {
      getResponsivePadding: this.getResponsivePadding,
      isCurrentBreakpointLargerThan: this.isCurrentBreakpointLargerThan,
      isCurrentBreakpointSmallerThan: this.isCurrentBreakpointSmallerThan
    });
    const _this$props = this.props,
      translate = _this$props.translate,
      children = _this$props.children;
    return react_1.default.createElement(exports.SettingsContext.Provider, {
      value: {
        translate
      }
    }, react_1.default.createElement(utils_1.ThemeOverridesContextProvider, null, react_1.default.createElement(utils_1.ThemeOverridesConsumer, null, ({
      themeOverrides
    }) => react_1.default.createElement(styled_components_1.ThemeProvider, {
      theme: (0, lodash_1.merge)(this.state.currentTheme, themeOverrides)
    }, react_1.default.createElement(components_1.GlobalStyle, null), react_1.default.createElement(exports.ResponsiveContext.Provider, {
      value: responsiveValue
    }, children)))));
  }
}
exports.Theme = Theme;