import styled, { keyframes } from 'styled-components';
import { Box } from '@oqton/design-system';

const rotate = keyframes`
0% {
  background-position: 0% 50%;
}
50% {
  background-position: 100% 50%;
}
100% {
  background-position: 0% 50%;
}
`;

export const BackgroundGradient = styled(Box)`
  position: absolute;
  background: linear-gradient(-45deg, #337b94, #092f45, #000000);
  background-size: 400% 400%;
  animation: ${rotate} 25s ease infinite;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
`;
