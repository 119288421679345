"use strict";

var __createBinding = void 0 && (void 0).__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  var desc = Object.getOwnPropertyDescriptor(m, k);
  if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
    desc = {
      enumerable: true,
      get: function () {
        return m[k];
      }
    };
  }
  Object.defineProperty(o, k2, desc);
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});
var __setModuleDefault = void 0 && (void 0).__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, "default", {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o["default"] = v;
});
var __importStar = void 0 && (void 0).__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
  __setModuleDefault(result, mod);
  return result;
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.VisibilityControls = void 0;
const react_1 = __importStar(require("react"));
const Icon_1 = require("../Icon");
const foundations_1 = require("../../foundations");
const Tooltip_1 = require("../Tooltip");
const VisibilityControls = props => {
  const isHidden = props.isHidden,
    isLocked = props.isLocked,
    onClickHidden = props.onClickHidden,
    onClickLock = props.onClickLock,
    disabled = props.disabled;
  const _onClickHidden = (0, react_1.useCallback)(e => {
    if (!disabled) {
      e.stopPropagation();
      if (onClickHidden) {
        onClickHidden(e);
      }
    }
  }, [disabled, onClickHidden]);
  const _onClickLock = (0, react_1.useCallback)(e => {
    if (!disabled) {
      e.stopPropagation();
      if (onClickLock) {
        onClickLock(e);
      }
    }
  }, [disabled, onClickLock]);
  if (disabled) {
    return react_1.default.createElement(react_1.default.Fragment, null);
  }
  return react_1.default.createElement("div", {
    "data-testid": "VisibilityControls",
    "data-disabled": disabled,
    style: {
      display: 'flex'
    }
  }, isLocked === true &&
  // TODO: Add translation for general:unlock
  react_1.default.createElement(Tooltip_1.Tooltip, {
    content: "Unlock"
  }, react_1.default.createElement(foundations_1.Flex, {
    mx: "xxs"
  }, react_1.default.createElement(Icon_1.Icon, {
    disabled: disabled,
    name: "InputLocked",
    onMouseDown: _onClickLock,
    cursor: "pointer",
    "data-testid": "locked"
  }))), isLocked === false &&
  // TODO: Add translation for general:lock
  react_1.default.createElement(Tooltip_1.Tooltip, {
    content: "Lock"
  }, react_1.default.createElement(foundations_1.Flex, {
    mx: "xxs"
  }, react_1.default.createElement(Icon_1.Icon, {
    disabled: disabled,
    name: "InputUnlocked",
    onMouseDown: _onClickLock,
    cursor: "pointer",
    "data-testid": "unlocked"
  }))), isHidden === true &&
  // TODO: Add translation for general:show
  react_1.default.createElement(Tooltip_1.Tooltip, {
    content: "Show"
  }, react_1.default.createElement(foundations_1.Flex, {
    mx: "xxs"
  }, react_1.default.createElement(Icon_1.Icon, {
    disabled: disabled,
    name: "InputHidden",
    onMouseDown: _onClickHidden,
    cursor: "pointer",
    "data-testid": "hidden"
  }))), isHidden === false &&
  // TODO: Add translation general:hide
  react_1.default.createElement(Tooltip_1.Tooltip, {
    content: "Hide"
  }, react_1.default.createElement(foundations_1.Flex, {
    mx: "xxs"
  }, react_1.default.createElement(Icon_1.Icon, {
    disabled: disabled,
    name: "InputVisible",
    onMouseDown: _onClickHidden,
    cursor: "pointer",
    "data-testid": "visible"
  }))));
};
exports.VisibilityControls = VisibilityControls;