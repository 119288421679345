"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
var __createBinding = void 0 && (void 0).__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  var desc = Object.getOwnPropertyDescriptor(m, k);
  if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
    desc = {
      enumerable: true,
      get: function () {
        return m[k];
      }
    };
  }
  Object.defineProperty(o, k2, desc);
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});
var __setModuleDefault = void 0 && (void 0).__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, "default", {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o["default"] = v;
});
var __importStar = void 0 && (void 0).__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
  __setModuleDefault(result, mod);
  return result;
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DateTimePickerFragment = void 0;
const react_1 = __importStar(require("react"));
const utils_core_1 = require("@oqton/utils-core");
const dates_1 = require("../../utils/dates");
const index_1 = require("../index");
const DEFAULT_DATE_PICKER_PROPS = {
  mb: 0
};
const DEFAULT_TIME_PICKER_PROPS = {
  format: 'HH:MM',
  align: 'right',
  label: '',
  mb: 0,
  minutesInterval: 5
};
function getDate(date, time, useSeconds, ignoreTimezone) {
  const output = ignoreTimezone ? (0, utils_core_1.dateAndTimeToDateIgnoreTimezone)(date, time) : (0, utils_core_1.dateAndTimeToDate)(date, time);
  if (useSeconds || !output) return output;
  return (0, dates_1.setSecondsToZero)(output);
}
const DateTimePickerFragment = props => {
  const disabled = props.disabled,
    readonly = props.readonly,
    _props$label = props.label,
    label = _props$label === void 0 ? '' : _props$label,
    disabledDays = props.disabledDays,
    dateValidError = props.dateValidError,
    timeValidError = props.timeValidError,
    onClear = props.onClear,
    isHidden = props.isHidden,
    isLocked = props.isLocked,
    onClickHidden = props.onClickHidden,
    onClickLock = props.onClickLock,
    required = props.required,
    timePickerProps = props.timePickerProps,
    datePickerProps = props.datePickerProps,
    ignoreLocalTimezone = props.ignoreLocalTimezone,
    onChange = props.onChange,
    date = props.date,
    _props$defaultTime = props.defaultTime,
    defaultTime = _props$defaultTime === void 0 ? '00:00:00' : _props$defaultTime,
    onFocus = props.onFocus,
    icons = props.icons;
  const _ref = (0, react_1.useState)(date === null || date === void 0 ? void 0 : date.toTimeString().slice(0, date === null || date === void 0 ? void 0 : date.toTimeString().indexOf(' '))),
    _ref2 = _slicedToArray(_ref, 2),
    time = _ref2[0],
    setTime = _ref2[1];
  const timeProps = (0, react_1.useMemo)(() => _objectSpread(_objectSpread({}, DEFAULT_TIME_PICKER_PROPS), timePickerProps), [timePickerProps]);
  const dateProps = (0, react_1.useMemo)(() => _objectSpread(_objectSpread({}, DEFAULT_DATE_PICKER_PROPS), datePickerProps), [datePickerProps]);
  (0, react_1.useEffect)(() => {
    setTime(date === null || date === void 0 ? void 0 : date.toTimeString().slice(0, date === null || date === void 0 ? void 0 : date.toTimeString().indexOf(' ')));
  }, [date]);
  const handleDateChange = newDate => {
    const format = timeProps.format;
    if (newDate) {
      const dateObject = getDate(typeof newDate === 'string' ? new Date(newDate) : newDate, time || defaultTime, format === 'HH:MM:SS', !!ignoreLocalTimezone);
      onChange && onChange(dateObject);
    }
  };
  const handleTimeChange = (0, react_1.useCallback)(newTime => {
    setTime(newTime !== null && newTime !== void 0 ? newTime : '');
  }, []);
  const handleTimeBlur = (0, react_1.useCallback)(() => {
    const format = timeProps.format;
    if (date && time) {
      const dateObject = getDate(date, time, format === 'HH:MM:SS', !!ignoreLocalTimezone);
      if (onChange) onChange(dateObject);
    }
  }, [date, timeProps, ignoreLocalTimezone, onChange, time]);
  return react_1.default.createElement(react_1.Fragment, null, react_1.default.createElement(index_1.DatePicker, _objectSpread(_objectSpread({}, dateProps), {}, {
    label: label,
    required: required,
    disabled: disabled,
    readonly: readonly,
    disabledDays: disabledDays,
    mb: 0,
    onChange: handleDateChange,
    value: date,
    error: dateValidError,
    onClear: onClear,
    onFocus: e => onFocus && onFocus(e, 'date'),
    icons: icons
  })), react_1.default.createElement(index_1.TimePicker, _objectSpread(_objectSpread({}, timeProps), {}, {
    disabled: disabled,
    readonly: readonly,
    mb: 0,
    onChange: handleTimeChange,
    value: time,
    error: timeValidError,
    onBlur: handleTimeBlur,
    onFocus: e => onFocus && onFocus(e, 'time'),
    icons: icons,
    isHidden: isHidden,
    isLocked: isLocked,
    onClickHidden: onClickHidden,
    onClickLock: onClickLock
  })));
};
exports.DateTimePickerFragment = DateTimePickerFragment;