"use strict";

const _excluded = ["disabled", "readonly", "onFocus", "hasDropdown", "onChange", "onBlur", "value", "format", "mb", "align", "required", "error", "minutesInterval", "minutesContainerHeight"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }
function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }
var __createBinding = void 0 && (void 0).__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  var desc = Object.getOwnPropertyDescriptor(m, k);
  if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
    desc = {
      enumerable: true,
      get: function () {
        return m[k];
      }
    };
  }
  Object.defineProperty(o, k2, desc);
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});
var __setModuleDefault = void 0 && (void 0).__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, "default", {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o["default"] = v;
});
var __importStar = void 0 && (void 0).__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
  __setModuleDefault(result, mod);
  return result;
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TimePicker = void 0;
const react_1 = __importStar(require("react"));
const foundations_1 = require("../../foundations");
const dateTimePickerHelpers_1 = require("../../utils/dateTimePickerHelpers");
const components_1 = require("./components");
const TimePicker = _ref => {
  let _ref$disabled = _ref.disabled,
    disabled = _ref$disabled === void 0 ? false : _ref$disabled,
    _ref$readonly = _ref.readonly,
    readonly = _ref$readonly === void 0 ? false : _ref$readonly,
    onFocus = _ref.onFocus,
    _ref$hasDropdown = _ref.hasDropdown,
    hasDropdown = _ref$hasDropdown === void 0 ? true : _ref$hasDropdown,
    onChange = _ref.onChange,
    onBlur = _ref.onBlur,
    value = _ref.value,
    _ref$format = _ref.format,
    format = _ref$format === void 0 ? 'HH:MM:SS' : _ref$format,
    _ref$mb = _ref.mb,
    mb = _ref$mb === void 0 ? 'm' : _ref$mb,
    _ref$align = _ref.align,
    align = _ref$align === void 0 ? 'left' : _ref$align,
    required = _ref.required,
    error = _ref.error,
    _ref$minutesInterval = _ref.minutesInterval,
    minutesInterval = _ref$minutesInterval === void 0 ? 5 : _ref$minutesInterval,
    minutesContainerHeight = _ref.minutesContainerHeight,
    rest = _objectWithoutProperties(_ref, _excluded);
  const panelRef = (0, react_1.useRef)();
  const pickerRef = (0, react_1.useRef)();
  const dropdownButtonRef = (0, react_1.useRef)();
  const _ref2 = (0, react_1.useState)(false),
    _ref3 = _slicedToArray(_ref2, 2),
    isOpen = _ref3[0],
    setIsOpen = _ref3[1];
  const _ref4 = (0, react_1.useState)(null),
    _ref5 = _slicedToArray(_ref4, 2),
    outputError = _ref5[0],
    setOutputError = _ref5[1];
  const handleClick = (0, react_1.useCallback)(e => {
    const target = e.target;
    if (disabled || readonly) {
      return e.preventDefault();
    }
    const panelNode = panelRef.current;
    const pickerNode = pickerRef.current;
    const dropdownButtonNode = dropdownButtonRef.current;
    // Keep open if clicking again on the masked input whilst open
    // @ts-expect-error
    if (target.type === 'text' && isOpen) {
      e.preventDefault();
      return;
    }
    if (hasDropdown) {
      if (dropdownButtonNode && dropdownButtonNode.contains(target) || target === pickerNode) {
        if (isOpen && onBlur) onBlur();
        setIsOpen(prevState => !prevState);
        e.preventDefault();
        return;
      }
      // Open (or keep open) the panel if you click on it
      if (panelNode && panelNode.contains(target)) {
        setIsOpen(true);
        e.preventDefault();
        return;
      }
      // If the click is anywhere else, close the panel
      if (isOpen && onBlur) onBlur();
      setIsOpen(false);
    }
    // Toggle the panel open state if you click on the dropdown button
  }, [disabled, hasDropdown, isOpen, onBlur, readonly]);
  (0, react_1.useEffect)(() => {
    if (typeof window !== `undefined`) {
      document.addEventListener('mousedown', handleClick, false);
    }
    return () => {
      if (typeof window !== `undefined`) {
        document.removeEventListener('mousedown', handleClick, false);
      }
    };
  }, [handleClick]);
  const handleFocus = e => {
    if (disabled || readonly) {
      return e.preventDefault();
    }
    if (onFocus) onFocus(e);
  };
  const updateTime = (division, val) => {
    const timeString = (0, dateTimePickerHelpers_1.updateTime)(division, val, value || '', format);
    onChange(timeString);
  };
  const handleOnError = message => {
    //We still want to trigger the onChange to update the value
    onChange && onChange(null);
    setOutputError(message);
    return true;
  };
  const checkForError = ({
    hour,
    minute,
    second
  }) => {
    if (!hour || !minute) return handleOnError(' ');
    if (parseInt(hour) > 23) return handleOnError('invalid hour');
    if (parseInt(minute) > 59) return handleOnError('invalid minute');
    if (format === 'HH:MM:SS') {
      if (!second) return handleOnError(' ');
      if (parseInt(second) > 59) return handleOnError('invalid second');
    }
    return false;
  };
  const handleOnChange = value => {
    const hour = value.hour,
      minute = value.minute,
      second = value.second;
    const output = Object.entries(value).reduce((prevValue, curValue) => {
      var _a;
      return (0, dateTimePickerHelpers_1.updateTime)(curValue[0], (_a = curValue[1]) !== null && _a !== void 0 ? _a : 0, prevValue, format);
    }, '');
    if (checkForError({
      hour,
      minute,
      second
    })) return;
    setOutputError(null);
    if (onChange) onChange(output);
  };
  return react_1.default.createElement(foundations_1.Box, {
    mb: mb
  }, react_1.default.createElement(components_1.StyledTimeInput, _objectSpread({
    format: format,
    hasDropdown: true,
    dropdownIsOpen: isOpen,
    pickerRef: pickerRef,
    dropdownButtonRef: dropdownButtonRef,
    value: value,
    onFocus: handleFocus,
    onBlur: onBlur,
    onChange: handleOnChange,
    disabled: disabled,
    readOnly: readonly,
    mb: 0,
    required: required,
    error: outputError || error
  }, rest)), isOpen && react_1.default.createElement(components_1.PickerPanel, {
    updateTime: updateTime,
    value: value,
    format: format,
    align: align,
    panelRef: panelRef,
    minutesInterval: minutesInterval,
    minutesContainerHeight: minutesContainerHeight
  }));
};
exports.TimePicker = TimePicker;