"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PrintableInterface = exports.ItemInterface = exports.DialogInterface = exports.DashboardInterface = exports.CustomInterface = void 0;
var CustomInterface_1 = require("./CustomInterface");
Object.defineProperty(exports, "CustomInterface", {
  enumerable: true,
  get: function () {
    return CustomInterface_1.CustomInterface;
  }
});
var DashboardInterface_1 = require("./DashboardInterface");
Object.defineProperty(exports, "DashboardInterface", {
  enumerable: true,
  get: function () {
    return DashboardInterface_1.DashboardInterface;
  }
});
var DialogInterface_1 = require("./DialogInterface");
Object.defineProperty(exports, "DialogInterface", {
  enumerable: true,
  get: function () {
    return DialogInterface_1.DialogInterface;
  }
});
var ItemInterface_1 = require("./ItemInterface");
Object.defineProperty(exports, "ItemInterface", {
  enumerable: true,
  get: function () {
    return ItemInterface_1.ItemInterface;
  }
});
var PrintableInterface_1 = require("./PrintableInterface");
Object.defineProperty(exports, "PrintableInterface", {
  enumerable: true,
  get: function () {
    return PrintableInterface_1.PrintableInterface;
  }
});