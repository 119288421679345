"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Assert = void 0;
/**
 * Utilities for declaring assertions.
 */
var Assert;
(function (Assert) {
    function identity(input) { }
    Assert.identity = identity;
    function as(input) { }
    Assert.as = as;
    function createAssertionError(caller, message) {
        const error = new Error(message);
        error.name = 'OqtonAssertionError';
        if (typeof Error.captureStackTrace === 'function') {
            Error.captureStackTrace(error, caller);
        }
        return error;
    }
    /**
     * Assert that a certain value can never exist.
     * This is useful for exhaustive switch statements,
     * if / else statements etc.
     * @param _value The value that should never exist.
     * @param message The message for the assertion error.
     */
    function never(_value, message = 'This code path should never be reached.') {
        throw createAssertionError(never, message);
    }
    Assert.never = never;
    /**
     * Assert that a particular code path is unreachable.
     * @param message The message for the assertion error.
     */
    function unreachable(message = 'This code path should never be reached') {
        throw createAssertionError(unreachable, message);
    }
    Assert.unreachable = unreachable;
    /**
     * Assert that the given values are strictly equal
     * @param a The first value to compare.
     * @param b The second value to compare.
     * @param message The message for the assertion error.
     */
    function is(a, b, message = 'Assertion failed') {
        if (!Object.is(a, b)) {
            throw createAssertionError(is, message);
        }
    }
    Assert.is = is;
    /**
     * Assert that the given value is truthy.
     * @param candidate The candidate value.
     * @param message The message for the assertion error.
     */
    function check(candidate, message = 'Assertion failed') {
        if (!candidate) {
            throw createAssertionError(check, message);
        }
    }
    Assert.check = check;
    /**
     * Assert that the given value is true.
     * @param candidate The candidate value.
     * @param message The message for the assertion error.
     */
    function isTrue(candidate, message = 'Assertion failed') {
        if (candidate !== true) {
            throw createAssertionError(isTrue, message);
        }
    }
    Assert.isTrue = isTrue;
    /**
     * Assert that the given value is false.
     * @param candidate The candidate value.
     * @param message The message for the assertion error.
     */
    function isFalse(candidate, message = 'Assertion failed') {
        if (candidate !== false) {
            throw createAssertionError(isFalse, message);
        }
    }
    Assert.isFalse = isFalse;
    /**
     * Assert that the given value is an instance of the constructor.
     * @param constructor The constructor to check against.
     * @param candidate The candidate value.
     * @param message The message for the assertion error.
     */
    function instanceOf(constructor, candidate, message) {
        if (!(candidate instanceof constructor)) {
            throw createAssertionError(instanceOf, message !== null && message !== void 0 ? message : `Expected instance of ${constructor.name || 'specific class'}.`);
        }
    }
    Assert.instanceOf = instanceOf;
    /**
     * Assert that the given value is defined (i.e. not `undefined`).
     * @param candidate The candidate value.
     * @param message The message for the assertion error.
     */
    function isDefined(candidate, message = 'Expected candidate value to be defined.') {
        if (candidate === undefined) {
            throw createAssertionError(isDefined, message);
        }
    }
    Assert.isDefined = isDefined;
    /**
     * Assert that the given value is an array of values.
     * @param candidate The candidate value.
     * @param message The message for the assertion error.
     */
    function isArray(candidate, message = 'Expected an array of values.') {
        if (!Array.isArray(candidate)) {
            throw createAssertionError(isArray, message);
        }
    }
    Assert.isArray = isArray;
    /**
     * Assert that the given value is an object.
     * @param candidate The candidate value.
     * @param message The message for the assertion error.
     */
    function isObject(candidate, message = 'Expected an object.') {
        if (typeof candidate !== 'object' || candidate === null) {
            throw createAssertionError(isArray, message);
        }
    }
    Assert.isObject = isObject;
    /**
     * Assert that the given value is a boolean.
     * @param candidate The candidate value.
     * @param message The message for the assertion error.
     */
    function isBoolean(candidate, message = 'Must be a boolean.') {
        if (typeof candidate !== 'boolean') {
            throw createAssertionError(isBoolean, message);
        }
    }
    Assert.isBoolean = isBoolean;
    /**
     * Assert that the given value is a number.
     * @param candidate The candidate value.
     * @param message The message for the assertion error.
     */
    function isNumber(candidate, message = 'Must be a number.') {
        if (typeof candidate !== 'number' || Number.isNaN(candidate)) {
            throw createAssertionError(isNumber, message);
        }
    }
    Assert.isNumber = isNumber;
    /**
     * Assert that the given value is a string.
     * @param candidate The candidate value.
     * @param message The message for the assertion error.
     */
    function isString(candidate, message = 'Must be a string.') {
        if (typeof candidate !== 'string') {
            throw createAssertionError(isString, message);
        }
    }
    Assert.isString = isString;
    /**
     * Assert that the given value is a function.
     * @param candidate The candidate value.
     * @param message The message for the assertion error.
     */
    function isFunction(candidate, message = 'Must be a function.') {
        if (typeof candidate !== 'function') {
            throw createAssertionError(isFunction, message);
        }
    }
    Assert.isFunction = isFunction;
    /**
     * Assert that the given value is not null or undefined.
     * @param candidate The candidate value.
     * @param message The message for the assertion error.
     */
    Assert.isNotNullOrUndefined = (value, message = 'value is null or undefined') => {
        if (value === null || value === undefined) {
            throw createAssertionError(isFunction, message);
        }
    };
})(Assert || (exports.Assert = Assert = {}));
