"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Prompt = void 0;
var Prompt_1 = require("./Prompt");
Object.defineProperty(exports, "Prompt", {
  enumerable: true,
  get: function () {
    return Prompt_1.Prompt;
  }
});