"use strict";

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useLocalstorageState = void 0;
const react_1 = require("react");
// original source from: https://raw.githubusercontent.com/imbhargav5/rooks/main/src/hooks/useLocalstorageState.ts
// Gets value from localstorage
function getValueFromLocalStorage(key) {
  var _a;
  if (typeof localStorage === 'undefined') {
    return null;
  }
  const storedValue = (_a = localStorage.getItem(key)) !== null && _a !== void 0 ? _a : 'null';
  try {
    return JSON.parse(storedValue);
  } catch (error) {
    console.error(error);
  }
  return storedValue;
}
// Saves value to localstorage
function saveValueToLocalStorage(key, value) {
  if (typeof localStorage === 'undefined') {
    return null;
  }
  return localStorage.setItem(key, JSON.stringify(value));
}
/**
 * @param key Key of the localStorage object
 * @param initialState Default initial value
 */
function initialize(key, initialState) {
  const valueLoadedFromLocalStorage = getValueFromLocalStorage(key);
  if (valueLoadedFromLocalStorage === null) {
    return initialState;
  } else {
    return valueLoadedFromLocalStorage;
  }
}
/**
 * An extension to useState which will store and load the value from localStorage
 * @example const [myValue, setMyValue] = useLocalstorageState("myValue", "initialValue");
 * @param {string} key - Key of the localStorage object
 * @param {any} initialState - Default initial value
 */
function useLocalstorageState(key, initialState) {
  const _ref = (0, react_1.useState)(() => initialize(key, initialState)),
    _ref2 = _slicedToArray(_ref, 2),
    value = _ref2[0],
    setValueState = _ref2[1];
  const isUpdateFromCrossDocumentListener = (0, react_1.useRef)(false);
  const isUpdateFromWithinDocumentListener = (0, react_1.useRef)(false);
  const CUSTOM_EVENT_TYPE_NAME = `rooks-${key}-localstorage-update`;
  (0, react_1.useEffect)(() => {
    /**
     * We need to ensure there is no loop of
     * storage events fired. Hence we are using a ref
     * to keep track of whether setValue is from another
     * storage event
     */
    if (!isUpdateFromCrossDocumentListener.current || !isUpdateFromWithinDocumentListener.current) {
      saveValueToLocalStorage(key, value);
    }
  }, [key, value]);
  const listenToCrossDocumentStorageEvents = (0, react_1.useCallback)(event => {
    var _a;
    if (event.storageArea === localStorage && event.key === key) {
      try {
        isUpdateFromCrossDocumentListener.current = true;
        const newValue = JSON.parse((_a = event.newValue) !== null && _a !== void 0 ? _a : 'null');
        if (value !== newValue) {
          setValueState(newValue);
        }
      } catch (error) {
        console.log(error);
      }
    }
  }, [key, value]);
  // check for changes across documents
  (0, react_1.useEffect)(() => {
    // eslint-disable-next-line no-negated-condition
    if (typeof window !== 'undefined') {
      window.addEventListener('storage', listenToCrossDocumentStorageEvents);
      return () => {
        window.removeEventListener('storage', listenToCrossDocumentStorageEvents);
      };
    } else {
      console.warn('useLocalstorageState: window is undefined.');
      return () => {};
    }
  }, [listenToCrossDocumentStorageEvents]);
  const listenToCustomEventWithinDocument = (0, react_1.useCallback)(event => {
    try {
      isUpdateFromWithinDocumentListener.current = true;
      const newValue = event.detail.newValue;
      if (value !== newValue) {
        setValueState(newValue);
      }
    } catch (error) {
      console.log(error);
    }
  }, [value]);
  // check for changes within document
  (0, react_1.useEffect)(() => {
    if (typeof document !== 'undefined') {
      // @ts-ignore
      document.addEventListener(CUSTOM_EVENT_TYPE_NAME, listenToCustomEventWithinDocument);
      return () => {
        // @ts-ignore
        document.removeEventListener(CUSTOM_EVENT_TYPE_NAME, listenToCustomEventWithinDocument);
      };
    } else {
      console.warn('[useLocalstorageState] document is undefined.');
      return () => {};
    }
  }, [CUSTOM_EVENT_TYPE_NAME, listenToCustomEventWithinDocument]);
  const broadcastValueWithinDocument = (0, react_1.useCallback)(newValue => {
    // eslint-disable-next-line no-negated-condition
    if (typeof document !== 'undefined') {
      const event = new CustomEvent(CUSTOM_EVENT_TYPE_NAME, {
        detail: {
          newValue
        }
      });
      document.dispatchEvent(event);
    } else {
      console.warn('[useLocalstorageState] document is undefined.');
    }
  }, [CUSTOM_EVENT_TYPE_NAME]);
  const setValue = (0, react_1.useCallback)(newValue => {
    isUpdateFromCrossDocumentListener.current = false;
    isUpdateFromWithinDocumentListener.current = false;
    setValueState(newValue);
    broadcastValueWithinDocument(newValue);
  }, [broadcastValueWithinDocument]);
  const removeValue = (0, react_1.useCallback)(() => {
    localStorage.removeItem(key);
  }, [key]);
  return [value, setValue, removeValue];
}
exports.useLocalstorageState = useLocalstorageState;