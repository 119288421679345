"use strict";

var __createBinding = void 0 && (void 0).__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  var desc = Object.getOwnPropertyDescriptor(m, k);
  if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
    desc = {
      enumerable: true,
      get: function () {
        return m[k];
      }
    };
  }
  Object.defineProperty(o, k2, desc);
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});
var __setModuleDefault = void 0 && (void 0).__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, "default", {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o["default"] = v;
});
var __importStar = void 0 && (void 0).__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
  __setModuleDefault(result, mod);
  return result;
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MetricCard = void 0;
const react_1 = __importStar(require("react"));
const foundations_1 = require("../../foundations");
const styles_1 = require("./styles");
const MetricCard = props => {
  const title = props.title,
    value = props.value,
    comparisonValue = props.comparisonValue,
    comparisonStatus = props.comparisonStatus,
    _props$size = props.size,
    size = _props$size === void 0 ? 'M' : _props$size;
  const status = (0, react_1.useMemo)(() => {
    if (!value) return false;
    return comparisonStatus !== null && comparisonStatus !== void 0 ? comparisonStatus : comparisonValue ? value < comparisonValue : false;
  }, [comparisonStatus, comparisonValue, value]);
  return react_1.default.createElement(styles_1.Card, null, title && react_1.default.createElement(styles_1.Title, {
    size: "xs",
    mb: size === 'S' ? 'm' : 'xxs'
  }, title), react_1.default.createElement(foundations_1.Flex, null, react_1.default.createElement(styles_1.MetricValue, {
    size: size
  }, value), comparisonValue && react_1.default.createElement(styles_1.ComparisonContainer, {
    size: size,
    ml: "s"
  }, react_1.default.createElement(styles_1.ComparisonIcon, {
    status: status,
    mr: "xxs"
  }, status ? '↑' : '↓'), react_1.default.createElement(styles_1.ComparisonValue, null, comparisonValue))));
};
exports.MetricCard = MetricCard;