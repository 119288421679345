"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useTheme = void 0;
const react_1 = require("react");
const styled_components_1 = require("styled-components");
/**
 * Use the current theme.
 */
function useTheme() {
  const theme = (0, react_1.useContext)(styled_components_1.ThemeContext);
  if (!theme) {
    throw new Error('Cannot useTheme when no theme is specified. Make sure there is a <ThemeContext /> parent');
  }
  return theme;
}
exports.useTheme = useTheme;