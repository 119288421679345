"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.printMode = void 0;
/**
 * Check if user has appended #print to the url
 *
 * @returns {boolean} Returns true if user appended #print to the url
 */
const printMode = () => (window && window.location.hash === '#print') || false;
exports.printMode = printMode;
