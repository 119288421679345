"use strict";

const _excluded = ["children", "disabled", "label", "error", "value", "placeholder", "onChange", "onClear", "onDropdownButtonClick", "clearButtonRef", "readOnly", "pickerRef", "tooltip"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }
function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }
var __createBinding = void 0 && (void 0).__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  var desc = Object.getOwnPropertyDescriptor(m, k);
  if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
    desc = {
      enumerable: true,
      get: function () {
        return m[k];
      }
    };
  }
  Object.defineProperty(o, k2, desc);
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});
var __setModuleDefault = void 0 && (void 0).__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, "default", {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o["default"] = v;
});
var __importStar = void 0 && (void 0).__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
  __setModuleDefault(result, mod);
  return result;
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledDurationMSInput = void 0;
const react_1 = __importStar(require("react"));
const Icon_1 = require("../Icon");
const InputLabel_1 = require("../InputLabel");
const DateTimeComponents_1 = require("../Shared/DateTimeComponents");
const InputText_1 = require("../InputText");
const TimeInput_1 = require("./TimeInput");
const StyledDurationMSInput = _ref => {
  let children = _ref.children,
    _ref$disabled = _ref.disabled,
    disabled = _ref$disabled === void 0 ? false : _ref$disabled,
    _ref$label = _ref.label,
    label = _ref$label === void 0 ? '' : _ref$label,
    error = _ref.error,
    value = _ref.value,
    placeholder = _ref.placeholder,
    onChange = _ref.onChange,
    onClear = _ref.onClear,
    onDropdownButtonClick = _ref.onDropdownButtonClick,
    clearButtonRef = _ref.clearButtonRef,
    readOnly = _ref.readOnly,
    pickerRef = _ref.pickerRef,
    tooltip = _ref.tooltip,
    rest = _objectWithoutProperties(_ref, _excluded);
  const _ref2 = (0, react_1.useState)(null),
    _ref3 = _slicedToArray(_ref2, 2),
    inValidInput = _ref3[0],
    setInvalidInput = _ref3[1];
  const handleOnClear = () => {
    if (onClear) {
      onClear(undefined);
    }
  };
  const handleInvalidInput = message => {
    setInvalidInput(message);
  };
  // In case of no label, pass a non-breaking space into the label to keep error text aligned vertically
  const labelOrNBSP = label || '&nbsp;';
  return react_1.default.createElement(InputText_1.InputStyles.Wrapper, _objectSpread({}, rest), react_1.default.createElement(InputText_1.InputTooltip, {
    content: tooltip || '',
    placement: "top",
    arrow: true,
    disabled: !tooltip
  }, react_1.default.createElement(InputLabel_1.InputLabel, {
    tooltip: tooltip,
    disabled: disabled,
    label: label
  }, labelOrNBSP)), react_1.default.createElement(TimeInput_1.TimeInput, {
    pickerRef: pickerRef,
    value: value,
    disabled: disabled,
    onChange: onChange,
    maxDetail: "millisecond",
    handleInvalidInput: handleInvalidInput,
    error: error || inValidInput
  }), error && react_1.default.createElement(InputText_1.InputError, null, error), react_1.default.createElement(DateTimeComponents_1.Icons, null, !disabled && onClear && react_1.default.createElement(DateTimeComponents_1.ClearButton, {
    tabIndex: -1,
    "data-testid": "clear-button",
    onClick: handleOnClear,
    type: "button",
    ref: clearButtonRef
  }, react_1.default.createElement(Icon_1.Icon, {
    name: "TextClear"
  }))), children);
};
exports.StyledDurationMSInput = StyledDurationMSInput;