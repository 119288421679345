"use strict";

var __createBinding = void 0 && (void 0).__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  var desc = Object.getOwnPropertyDescriptor(m, k);
  if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
    desc = {
      enumerable: true,
      get: function () {
        return m[k];
      }
    };
  }
  Object.defineProperty(o, k2, desc);
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});
var __setModuleDefault = void 0 && (void 0).__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, "default", {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o["default"] = v;
});
var __importStar = void 0 && (void 0).__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
  __setModuleDefault(result, mod);
  return result;
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ToastContainer = exports.ToastContext = void 0;
const react_1 = __importStar(require("react"));
const react_toastify_1 = require("react-toastify");
require("react-toastify/dist/ReactToastify.css");
const Icon_1 = require("../Icon");
exports.ToastContext = react_1.default.createContext({
  errorCodes: {}
});
const CloseButton = () => react_1.default.createElement("div", {
  className: "Toastify__toast--closeButton"
}, react_1.default.createElement(Icon_1.Icon, {
  name: "Close"
}));
const ToastContainer = ({
  children,
  errorCodes
}) => {
  const value = (0, react_1.useMemo)(() => ({
    errorCodes
  }), [errorCodes]);
  return react_1.default.createElement(exports.ToastContext.Provider, {
    value: value
  }, react_1.default.createElement(react_1.default.Fragment, null, children, react_1.default.createElement(react_toastify_1.ToastContainer, {
    closeButton: react_1.default.createElement(CloseButton, null),
    transition: react_toastify_1.Slide,
    hideProgressBar: true,
    position: "bottom-left"
  })));
};
exports.ToastContainer = ToastContainer;