"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.elevation = void 0;
exports.elevation = {
  sentry: 9000,
  critical: 8000,
  dropdown: 7000,
  modalLift: 6000,
  modalOverlay: 5000,
  widgetEditor: 4750,
  globalNav: 4500,
  alert: 4000,
  header: 3000,
  footer: 2000,
  bodyLift: 1000,
  body: 0 // the base elevation of the app
};