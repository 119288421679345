"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Radian = exports.Degree = void 0;
const AngleUnits_1 = require("../UnitTypes/AngleUnits");
exports.Degree = new AngleUnits_1.AngleUnitDefinition({
    name: 'DEGREE',
    label: 'Degree',
    symbol: '°',
    scaleToSI: 1
});
exports.Radian = new AngleUnits_1.AngleUnitDefinition({
    name: 'RADIAN',
    label: 'Radian',
    symbol: 'rad',
    scaleToSI: 57.29578
});
