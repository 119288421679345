"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.IconQA = void 0;
const testutils_1 = require("../../testutils");
class IconQA extends testutils_1.QA {
  get name() {
    return this.element.getAttribute('data-testtype');
  }
}
exports.IconQA = IconQA;
IconQA.componentName = 'Icon';
IconQA.selector = "[data-testid='icon']";