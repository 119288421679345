"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.maskBitsPerInt = exports.setBit = exports.flipBit = exports.fromBitMask = exports.toBitMask = exports.requiredBitMaskLength = void 0;
const maskBitsPerInt = 31; // wasting the sign bit (since WebGL 1.0 does not support uint)
exports.maskBitsPerInt = maskBitsPerInt;
function requiredBitMaskLength(maxValue) {
    return Math.ceil((maxValue + 1) / maskBitsPerInt); // 31 bits in each bucket
    // value 0 requires 1 bit
}
exports.requiredBitMaskLength = requiredBitMaskLength;
/**
 * Return an array of ids into a bitmask. Each id has to be > 0 and <= maxValue
 */
function toBitMask(ids, maxValue = 10016, // This default comes from thinking that we max need 10000 ids,
// and there is space for at least 10016 ids in a mask which contains 324 ints
// (not sure why it is set to 10016 and not 31*324=10044)
array) {
    const buckets = requiredBitMaskLength(maxValue);
    const res = array || new Int32Array(buckets);
    for (let i = 0; i < ids.length; i += 1) {
        const id = ids[i];
        if (id > maxValue) {
            //TODO: should throw an error. console.log should not be used in node
            //eslint-disable-next-line no-console
            console.error(`id ${id} is too large to put in bitmask with max value ${maxValue}`);
        }
        else {
            const bucket = Math.floor(id / maskBitsPerInt);
            res[bucket] |= 1 << (id - bucket * maskBitsPerInt) % maskBitsPerInt; // set bit
        }
    }
    return res;
}
exports.toBitMask = toBitMask;
function fromBitMask(bitmask, idOffset = 0) {
    // return a number ids based on a bitmask
    const res = [];
    // Run through integers in bitmask
    for (let i = 0; i < bitmask.length; i += 1) {
        const bucketOffset = i * maskBitsPerInt + idOffset;
        // If there are none zero bits in the integer
        if (bitmask[i]) {
            // Run through the bits in the integer
            for (let j = 0; j < maskBitsPerInt; j += 1) {
                // If the bit is set to true, then add support id to output array
                if (bitmask[i] & (1 << j)) {
                    res.push(bucketOffset + j);
                }
            }
        }
    }
    return res;
}
exports.fromBitMask = fromBitMask;
function flipBit(bitmask, id) {
    const bucket = Math.floor(id / maskBitsPerInt);
    const bitId = 1 << id % maskBitsPerInt; // set bit
    bitmask[bucket] ^= bitId; // flip bit
    return bitmask;
}
exports.flipBit = flipBit;
function setBit(bitmask, id) {
    const bucket = Math.floor(id / maskBitsPerInt);
    const bitId = 1 << id % maskBitsPerInt; // set bit
    bitmask[bucket] |= bitId; // flip bit
    return bitmask;
}
exports.setBit = setBit;
