"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.hoursAreDifferent = void 0;
const hoursAreDifferent = (date1, date2) => {
  // Compare 11:22:00 and 11:22 properly
  let newDate1 = date1;
  let newDate2 = date2;
  // Compare 11:22 and 11:22:00 properly
  if ((date1 === null || date1 === void 0 ? void 0 : date1.length) < (date2 === null || date2 === void 0 ? void 0 : date2.length) && (date2 === null || date2 === void 0 ? void 0 : date2.endsWith(':00'))) {
    newDate2 = date2.slice(0, -3);
  }
  // Compare 11:22:00 and 11:22 properly
  if ((date2 === null || date2 === void 0 ? void 0 : date2.length) < (date1 === null || date1 === void 0 ? void 0 : date1.length) && (date1 === null || date1 === void 0 ? void 0 : date1.endsWith(':00'))) {
    newDate1 = date1.slice(0, -3);
  }
  return date1 && !date2 || !date1 && date2 || newDate1 && newDate2 && newDate1 !== newDate2;
};
exports.hoursAreDifferent = hoursAreDifferent;