"use strict";

var __createBinding = void 0 && (void 0).__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  var desc = Object.getOwnPropertyDescriptor(m, k);
  if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
    desc = {
      enumerable: true,
      get: function () {
        return m[k];
      }
    };
  }
  Object.defineProperty(o, k2, desc);
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});
var __setModuleDefault = void 0 && (void 0).__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, "default", {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o["default"] = v;
});
var __importStar = void 0 && (void 0).__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
  __setModuleDefault(result, mod);
  return result;
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BlankTable = void 0;
const react_1 = __importStar(require("react"));
const lodash_1 = require("lodash");
const styles_1 = require("./styles");
const components_1 = require("./components");
const BlankTable = ({
  columns,
  rows,
  size
}) => {
  const ref = (0, react_1.useRef)(null);
  const showBothTitles = rows.showTitles && columns.showTitles;
  const rowsAmount = columns.showTitles ? rows.amount + 1 : rows.amount;
  const columnsAmount = rows.showTitles ? columns.amount + 1 : columns.amount;
  return react_1.default.createElement(styles_1.Table, {
    "data-widget-type": "oqton.items.v1.layout.widgets.BlankTableWidget",
    size: size,
    columns: columnsAmount,
    ref: ref
  }, react_1.default.createElement("tbody", null, (0, lodash_1.range)(rowsAmount).map((val, rowIndex) => react_1.default.createElement("tr", {
    key: `row_${rowIndex}`
  }, (0, lodash_1.range)(columnsAmount).map((val, columnIndex) => {
    var _a, _b, _c, _d;
    if (showBothTitles && rowIndex === 0 && columnIndex === 0) {
      return react_1.default.createElement(components_1.TableHeader, {
        key: `header_${rowIndex}_${columnIndex}`,
        width: columns.titleWidth,
        height: rows.titleHeight
      });
    }
    if (rows.showTitles && columnIndex === 0) {
      const index = showBothTitles ? rowIndex - 1 : rowIndex;
      return react_1.default.createElement(components_1.TableHeader, {
        key: `header_${rowIndex}_${columnIndex}`,
        content: (_a = rows.titles) === null || _a === void 0 ? void 0 : _a[index],
        height: (_b = rows.heights) === null || _b === void 0 ? void 0 : _b[index],
        width: columns.titleWidth
      });
    }
    if (columns.showTitles && rowIndex === 0) {
      const index = showBothTitles ? columnIndex - 1 : columnIndex;
      return react_1.default.createElement(components_1.TableHeader, {
        key: `header_${rowIndex}_${columnIndex}`,
        content: (_c = columns.titles) === null || _c === void 0 ? void 0 : _c[index],
        width: (_d = columns.widths) === null || _d === void 0 ? void 0 : _d[index],
        height: rows.titleHeight
      });
    }
    return react_1.default.createElement("td", {
      key: `cell_${rowIndex}_${columnIndex}`
    });
  })))));
};
exports.BlankTable = BlankTable;