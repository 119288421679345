"use strict";

const _excluded = ["disabled", "onFocus", "onChange", "value", "validator", "error", "onValidValueChange", "align", "format", "label", "hasDropdown", "mb", "required"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }
function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }
var __createBinding = void 0 && (void 0).__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  var desc = Object.getOwnPropertyDescriptor(m, k);
  if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
    desc = {
      enumerable: true,
      get: function () {
        return m[k];
      }
    };
  }
  Object.defineProperty(o, k2, desc);
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});
var __setModuleDefault = void 0 && (void 0).__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, "default", {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o["default"] = v;
});
var __importStar = void 0 && (void 0).__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
  __setModuleDefault(result, mod);
  return result;
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DurationPicker = void 0;
const react_1 = __importStar(require("react"));
const lodash_1 = require("lodash");
const foundations_1 = require("../../foundations");
const index_1 = require("../index");
const dateTimePickerHelpers_1 = require("../../utils/dateTimePickerHelpers");
const Input_1 = require("../Input");
const components_1 = require("./components");
const utils_1 = require("./utils");
const index_2 = require("./index");
const DurationPicker = _ref => {
  let disabled = _ref.disabled,
    onFocus = _ref.onFocus,
    onChange = _ref.onChange,
    value = _ref.value,
    validator = _ref.validator,
    error = _ref.error,
    onValidValueChange = _ref.onValidValueChange,
    _ref$align = _ref.align,
    align = _ref$align === void 0 ? 'left' : _ref$align,
    _ref$format = _ref.format,
    format = _ref$format === void 0 ? 'h:m:s' : _ref$format,
    _ref$label = _ref.label,
    label = _ref$label === void 0 ? '' : _ref$label,
    _ref$hasDropdown = _ref.hasDropdown,
    hasDropdown = _ref$hasDropdown === void 0 ? true : _ref$hasDropdown,
    _ref$mb = _ref.mb,
    mb = _ref$mb === void 0 ? 'm' : _ref$mb,
    required = _ref.required,
    rest = _objectWithoutProperties(_ref, _excluded);
  const dropdownButtonRef = (0, react_1.useRef)(null);
  const pickerRef = (0, react_1.useRef)(null);
  const panelRef = (0, react_1.useRef)(null);
  const _ref2 = (0, react_1.useState)(false),
    _ref3 = _slicedToArray(_ref2, 2),
    open = _ref3[0],
    setOpen = _ref3[1];
  const handleClick = (0, react_1.useCallback)(e => {
    if (disabled) {
      return e.preventDefault();
    }
    const panelNode = panelRef.current;
    const dropdownButtonNode = dropdownButtonRef.current;
    const pickerNode = pickerRef.current;
    // Keep open if clicking again on the masked input whilst open
    // @ts-expect-error
    if (e.target.type === 'text' && open) {
      e.preventDefault();
      return;
    }
    if (hasDropdown) {
      // @ts-expect-error
      if (dropdownButtonNode && dropdownButtonNode.contains(e.target) || e.target === pickerNode) {
        setOpen(!open);
        e.preventDefault();
        return;
      }
      // Open (or keep open) the panel if you click on it
      // @ts-expect-error
      if (panelNode && panelNode.contains(e.target)) {
        setOpen(true);
        e.preventDefault();
        return;
      }
      // If the click is anywhere else, close the panel
      setOpen(false);
    }
    // Toggle the panel open state if you click on the dropdown button
  }, [disabled, dropdownButtonRef, hasDropdown, open, panelRef, pickerRef]);
  const validationError = (0, react_1.useMemo)(() => {
    if ((0, lodash_1.isFunction)(validator)) {
      const validity = validator(value);
      if (validity instanceof Input_1.ValidationError) return validity;
    }
  }, [validator, value]);
  (0, react_1.useEffect)(() => {
    if (typeof window !== `undefined`) {
      if (open) {
        document.addEventListener('mousedown', handleClick, false);
      }
    }
    return () => {
      if (typeof window !== `undefined`) {
        document.removeEventListener('mousedown', handleClick, false);
      }
    };
  }, [handleClick, open]);
  const updateTime = (division, val) => {
    const secs = (0, index_1.formatIncludesDays)(format) ? (0, utils_1.getTotalSecondsDays)(division, val, value) : (0, utils_1.getTotalSeconds)(division, val, value);
    if (secs) {
      handleChange(secs);
    }
  };
  const handleChange = (0, react_1.useMemo)(() => {
    return value => {
      // always display user input
      if ((0, lodash_1.isFunction)(validator)) {
        if (onValidValueChange) {
          const val = validator(value);
          if (!(val instanceof Input_1.ValidationError)) onValidValueChange(val !== undefined ? val : value);
        }
      }
      if (onChange) onChange(value); // always call the original onChange, regardless of value validity
    };
  }, [validator, onChange, onValidValueChange]);
  let finalError = undefined;
  if (validationError) {
    finalError = error || validationError.error;
  }
  const isNegative = value !== null && value < 0;
  return react_1.default.createElement(foundations_1.Box, {
    mb: mb,
    onMouseDown: handleClick
  }, react_1.default.createElement(index_2.StyledDurationInput, _objectSpread({
    format: format,
    disabled: disabled,
    hasDropdown: true,
    dropdownIsOpen: open,
    pickerRef: pickerRef,
    dropdownButtonRef: dropdownButtonRef,
    value: (0, index_1.formatIncludesDays)(format) ? (0, utils_1.convertSecsToTimeStringDays)(value) : (0, utils_1.convertSecsToTimeString)(value),
    onChange: handleChange,
    label: required ? `${label} *` : label,
    mb: 0,
    isNegative: isNegative,
    error: finalError
  }, rest)), open && react_1.default.createElement(index_1.Portal, {
    maintainPosition: true
  }, parentRect => {
    // if (!(parentRect instanceof DOMRect)) return null; // FAILS ON TEST because DOMRect undefined
    if (!parentRect || (parentRect === null || parentRect === void 0 ? void 0 : parentRect.top) === undefined) return null;
    return react_1.default.createElement(index_2.DurationPickerStyles.PanelWrapper, {
      "data-testid": "panel-wrap",
      ref: panelRef,
      parentRect: parentRect
    }, react_1.default.createElement(components_1.TimePickerPanel, {
      align: align,
      onDaySelect: day => {
        updateTime(dateTimePickerHelpers_1.TIME_DIVISIONS.day, day);
      },
      onHourSelect: hour => {
        updateTime(dateTimePickerHelpers_1.TIME_DIVISIONS.hour, hour);
      },
      onMinuteSelect: minute => {
        updateTime(dateTimePickerHelpers_1.TIME_DIVISIONS.minute, minute);
      },
      onSecondSelect: second => {
        updateTime(dateTimePickerHelpers_1.TIME_DIVISIONS.second, second);
      },
      format: format,
      international: true,
      value: (0, index_1.formatIncludesDays)(format) ? (0, utils_1.convertSecsToTimeStringDays)(value) : (0, utils_1.convertSecsToTimeString)(value)
    }));
  }));
};
exports.DurationPicker = DurationPicker;