"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Dictionary = void 0;
var Dictionary;
(function (Dictionary) {
    /**
     * Is the given input a dictionary?
     * @param input The input.
     */
    function isDictionary(input) {
        return typeof input === 'object' && input !== null && !Array.isArray(input);
    }
    Dictionary.isDictionary = isDictionary;
    /**
     * Get the keys of the the given dictionary.
     * @param input The dictionary to retrieve keys from.
     */
    function keysOf(input) {
        return Object.keys(input);
    }
    Dictionary.keysOf = keysOf;
    /**
     * Get the values of the the given dictionary.
     * @param input The dictionary to retrieve values from.
     */
    function valuesOf(input) {
        return Object.values(input);
    }
    Dictionary.valuesOf = valuesOf;
    /**
     * Get the entries of the the given dictionary.
     * @param input The dictionary to retrieve entries from.
     */
    function entriesOf(input) {
        return Object.entries(input);
    }
    Dictionary.entriesOf = entriesOf;
    /**
     * Count the keys in a dictionary.
     * @param input The dictionary to calculate the size of.
     */
    function sizeOf(input) {
        return Object.keys(input).length;
    }
    Dictionary.sizeOf = sizeOf;
    /**
     * Does the given dictionary contain the given key?
     * @param input The dictionary.
     * @param key The key to lookup.
     */
    function has(input, key) {
        return Object.prototype.hasOwnProperty.call(input, key) && input[key] !== undefined;
    }
    Dictionary.has = has;
    /**
     * Are the given dictionaries equal.
     * @param a The first dictionary.
     * @param b The second dictionary.
     * @param valueComparator The function used to compare values, defaults to exact equality.
     */
    function equal(a, b, valueComparator = defaultValueComparator) {
        const aKeys = Object.keys(a);
        const bKeys = Object.keys(b);
        if (aKeys.length !== bKeys.length)
            return false;
        for (const aKey of aKeys) {
            const bKey = aKey;
            if (!bKeys.includes(bKey))
                return false;
            if (!valueComparator(a[aKey], b[bKey])) {
                return false;
            }
        }
        return true;
    }
    Dictionary.equal = equal;
    function defaultValueComparator(a, b) {
        return a === b;
    }
    /**
     * Read a value from a dictionary using a key.
     * This is safer than directly using `input[key]` because it ensures that
     * the input is a valid dictionary, and that the key is not referring to a
     * property on the object's prototype.
     * @example
     *   const dict = {a: 123};
     *   console.log(dict['a']); // 123
     *   console.log(dict['hasOwnProperty']); // ƒ hasOwnProperty() { [native code] }
     *
     *   console.log(Dictionary.get(dict, 'a')); // 123
     *   console.log(Dictionary.get(dict, 'hasOwnProperty')); // undefined
     *
     *   function someRandomFunction() {}
     *   console.log(Dictionary.get(someRandomFunction, 'name')); // throws TypeError because a function is not a dictionary
     * @param input The dictionary to read a value from.
     * @param key The key to read.
     */
    function get(input, key) {
        if (!isDictionary(input)) {
            throw new TypeError('Cannot get a key on a non-dictionary.');
        }
        if (has(input, key)) {
            return input[key];
        }
        return undefined;
    }
    Dictionary.get = get;
    /**
     * Set a value on a dictionary using a key.
     * This is safer than directly using `input[key] = value`
     * because it verifies that `input` is a dictionary before setting the value.
     *
     * Note: This mutates the dictionary.
     *
     * @param input The dictionary to write to.
     * @param key The key to set.
     * @param value The value to set.
     */
    function set(input, key, value) {
        if (!isDictionary(input)) {
            throw new TypeError('Cannot set a key on a non-dictionary.');
        }
        input[key] = value;
        return input;
    }
    Dictionary.set = set;
    /**
     * Map over the entries in a dictionary, returning a new dictionary.
     * @param input The dictionary to iterate over.
     * @param fn A function which receives a property value and key parameters.
     */
    function map(input, fn) {
        return Object.fromEntries(keysOf(input).map(key => [key, fn(input[key], key)]));
    }
    Dictionary.map = map;
    /**
     * Filter the entries in a dictionary, returning a new dictionary.
     * @param input The dictionary to iterate over.
     * @param fn A function which receives a property value and key parameters and returns true or false.
     */
    function filter(input, fn) {
        return Object.fromEntries(entriesOf(input).filter(([key, value]) => fn(value, key)));
    }
    Dictionary.filter = filter;
    /**
     * Visit the entries in the given dictionary.
     * @param input The dictionary to iterate over.
     * @param fn A function which receives a property value and key parameters.
     */
    function forEach(input, fn) {
        keysOf(input).forEach(key => fn(input[key], key));
    }
    Dictionary.forEach = forEach;
    /**
     * Build a dictionary from the given entries.
     * @param entries The key / value pairs.
     */
    function fromEntries(entries) {
        return Object.fromEntries(entries);
    }
    Dictionary.fromEntries = fromEntries;
})(Dictionary || (exports.Dictionary = Dictionary = {}));
