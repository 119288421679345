"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
var __createBinding = void 0 && (void 0).__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  var desc = Object.getOwnPropertyDescriptor(m, k);
  if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
    desc = {
      enumerable: true,
      get: function () {
        return m[k];
      }
    };
  }
  Object.defineProperty(o, k2, desc);
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});
var __setModuleDefault = void 0 && (void 0).__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, "default", {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o["default"] = v;
});
var __importStar = void 0 && (void 0).__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
  __setModuleDefault(result, mod);
  return result;
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Dialog = void 0;
const react_1 = __importStar(require("react"));
const styled_components_1 = require("styled-components");
const index_1 = require("../index");
const hooks_1 = require("../../hooks");
const theme_1 = require("../../theme");
const DialogStyles_1 = require("./DialogStyles");
const Dialog = ({
  children,
  open,
  onCancel,
  title
}) => {
  const _ref = (0, theme_1.useResponsiveContext)(),
    isCurrentBreakpointLargerThan = _ref.isCurrentBreakpointLargerThan;
  const theme = (0, react_1.useContext)(styled_components_1.ThemeContext);
  const _ref2 = (0, hooks_1.useTranslation)(),
    t = _ref2.t;
  const bodyWidth = '384';
  const forceBodyWidth = isCurrentBreakpointLargerThan('xs') ? bodyWidth : `100%`;
  const cancelButton = isCurrentBreakpointLargerThan('xs') ? {
    text: t('general:cancel'),
    onClick: onCancel
  } : {
    icon: 'Cancel',
    onClick: onCancel
  };
  return react_1.default.createElement(DialogStyles_1.ModalWrapper, {
    open: !!open
  }, isCurrentBreakpointLargerThan('xs') && react_1.default.createElement("div", {
    style: {
      position: 'absolute',
      right: theme.spacing.xl,
      top: theme.spacing.xl
    }
  }, react_1.default.createElement(index_1.Button, _objectSpread({
    size: "l",
    mode: "secondary"
  }, cancelButton))), react_1.default.createElement(DialogStyles_1.InnerModalWrapper, {
    isSmallWidth: !isCurrentBreakpointLargerThan('xs')
  }, react_1.default.createElement(DialogStyles_1.HeaderRow, {
    width: forceBodyWidth
  }, react_1.default.createElement("div", null), react_1.default.createElement(index_1.Heading, {
    size: "l"
  }, title), !isCurrentBreakpointLargerThan('xs') ? react_1.default.createElement(index_1.Button, _objectSpread({
    mb: "m",
    size: "l",
    mode: "secondary"
  }, cancelButton)) : react_1.default.createElement("div", null)), react_1.default.createElement(DialogStyles_1.DialogContainer, {
    width: forceBodyWidth
  }, children)));
};
exports.Dialog = Dialog;