"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.InputQA = void 0;
const __1 = require("../");
/**
 * QA Class for the base HTMLInputElement
 */
class InputQA extends __1.QA {
  get isActive() {
    return this.element.dataset.isActive === 'true';
  }
  /**
   * Get the name of the element.
   */
  get name() {
    return this.element.name;
  }
  /**
   * Get the type of the input, e.g. "text" or "password".
   */
  get type() {
    return this.element.type;
  }
  /**
   * Get the value of the input.
   */
  get value() {
    return this.element.value;
  }
  /**
   * Set the value of the input using a change handler.
   */
  set value(value) {
    this.change({
      target: {
        value
      }
    });
  }
}
exports.InputQA = InputQA;
InputQA.componentName = 'Input';
InputQA.selector = 'input';