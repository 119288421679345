"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getStaticRanges = void 0;
const utils_core_1 = require("@oqton/utils-core");
const getStaticRanges = () => {
  const _staticRangeHandler = currentRange => {
    return {
      isSelected(range) {
        return utils_core_1.date.isSame({
          date: range.startDate,
          toEqual: currentRange.range().startDate,
          unit: 'day'
        }) && utils_core_1.date.isSame({
          date: range.endDate,
          toEqual: currentRange.range().endDate,
          unit: 'day'
        });
      }
    };
  };
  function _createStaticRanges(ranges) {
    return ranges.map(range => _objectSpread(_objectSpread({}, _staticRangeHandler(range)), range));
  }
  return _createStaticRanges([{
    label: 'Yesterday',
    range: () => ({
      startDate: utils_core_1.date.getDate(utils_core_1.date.getStartOf({
        date: utils_core_1.date.subtract({
          amount: 1,
          unit: 'day'
        }),
        unit: 'day'
      })),
      endDate: utils_core_1.date.getDate(utils_core_1.date.getEndOf({
        date: utils_core_1.date.subtract({
          amount: 1,
          unit: 'day'
        }),
        unit: 'day'
      }))
    })
  }, {
    label: 'Today',
    range: () => ({
      startDate: utils_core_1.date.getDate(utils_core_1.date.getStartOf({
        unit: 'day'
      })),
      endDate: utils_core_1.date.getDate(utils_core_1.date.getEndOf({
        unit: 'day'
      }))
    })
  }, {
    label: 'This Week',
    range: () => ({
      startDate: utils_core_1.date.getDate(utils_core_1.date.getStartOf({
        unit: 'week'
      })),
      endDate: utils_core_1.date.getDate(utils_core_1.date.getEndOf({
        unit: 'week'
      }))
    })
  }, {
    label: 'Next Week',
    range: () => ({
      startDate: utils_core_1.date.getDate(utils_core_1.date.getStartOf({
        date: utils_core_1.date.add({
          amount: 1,
          unit: 'week'
        }),
        unit: 'week'
      })),
      endDate: utils_core_1.date.getDate(utils_core_1.date.getEndOf({
        date: utils_core_1.date.add({
          amount: 1,
          unit: 'week'
        }),
        unit: 'week'
      }))
    })
  }, {
    label: 'This Month',
    range: () => ({
      startDate: utils_core_1.date.getDate(utils_core_1.date.getStartOf({
        unit: 'month'
      })),
      endDate: utils_core_1.date.getDate(utils_core_1.date.getEndOf({
        unit: 'month'
      }))
    })
  }, {
    label: 'Next Month',
    range: () => ({
      startDate: utils_core_1.date.getDate(utils_core_1.date.getStartOf({
        date: utils_core_1.date.add({
          amount: 1,
          unit: 'month'
        }),
        unit: 'month'
      })),
      endDate: utils_core_1.date.getDate(utils_core_1.date.getEndOf({
        date: utils_core_1.date.add({
          amount: 1,
          unit: 'month'
        }),
        unit: 'month'
      }))
    })
  }]);
};
exports.getStaticRanges = getStaticRanges;