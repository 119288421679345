"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PointUnitDefinition = void 0;
const UnitDefinition_1 = require("../UnitDefinition");
const Formatters_1 = require("../Formatters");
/**
 * Point Unit Definition.
 */
class PointUnitDefinition extends UnitDefinition_1.UnitDefinition {
    formatAsTokens(input, precision = this.precision, withSymbol = true) {
        const { symbol } = this;
        if (input == null || !Array.isArray(input)) {
            return [];
        }
        return (0, Formatters_1.formatPointAsTokens)(input, precision, withSymbol ? symbol : undefined);
    }
}
exports.PointUnitDefinition = PointUnitDefinition;
