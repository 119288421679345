"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.extensionLowerCase = exports.baseName = void 0;
function baseName(str, stripExtension = true) {
    const input = String(str);
    const base = input.substring(input.lastIndexOf('/') + 1);
    if (stripExtension && base.lastIndexOf('.') !== -1)
        return base.substring(0, base.lastIndexOf('.'));
    return base;
}
exports.baseName = baseName;
function extensionLowerCase(str) {
    const extension = String(str).substring(str.lastIndexOf('.') + 1);
    return extension.toLowerCase();
}
exports.extensionLowerCase = extensionLowerCase;
