"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Thunk = void 0;
var Thunk;
(function (Thunk) {
    /**
     * If the given input is a thunk, evaluate it and return the result,
     * otherwise just return the input.
     * @param input The value or thunk.
     */
    function valueOf(input) {
        return typeof input === 'function' ? input() : input;
    }
    Thunk.valueOf = valueOf;
    /**
     * Create a thunk for the given value.
     * @param input The value to wrap.
     */
    function create(input) {
        if (typeof input === 'function')
            return input;
        return () => input;
    }
    Thunk.create = create;
    /**
     * Return a function which will be evaluated only once, memoizing its result.
     * @param input The thunk to evaluate.
     */
    function lazy(input) {
        let hasRun = false;
        let result;
        return () => {
            if (hasRun)
                return result;
            result = input();
            hasRun = true;
            return result;
        };
    }
    Thunk.lazy = lazy;
})(Thunk || (exports.Thunk = Thunk = {}));
