"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.debugMode = void 0;
/**
 * Check if user has appended #debug to the url
 * Or if server was started with DEBUG=true as command line variable
 * Or set DEBUG=true in localStorage
 *
 * @returns {boolean} Returns true if user appended #debug to the url
 */
const debugMode = () => {
    var _a;
    return ((window && window.location.hash === '#debug') ||
        (process.env.DEBUG && process.env.DEBUG === 'true') ||
        (((_a = window === null || window === void 0 ? void 0 : window.localStorage) === null || _a === void 0 ? void 0 : _a.getItem) && window.localStorage.getItem('DEBUG') === 'true') ||
        false);
};
exports.debugMode = debugMode;
