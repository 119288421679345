"use strict";

const _excluded = ["align", "mode", "loading", "closeOnSelect", "actionOnClick", "fullWidth", "text", "title", "menuWidth", "menuItems", "onClose", "onOpen", "size", "icon", "disabled", "dropdownDisabled", "status", "selected", "dropdownProps", "usePortal"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }
function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }
var __createBinding = void 0 && (void 0).__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  var desc = Object.getOwnPropertyDescriptor(m, k);
  if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
    desc = {
      enumerable: true,
      get: function () {
        return m[k];
      }
    };
  }
  Object.defineProperty(o, k2, desc);
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});
var __setModuleDefault = void 0 && (void 0).__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, "default", {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o["default"] = v;
});
var __importStar = void 0 && (void 0).__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
  __setModuleDefault(result, mod);
  return result;
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SplitDropdownButton = void 0;
const react_1 = __importStar(require("react"));
const dropdownHelpers_1 = require("../../utils/dropdownHelpers");
const DropdownButtonStyles_1 = require("../DropdownButton/DropdownButtonStyles");
const index_1 = require("../index");
const components_1 = require("./components");
const index_2 = require("./index");
function isValidMenuItem(menuItem) {
  return !!(menuItem === null || menuItem === void 0 ? void 0 : menuItem.items);
}
const SplitDropdownButton = props => {
  const _props$align = props.align,
    align = _props$align === void 0 ? 'right' : _props$align,
    _props$mode = props.mode,
    mode = _props$mode === void 0 ? 'secondary' : _props$mode,
    _props$loading = props.loading,
    loading = _props$loading === void 0 ? false : _props$loading,
    closeOnSelect = props.closeOnSelect,
    actionOnClick = props.actionOnClick,
    fullWidth = props.fullWidth,
    text = props.text,
    title = props.title,
    menuWidth = props.menuWidth,
    menuItems = props.menuItems,
    onClose = props.onClose,
    onOpen = props.onOpen,
    size = props.size,
    icon = props.icon,
    disabled = props.disabled,
    dropdownDisabled = props.dropdownDisabled,
    status = props.status,
    selected = props.selected,
    dropdownProps = props.dropdownProps,
    _props$usePortal = props.usePortal,
    usePortal = _props$usePortal === void 0 ? false : _props$usePortal,
    rest = _objectWithoutProperties(props, _excluded);
  const outerElement = (0, react_1.useRef)(null);
  const _ref = (0, react_1.useState)(false),
    _ref2 = _slicedToArray(_ref, 2),
    isOpen = _ref2[0],
    setIsOpen = _ref2[1];
  const id = (0, dropdownHelpers_1.getUniqueId)();
  (0, react_1.useEffect)(() => {
    if (typeof window !== `undefined`) document.body.addEventListener('click', closeIfBlur);
    return () => {
      if (typeof window !== `undefined`) {
        document.body.removeEventListener('click', closeIfBlur);
      }
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const closeMenu = () => setIsOpen(false);
  const closeIfBlur = e => {
    var _a;
    return !((_a = outerElement === null || outerElement === void 0 ? void 0 : outerElement.current) === null || _a === void 0 ? void 0 : _a.contains(e.target)) && closeMenu();
  };
  const toggleOpen = () => {
    setIsOpen(prevState => {
      if (prevState && onClose) onClose();
      if (!prevState && onOpen) onOpen();
      return !prevState;
    });
  };
  const dropdownList = (0, react_1.useMemo)(() => react_1.default.createElement(index_1.DropdownList, _objectSpread({
    overrides: {
      width: 'max-content'
    },
    selected: selected,
    "aria-labelledby": `split-dropdown-button-${id}`,
    menuItems: menuItems.filter(isValidMenuItem),
    align: align,
    listWidth: menuWidth,
    onSelect: closeMenu
  }, dropdownProps)), [align, dropdownProps, id, menuItems, menuWidth, selected]);
  const portalDropdown = (0, react_1.useMemo)(() => react_1.default.createElement(index_1.Portal, {
    maintainPosition: true
  }, parentRect => {
    if (!parentRect || (parentRect === null || parentRect === void 0 ? void 0 : parentRect.top) === undefined) return null;
    return react_1.default.createElement(DropdownButtonStyles_1.PanelWrapper, {
      parentRect: parentRect
    }, dropdownList);
  }), [dropdownList]);
  return react_1.default.createElement(index_2.SplitDropdownButtonStyles.Wrapper, _objectSpread({
    fullWidth: fullWidth,
    ref: outerElement
  }, rest), react_1.default.createElement(components_1.DropdownButtonGroup, {
    fullWidth: fullWidth,
    mode: mode
  }, (text || icon) && react_1.default.createElement(index_2.SplitDropdownButtonStyles.Button, {
    mode: mode,
    onClick: actionOnClick,
    text: text,
    icon: icon || loading || undefined,
    size: size,
    status: status,
    width: fullWidth ? '100%' : 'auto',
    disabled: disabled,
    title: title
  }), react_1.default.createElement(index_2.SplitDropdownButtonStyles.Trigger, {
    id: `split-dropdown-button-${id}`,
    "aria-haspopup": "true",
    "data-testid": "split-dropdown-button",
    "aria-expanded": isOpen,
    disabled: dropdownDisabled,
    mode: mode,
    size: size,
    status: status,
    type: "button",
    onClick: toggleOpen,
    icon: "TextCaretDown"
  }), isOpen && (usePortal ? portalDropdown : dropdownList)));
};
exports.SplitDropdownButton = SplitDropdownButton;