"use strict";
/**
 * A collection helpers for working with promises
 * @namespace promise
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.MaxConcurrentPromiseRunner = exports.maybeThen = exports.defer = exports.allSettledSuccessfulFromObject = exports.promiseAllFromObject = exports.allSettledSuccessful = exports.createWatchdogPromise = exports.createRetryPromise = exports.withTimeout = exports.delay = exports.isTimeoutError = exports.TimeoutError = exports.isAbortError = exports.AbortError = void 0;
const lodash_1 = require("lodash");
const Predicate_1 = require("../Predicate");
const anySignal_1 = require("./anySignal");
class AbortError extends Error {
}
exports.AbortError = AbortError;
const isAbortError = (err) => err instanceof AbortError;
exports.isAbortError = isAbortError;
class TimeoutError extends Error {
}
exports.TimeoutError = TimeoutError;
const isTimeoutError = (err) => err instanceof TimeoutError;
exports.isTimeoutError = isTimeoutError;
/**
 * Create a promise that resolves after a specified amount of time.
 * Calling the cancel function on the promise will cause it to be rejected.
 * @memberof promise
 * @param {Number} ms requested delay in milliseconds
 * @param {AbortSignal} abortSignal abortSignal from abortController can be used to terminate the delay
 * @async
 * @return {Promise}
 */
function delay(ms, abortSignal) {
    let cancel = null;
    const cleanup = () => {
        if (abortSignal)
            abortSignal.removeEventListener('abort', cancel);
        cancel = null;
    };
    const promise = new Promise((res, rej) => {
        const timeout = setTimeout(res, ms);
        cancel = () => {
            clearTimeout(timeout);
            rej(new AbortError('Canceled'));
        };
    });
    // add event listener
    if (abortSignal) {
        if (abortSignal.aborted)
            cancel();
        else
            abortSignal.addEventListener('abort', cancel, { once: true });
    }
    // remove event listener when done
    promise.then(cleanup, cleanup);
    return promise;
}
exports.delay = delay;
/**
 * Run an async function or promise with a timeout. If the timeout is reached,
 * an error is thrown or the return value of the onTimeout callback is returned.
 * The async function is passed an abort signal that will be triggered if the timeout is reached.
 * In case an onTimeout function is passed, it will first run the onTimeout and only after that function finishes,
 * it will abort the initial promise. So the race continues until one returns (or throws) something.
 * Similary the onTimeout is passed the same abort signal that will be triggered when the initial promise has won the race.
 * It is the responsibility of those functions to react appropriately to the abortion.
 * @memberof promise
 * @param {Async Function | Promise} promiseOrAsync the function that does the asynchronous work or a promise
 * @param {Number} timeout requested delay in milliseconds
 * @param {AbortSignal} options.abortSignal optional abortSignal that will abort both the async function as well as the timeout delay
 * @param {Class} [options.ErrorClass=Error] optional type of error to throw (if no onTimeout is specified)
 * @param {String} options.msg optional message to throw
 * @param {Function} options.onTimeout optional (async) function to call on timeout, if specified the value of this function will be returned by `withTimeout` and no error will be thrown
 * @async
 * @return {Promise}
 */
async function withTimeout(promiseOrAsync, timeout, options) {
    const abortController = new AbortController();
    const combinedAbortSignal = (options === null || options === void 0 ? void 0 : options.abortSignal)
        ? (0, anySignal_1.anySignal)([options.abortSignal, abortController.signal])
        : abortController.signal;
    try {
        return await Promise.race([
            (0, lodash_1.isFunction)(promiseOrAsync) ? promiseOrAsync(combinedAbortSignal) : promiseOrAsync,
            (async () => {
                await delay(timeout, combinedAbortSignal);
                await delay(1, combinedAbortSignal);
                if (options === null || options === void 0 ? void 0 : options.onTimeout)
                    return await options.onTimeout(combinedAbortSignal);
                throw new ((options === null || options === void 0 ? void 0 : options.ErrorClass) || TimeoutError)(`Timeout (${timeout / 1000}s): ${(options === null || options === void 0 ? void 0 : options.msg) || 'Promise did not resolve in time'}`);
            })()
        ]);
    }
    finally {
        abortController.abort();
    }
}
exports.withTimeout = withTimeout;
/**
 * Creates a promise that will attempt to execute an async function multiple times until succeeded.
 * @memberof promise
 * @param {Number} attempts how many times to try before giving up
 * @param {Number} [delayMs=0] how long to wait between retries
 * @param {async function} promiseGenerator the async function to execute; every time it throws an error it will be restarted
 * @async
 * @return {Promise}
 */
async function createRetryPromise(attempts, delayMs = 0, promiseGenerator) {
    for (let i = 0; i < attempts; i += 1) {
        try {
            return await promiseGenerator();
        }
        catch (e) {
            await delay(delayMs);
            if (i === attempts - 1)
                throw e;
        }
    }
}
exports.createRetryPromise = createRetryPromise;
/**
 * Creates a watchdog: a promise that will reject after a specified amount of time of not being "pacified".
 * Used to check that a specific action is performed frequently enough.
 * E.g. if no response is received every minute, we assume the network connection is dead and throw an error.
 * The watchdog has the following methods:
 * pacify(): call this regularly to prevent the error
 * start(): call this to start the watchdog
 * stop(): call this to stop the watchdog; it won't throw an error even if you don't pacify it
 * catch(): use as a promise
 * @memberof promise
 * @param {Number} attempts how many times to try before giving up
 * @param {Number} [delayMs=0] how long to wait between retries
 * @param {async_function} promiseGenerator the async function to execute; every time it throws an error it will be restarted
 * @async
 * @return {Promise}
 */
function createWatchdogPromise(timeout, msg = 'Watchdog was not pacified in time') {
    let timeoutFn;
    // @ts-expect-error
    const promise = new Promise((res, rej) => {
        timeoutFn = () => {
            rej(new Error(`Watchdog (${timeout / 1000}s): ${msg}`));
        };
    });
    let timer = null;
    promise.pacify = function _pacify() {
        // let the watchdog know that all is well.
        if (timer) {
            clearTimeout(timer);
            timer = setTimeout(timeoutFn, timeout);
        }
        return this;
    };
    promise.start = function _start() {
        if (!timer) {
            timer = setTimeout(timeoutFn, timeout);
        }
        return this;
    };
    promise.stop = function _stop() {
        clearTimeout(timer);
        timer = null;
        return this;
    };
    promise.catch = function _catch(reason) {
        // @ts-expect-error
        const p = Promise.prototype.catch.call(this, reason);
        p.pacify = promise.pacify;
        p.start = promise.start;
        p.stop = promise.stop;
        return p;
    };
    return promise;
}
exports.createWatchdogPromise = createWatchdogPromise;
async function allSettledSuccessful(promises) {
    let firstError = null;
    return Promise.all(promises.map(p => p.catch(e => {
        firstError = e;
        return null;
    }))).then(r => {
        if (firstError)
            throw firstError;
        return r;
    });
}
exports.allSettledSuccessful = allSettledSuccessful;
async function promiseAllFromObject(promiseObject) {
    return Object.fromEntries(await Promise.all(Object.entries(promiseObject).map(async ([key, promise]) => {
        return [key, await promise];
    })));
}
exports.promiseAllFromObject = promiseAllFromObject;
async function allSettledSuccessfulFromObject(promiseObject) {
    return Object.fromEntries(await allSettledSuccessful(Object.entries(promiseObject).map(async ([key, promise]) => {
        return [key, await promise];
    })));
}
exports.allSettledSuccessfulFromObject = allSettledSuccessfulFromObject;
/**
 * Create a deferred, promise object which can be resolved or rejected directly.
 */
function defer() {
    let resolve;
    let reject;
    const promise = new Promise((_resolve, _reject) => {
        resolve = _resolve;
        reject = _reject;
    });
    const deferred = promise;
    deferred.resolve = resolve;
    deferred.reject = reject;
    return deferred;
}
exports.defer = defer;
/**
 * Similar to promise.then() but will call the callback directly if the value is not a promise.
 * Useful for dealing with values that are possibly not resolved yet.
 * @param maybePromise The value or a promise of a value.
 * @param onFulfilled The function to call with the result.
 * @param onRejected The function to call if there is an asynchronous error.
 * @returns The fulfilled value, or a promise to the fulfilled value.
 */
function maybeThen(maybePromise, onFulfilled, onRejected) {
    if (Predicate_1.Predicate.isPromiseLike(maybePromise)) {
        return maybePromise.then(onFulfilled, onRejected);
    }
    return onFulfilled(maybePromise);
}
exports.maybeThen = maybeThen;
/**
 *  Limits the amount of promises that can run concurrently
 */
class MaxConcurrentPromiseRunner {
    constructor(maxConcurrentPromises) {
        this.maxConcurrentPromises = maxConcurrentPromises;
        this.queue = [];
        this.running = 0;
    }
    add(fn) {
        return new Promise((resolve, reject) => {
            this.queue.push({ resolve, reject, fn });
            this.run();
        });
    }
    run() {
        if (this.running < this.maxConcurrentPromises && this.queue.length > 0) {
            this.running += 1;
            const job = this.queue.pop();
            job
                .fn()
                .then((value) => {
                this.running -= 1;
                job.resolve(value);
                this.run();
            })
                .catch((error) => {
                this.running -= 1;
                job.reject(error);
                this.run();
            });
        }
    }
}
exports.MaxConcurrentPromiseRunner = MaxConcurrentPromiseRunner;
