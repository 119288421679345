"use strict";
var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _LimitedStack_top, _LimitedStack_bottom, _LimitedStack_initialIndex;
Object.defineProperty(exports, "__esModule", { value: true });
exports.LimitedStack = void 0;
/** Class representing a Limited Stack, with Constant Element Indexes.
 * - Array like: has similar methods with same interface as Array.prototype
 * - Limited size: on adding elements, push out elementes at the other side when length > limit
 * - Constant Element Index: element indexes stay constant, even when pushing out elements on overflow
 *  => although length is limited, indexes will keep increasing
 */
class LimitedStack {
    /**
     * Create a new Limited Stack.
     * @param {number} limit - The size limit of the Limited Stack
     * @param {number} initialIndex - The index at which the first element will get added
     * @param {Iterable} initialElements - A list of initial elements to push to the Limited Stack
     */
    constructor(limit, initialIndex = 0, initialElements) {
        _LimitedStack_top.set(this, void 0);
        _LimitedStack_bottom.set(this, void 0);
        _LimitedStack_initialIndex.set(this, void 0);
        this.limit = limit;
        __classPrivateFieldSet(this, _LimitedStack_initialIndex, initialIndex, "f");
        __classPrivateFieldSet(this, _LimitedStack_top, undefined, "f");
        __classPrivateFieldSet(this, _LimitedStack_bottom, undefined, "f");
        if (initialElements) {
            for (const el of initialElements)
                this.push(el);
        }
    }
    /**
     * Appends new elements to the top of the Limited Stack, and returns the new length of the stack.
     * @param items New elements to add to the top of the Limited Stack.
     */
    push(...items) {
        var _a, _b, _c;
        if (__classPrivateFieldGet(this, _LimitedStack_top, "f") === undefined || __classPrivateFieldGet(this, _LimitedStack_bottom, "f") === undefined) {
            __classPrivateFieldSet(this, _LimitedStack_bottom, __classPrivateFieldGet(this, _LimitedStack_initialIndex, "f"), "f");
            __classPrivateFieldSet(this, _LimitedStack_top, __classPrivateFieldGet(this, _LimitedStack_initialIndex, "f") - 1, "f");
        }
        for (let item of items) {
            this[__classPrivateFieldSet(this, _LimitedStack_top, (_a = __classPrivateFieldGet(this, _LimitedStack_top, "f"), ++_a), "f")] = item;
            if (this.length > this.limit)
                delete this[__classPrivateFieldSet(this, _LimitedStack_bottom, (_c = __classPrivateFieldGet(this, _LimitedStack_bottom, "f"), _b = _c++, _c), "f"), _b];
        }
        return this.length;
    }
    /**
     * Removes the element at the top of the Limited Stack and returns it.
     * If the Limited Stack is empty, undefined is returned and the Limited Stack is not modified.
     */
    pop() {
        var _a, _b;
        if (__classPrivateFieldGet(this, _LimitedStack_top, "f") === undefined || __classPrivateFieldGet(this, _LimitedStack_bottom, "f") === undefined)
            return;
        const el = this[__classPrivateFieldGet(this, _LimitedStack_top, "f")];
        delete this[__classPrivateFieldSet(this, _LimitedStack_top, (_b = __classPrivateFieldGet(this, _LimitedStack_top, "f"), _a = _b--, _b), "f"), _a];
        if (__classPrivateFieldGet(this, _LimitedStack_top, "f") < __classPrivateFieldGet(this, _LimitedStack_bottom, "f")) {
            __classPrivateFieldSet(this, _LimitedStack_top, undefined, "f");
            __classPrivateFieldSet(this, _LimitedStack_bottom, undefined, "f");
        }
        return el;
    }
    /**
     * Inserts new elements at the bottom of of the Limited Stack, and returns the new length of the Limited Stack.
     * @param items Elements to insert at the bottom of the Limited Stack.
     */
    unshift(...items) {
        var _a, _b, _c;
        if (__classPrivateFieldGet(this, _LimitedStack_top, "f") === undefined || __classPrivateFieldGet(this, _LimitedStack_bottom, "f") === undefined) {
            __classPrivateFieldSet(this, _LimitedStack_bottom, __classPrivateFieldGet(this, _LimitedStack_initialIndex, "f") + 1, "f");
            __classPrivateFieldSet(this, _LimitedStack_top, __classPrivateFieldGet(this, _LimitedStack_initialIndex, "f"), "f");
        }
        for (let item of items) {
            this[__classPrivateFieldSet(this, _LimitedStack_bottom, (_a = __classPrivateFieldGet(this, _LimitedStack_bottom, "f"), --_a), "f")] = item;
            if (this.length > this.limit)
                delete this[__classPrivateFieldSet(this, _LimitedStack_top, (_c = __classPrivateFieldGet(this, _LimitedStack_top, "f"), _b = _c--, _c), "f"), _b];
        }
        return this.length;
    }
    /**
     * Removes the element at the bottom of the Limited Stack and returns it.
     * If the Limited Stack is empty, undefined is returned and the Limited Stack is not modified.
     */
    shift() {
        var _a, _b;
        if (__classPrivateFieldGet(this, _LimitedStack_top, "f") === undefined || __classPrivateFieldGet(this, _LimitedStack_bottom, "f") === undefined)
            return;
        const el = this[__classPrivateFieldGet(this, _LimitedStack_bottom, "f")];
        delete this[__classPrivateFieldSet(this, _LimitedStack_bottom, (_b = __classPrivateFieldGet(this, _LimitedStack_bottom, "f"), _a = _b++, _b), "f"), _a];
        if (__classPrivateFieldGet(this, _LimitedStack_bottom, "f") > __classPrivateFieldGet(this, _LimitedStack_top, "f")) {
            __classPrivateFieldSet(this, _LimitedStack_top, undefined, "f");
            __classPrivateFieldSet(this, _LimitedStack_bottom, undefined, "f");
        }
        return el;
    }
    /**
     *
     * @param {number} idx - Requested index
     * @returns Returns the element at the requested index of the Limited Stack
     */
    at(idx) {
        return this[idx];
    }
    /**
     * Returns the value of the first element in the Limited Stack where predicate is true, and undefined
     * otherwise.
     * @param predicate find calls predicate once for each element of the Limited Stack, in ascending
     * order, until it finds one where predicate returns true. If such an element is found, find
     * immediately returns that element value. Otherwise, find returns undefined.
     * @param thisArg If provided, it will be used as the this value for each invocation of
     * predicate. If it is not provided, undefined is used instead.
     */
    find(predicate, thisArg) {
        if (__classPrivateFieldGet(this, _LimitedStack_top, "f") === undefined || __classPrivateFieldGet(this, _LimitedStack_bottom, "f") === undefined)
            return;
        const fn = predicate.bind(thisArg);
        for (let i = __classPrivateFieldGet(this, _LimitedStack_bottom, "f"); i <= __classPrivateFieldGet(this, _LimitedStack_top, "f"); i++) {
            if (fn(this[i], i, this))
                return this[i];
        }
    }
    /**
     * Returns the index of the first element in the Limited Stack where predicate is true, and -1
     * otherwise.
     * @param predicate find calls predicate once for each element of the Limited Stack, in ascending
     * order, until it finds one where predicate returns true. If such an element is found,
     * findIndex immediately returns that element index. Otherwise, findIndex returns -1.
     * @param thisArg If provided, it will be used as the this value for each invocation of
     * predicate. If it is not provided, undefined is used instead.
     */
    findIndex(predicate, thisArg) {
        if (__classPrivateFieldGet(this, _LimitedStack_top, "f") === undefined || __classPrivateFieldGet(this, _LimitedStack_bottom, "f") === undefined)
            return;
        const fn = predicate.bind(thisArg);
        for (let i = __classPrivateFieldGet(this, _LimitedStack_bottom, "f"); i <= __classPrivateFieldGet(this, _LimitedStack_top, "f"); i++) {
            if (fn(this[i], i, this))
                return i;
        }
    }
    /**
     * The highest index of the Limited Stack
     */
    get top() {
        return __classPrivateFieldGet(this, _LimitedStack_top, "f");
    }
    /**
     * The lowest index of the Limited Stack
     */
    get bottom() {
        return __classPrivateFieldGet(this, _LimitedStack_bottom, "f");
    }
    /**
     * The initial index of the Limited Stack
     */
    get initialIndex() {
        return __classPrivateFieldGet(this, _LimitedStack_initialIndex, "f");
    }
    /**
     * The length of the Limited Stack
     */
    get length() {
        if (__classPrivateFieldGet(this, _LimitedStack_top, "f") === undefined || __classPrivateFieldGet(this, _LimitedStack_bottom, "f") === undefined)
            return 0;
        else
            return __classPrivateFieldGet(this, _LimitedStack_top, "f") - __classPrivateFieldGet(this, _LimitedStack_bottom, "f") + 1;
    }
    /**
     * Returns an iterable of key, value pairs for every entry in the Limited Stack
     */
    entries() {
        const stack = this;
        return {
            [Symbol.iterator]() {
                if (__classPrivateFieldGet(stack, _LimitedStack_top, "f") === undefined || __classPrivateFieldGet(stack, _LimitedStack_bottom, "f") === undefined)
                    return { next: () => ({ value: undefined, done: true }) };
                let idx = __classPrivateFieldGet(stack, _LimitedStack_bottom, "f");
                return {
                    next: () => {
                        if (__classPrivateFieldGet(stack, _LimitedStack_top, "f") !== undefined && idx <= __classPrivateFieldGet(stack, _LimitedStack_top, "f"))
                            return { value: [idx, stack[idx++]], done: false };
                        else
                            return { value: undefined, done: true };
                    }
                };
            }
        };
    }
    [(_LimitedStack_top = new WeakMap(), _LimitedStack_bottom = new WeakMap(), _LimitedStack_initialIndex = new WeakMap(), Symbol.iterator)]() {
        if (__classPrivateFieldGet(this, _LimitedStack_top, "f") === undefined || __classPrivateFieldGet(this, _LimitedStack_bottom, "f") === undefined)
            return { next: () => ({ value: undefined, done: true }) };
        let idx = __classPrivateFieldGet(this, _LimitedStack_bottom, "f");
        return {
            next: () => {
                if (__classPrivateFieldGet(this, _LimitedStack_top, "f") !== undefined && idx <= __classPrivateFieldGet(this, _LimitedStack_top, "f"))
                    return { value: this[idx++], done: false };
                else
                    return { value: undefined, done: true };
            }
        };
    }
}
exports.LimitedStack = LimitedStack;
