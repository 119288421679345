"use strict";

var __createBinding = void 0 && (void 0).__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  var desc = Object.getOwnPropertyDescriptor(m, k);
  if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
    desc = {
      enumerable: true,
      get: function () {
        return m[k];
      }
    };
  }
  Object.defineProperty(o, k2, desc);
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});
var __exportStar = void 0 && (void 0).__exportStar || function (m, exports) {
  for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Footer = exports.Header = exports.Body = exports.MainContent = exports.HeaderToRightColumn = exports.FixedRightColumnGrid = exports.RightColumn = exports.CenterColumn = exports.CenterTop = void 0;
var CenterTop_1 = require("./CenterTop");
Object.defineProperty(exports, "CenterTop", {
  enumerable: true,
  get: function () {
    return CenterTop_1.CenterTop;
  }
});
var CenterColumn_1 = require("./CenterColumn");
Object.defineProperty(exports, "CenterColumn", {
  enumerable: true,
  get: function () {
    return CenterColumn_1.CenterColumn;
  }
});
__exportStar(require("./LeftColumn"), exports);
__exportStar(require("./EditorLeftColumn"), exports);
var RightColumn_1 = require("./RightColumn");
Object.defineProperty(exports, "RightColumn", {
  enumerable: true,
  get: function () {
    return RightColumn_1.RightColumn;
  }
});
var FixedRightColumnGrid_1 = require("./FixedRightColumnGrid");
Object.defineProperty(exports, "FixedRightColumnGrid", {
  enumerable: true,
  get: function () {
    return FixedRightColumnGrid_1.FixedRightColumnGrid;
  }
});
var HeaderToRightColumn_1 = require("./HeaderToRightColumn");
Object.defineProperty(exports, "HeaderToRightColumn", {
  enumerable: true,
  get: function () {
    return HeaderToRightColumn_1.HeaderToRightColumn;
  }
});
var MainContent_1 = require("./MainContent");
Object.defineProperty(exports, "MainContent", {
  enumerable: true,
  get: function () {
    return MainContent_1.MainContent;
  }
});
var Body_1 = require("./Body");
Object.defineProperty(exports, "Body", {
  enumerable: true,
  get: function () {
    return Body_1.Body;
  }
});
var Header_1 = require("./Header");
Object.defineProperty(exports, "Header", {
  enumerable: true,
  get: function () {
    return Header_1.Header;
  }
});
var Footer_1 = require("./Footer");
Object.defineProperty(exports, "Footer", {
  enumerable: true,
  get: function () {
    return Footer_1.Footer;
  }
});
__exportStar(require("./constants"), exports);