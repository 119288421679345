"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.deleteOrBackspace = exports.isUpOrDown = exports.isSpaceOrEnter = void 0;
// 13 = enter, 32 = space
const isSpaceOrEnter = keyCode => keyCode === 13 || keyCode === 32;
exports.isSpaceOrEnter = isSpaceOrEnter;
// 38 = up, 40 = down
const isUpOrDown = keyCode => keyCode === 38 || keyCode === 40;
exports.isUpOrDown = isUpOrDown;
// 8 = backspace, 46 = delete
const deleteOrBackspace = keyCode => keyCode === 8 || keyCode === 46;
exports.deleteOrBackspace = deleteOrBackspace;