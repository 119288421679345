"use strict";

/* Standard Input Validators, to be used for input components
 * A validator is a function that takes a value and checks if the value is valid:
 *  - if not: the validator should return a ValidationError object with a descriptive error message for the user
 *  - if so: the validator should return the valid value, or return a parsed version of the value.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ValidationError = void 0;
class ValidationError extends Error {
  constructor(name) {
    super(name);
    this.name = name;
    this.error = name;
  }
}
exports.ValidationError = ValidationError;