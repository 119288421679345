"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useBeforeUnload = void 0;
const react_1 = require("react");
const lodash_1 = require("lodash");
const useFunction_1 = require("./useFunction");
/**
 * Hook to check before closing a page
 * @param {Function} checkerFn should return a string to show when a user attempts to browse away (a warning) or true to allow this

 */
const useBeforeUnload = checkerFn => {
  console.assert((0, lodash_1.isFunction)(checkerFn), 'useBeforeUnload expects a function argument');
  const callback = (0, useFunction_1.useFunction)(checkerFn, []);
  (0, react_1.useEffect)(() => {
    const handleBeforeunload = event => {
      try {
        const res = callback();
        if (res !== true) {
          // Cancel the event as stated by the standard.
          event.preventDefault();
          event.returnValue = res;
          return event.returnValue;
        }
      } catch (e) {
        console.error(e);
        // Cancel the event as stated by the standard.
        event.preventDefault();
        event.returnValue = 'Error on leave';
        return event.returnValue;
      }
    };
    window.addEventListener('beforeunload', handleBeforeunload);
    return () => window.removeEventListener('beforeunload', handleBeforeunload);
  }, [callback]);
};
exports.useBeforeUnload = useBeforeUnload;