"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.VisibilityControlsQA = void 0;
const react_1 = require("@testing-library/react");
const testutils_1 = require("../../testutils");
/**
 * A QA wrapper for the VisibilityControls component
 * @example from another parent QA component do this.get(VisibilityControlsQA); and then use the methods to interact with the visibility control icons
 */
class VisibilityControlsQA extends testutils_1.QA {
  get lockIcon() {
    return this.querySelector(`[data-testid='locked']`);
  }
  get unlockIcon() {
    return this.querySelector(`[data-testid='unlocked']`);
  }
  get hiddenIcon() {
    return this.querySelector(`[data-testid='hidden']`);
  }
  get visibleIcon() {
    return this.querySelector(`[data-testid='visible']`);
  }
  hasIcon(icon) {
    const el = this.querySelector(`[data-testid='${icon}']`);
    return el !== null;
  }
  /**
   * Get an array of the visible icons
   */
  get visibleIcons() {
    const visibleIcons = [];
    if (this.hasUnLockIcon) {
      visibleIcons.push('unlocked');
    }
    if (this.hasLockIcon) {
      visibleIcons.push('locked');
    }
    if (this.hasHiddenIcon) {
      visibleIcons.push('hidden');
    }
    if (this.hasVisibleIcon) {
      visibleIcons.push('visible');
    }
    return visibleIcons;
  }
  get hasLockIcon() {
    return this.lockIcon !== null;
  }
  get hasUnLockIcon() {
    return this.unlockIcon !== null;
  }
  get hasHiddenIcon() {
    return this.hiddenIcon !== null;
  }
  get hasVisibleIcon() {
    return this.visibleIcon !== null;
  }
  get isDisabled() {
    return Boolean(this.element.getAttribute('data-disabled'));
  }
  clickLock() {
    if (this.lockIcon) {
      react_1.fireEvent.mouseDown(this.lockIcon);
    }
  }
  clickUnlock() {
    if (this.unlockIcon) {
      react_1.fireEvent.mouseDown(this.unlockIcon);
    }
  }
  clickHidden() {
    if (this.hiddenIcon) {
      react_1.fireEvent.mouseDown(this.hiddenIcon);
    }
  }
  clickVisible() {
    if (this.visibleIcon) {
      react_1.fireEvent.mouseDown(this.visibleIcon);
    }
  }
}
exports.VisibilityControlsQA = VisibilityControlsQA;
VisibilityControlsQA.componentName = 'VisibilityControls';
VisibilityControlsQA.selector = "[data-testid='VisibilityControls']";