"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Inch = exports.Feet = exports.Yard = exports.Mile = exports.Kilometre = exports.Millimetre = exports.Centimetre = exports.Metre = void 0;
const LengthUnitDefinition_1 = require("./../UnitTypes/LengthUnitDefinition");
exports.Metre = new LengthUnitDefinition_1.LengthUnitDefinition({
    name: 'METRE',
    label: 'Metre',
    symbol: 'm',
    scaleToSI: 1
});
exports.Centimetre = new LengthUnitDefinition_1.LengthUnitDefinition({
    name: 'CENTIMETRE',
    label: 'Centimetre',
    symbol: 'cm',
    scaleToSI: 0.01
});
exports.Millimetre = new LengthUnitDefinition_1.LengthUnitDefinition({
    name: 'MILLIMETRE',
    label: 'Millimetre',
    symbol: 'mm',
    scaleToSI: 0.001
});
exports.Kilometre = new LengthUnitDefinition_1.LengthUnitDefinition({
    name: 'KILOMETER',
    label: 'Kilometre',
    symbol: 'km',
    scaleToSI: 1000
});
exports.Mile = new LengthUnitDefinition_1.LengthUnitDefinition({
    name: 'MILE',
    label: 'Mile',
    symbol: 'mi',
    scaleToSI: 1609.344
});
exports.Yard = new LengthUnitDefinition_1.LengthUnitDefinition({
    name: 'YARD',
    label: 'Yard',
    symbol: 'yd',
    scaleToSI: 0.9144
});
exports.Feet = new LengthUnitDefinition_1.LengthUnitDefinition({
    name: 'FEET',
    label: 'Feet',
    symbol: 'ft',
    scaleToSI: 0.3048
});
exports.Inch = new LengthUnitDefinition_1.LengthUnitDefinition({
    name: 'INCH',
    label: 'Inch',
    symbol: '″',
    scaleToSI: 0.0254
});
