"use strict";

var __createBinding = void 0 && (void 0).__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  var desc = Object.getOwnPropertyDescriptor(m, k);
  if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
    desc = {
      enumerable: true,
      get: function () {
        return m[k];
      }
    };
  }
  Object.defineProperty(o, k2, desc);
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});
var __setModuleDefault = void 0 && (void 0).__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, "default", {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o["default"] = v;
});
var __importStar = void 0 && (void 0).__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
  __setModuleDefault(result, mod);
  return result;
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WindowChangeListener = void 0;
const react_1 = __importStar(require("react"));
const utils_core_1 = require("@oqton/utils-core");
// NOTE: Not currently using this, as we need to scroll outside of the menu
// in order to scroll down and see the entire menu list. May reuse later if we have performance issues
//
// React-Select's helper function closeMenuOnScroll cause high cpu load even if a select component isn't focused / open
// Additionally, we need to menu to close, not only on window scroll, but also if the window is resized
// This component will trigger the blur and closing of a react select component under one of the following events:
// 1. A window scroll event happens anywhere outside of the menu list
//   (we still want to scroll inside of the menu list without it closing)
// 2. The window is resized
class WindowChangeListener extends react_1.PureComponent {
  constructor(props) {
    super(props);
    this.containerRef = react_1.default.createRef();
    this.blurOnResize = this.blurOnResize.bind(this);
    this.blurUnlessScrollInsideMenuList = this.blurUnlessScrollInsideMenuList.bind(this);
  }
  componentDidMount() {
    if (typeof window !== `undefined`) {
      window.addEventListener('scroll', this.blurUnlessScrollInsideMenuList, true);
      window.addEventListener('resize', this.blurOnResize, true);
    }
  }
  componentWillUnmount() {
    if (typeof window !== `undefined`) {
      window.removeEventListener('scroll', this.blurUnlessScrollInsideMenuList, true);
      window.removeEventListener('resize', this.blurOnResize, true);
    }
  }
  blurOnResize() {
    const selectRefCurrent = (0, utils_core_1.getIn)(this, 'props.selectRef.current');
    if (typeof selectRefCurrent.blur === 'function') selectRefCurrent.blur();
  }
  blurUnlessScrollInsideMenuList(e) {
    // check before calling
    const menuContainer = (0, utils_core_1.getIn)(this, 'props.selectRef.current.select.menuListRef');
    // If we are scrolling inside of the menu container, don't blur
    if (typeof menuContainer.contains === 'function' && menuContainer.contains(e.target)) return;
    const selectRefCurrent = (0, utils_core_1.getIn)(this, 'props.selectRef.current');
    if (typeof selectRefCurrent.blur === 'function') selectRefCurrent.blur();
  }
  render() {
    return react_1.default.createElement("div", {
      ref: this.containerRef
    }, this.props.children);
  }
}
exports.WindowChangeListener = WindowChangeListener;