"use strict";

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getRange = exports.getEndPrevious2 = exports.getEndPrevious = exports.getEnd = exports.getBegin = exports.getWeekNumber = exports.getDayRange = exports.getEndOfDay = exports.getBeginOfDay = exports.getBeginOfNextMonth = exports.getEndOfPreviousMonth = exports.getBeginOfPreviousMonth = exports.getMonthRange = exports.getBeginOfWeek = exports.getEndOfMonth = exports.getBeginOfMonth = exports.getBeginOfNextYear = exports.getEndOfPreviousYear = exports.getBeginOfPreviousYear = exports.getYearRange = exports.getEndOfYear = exports.getBeginOfYear = exports.getBeginOfNextDecade = exports.getEndOfPreviousDecade = exports.getBeginOfPreviousDecade = exports.getDecadeRange = exports.getEndOfDecade = exports.getBeginOfDecade = exports.getBeginOfDecadeYear = exports.getBeginOfNextCentury = exports.getEndOfPreviousCentury = exports.getBeginOfPreviousCentury = exports.getCenturyRange = exports.getEndOfCentury = exports.getBeginOfCentury = exports.getBeginOfCenturyYear = exports.getDayOfWeek = exports.getDay = exports.getMonthIndex = exports.getMonth = exports.getYear = exports.convert24to12 = exports.convert12to24 = exports.getHoursMinutesSeconds = exports.getHoursMinutes = exports.getMilliseconds = exports.getSeconds = exports.getMinutes = exports.getHours = exports.getDays = void 0;
exports.setSecondsToZero = exports.getISOLocalDate = exports.getISOLocalMonth = exports.isWeekend = exports.getDecadeLabel = exports.getCenturyLabel = exports.getDaysInMonth = exports.getValueRange = void 0;
const hourOptionalSecondsRegExp = /^(([0-1])?[0-9]|2[0-3]):[0-5][0-9](:([0-5][0-9]))?$/;
const hourRegExp = /^(([0-1])?[0-9]|2[0-3]):[0-5][0-9]:([0-5][0-9])$/;
const getDays = date => {
  if (!date) {
    return date;
  }
  if (date instanceof Date) {
    return date.getDay();
  }
  throw new Error(`Failed to get hours from date: ${date}.`);
};
exports.getDays = getDays;
const getHours = date => {
  if (!date) {
    return date;
  }
  if (date instanceof Date) {
    return date.getHours();
  }
  date = date.toString();
  if (hourOptionalSecondsRegExp.test(date)) {
    const _date$split = date.split(':'),
      _date$split2 = _slicedToArray(_date$split, 1),
      hourString = _date$split2[0];
    return parseInt(hourString, 10);
  }
  throw new Error(`Failed to get hours from date: ${date}.`);
};
exports.getHours = getHours;
const getMinutes = date => {
  if (!date) {
    return date;
  }
  if (date instanceof Date) {
    return date.getMinutes();
  }
  date = date.toString();
  if (hourOptionalSecondsRegExp.test(date)) {
    const _date$split3 = date.split(':'),
      _date$split4 = _slicedToArray(_date$split3, 2),
      minuteString = _date$split4[1];
    return parseInt(minuteString, 10);
  }
  throw new Error(`Failed to get minutes from date: ${date}.`);
};
exports.getMinutes = getMinutes;
const getSeconds = date => {
  if (!date) {
    return date;
  }
  if (date instanceof Date) {
    return date.getSeconds();
  }
  date = date.toString();
  if (hourRegExp.test(date)) {
    const _date$split5 = date.split(':'),
      _date$split6 = _slicedToArray(_date$split5, 3),
      secondString = _date$split6[2];
    return parseInt(secondString, 10);
  }
  if (hourOptionalSecondsRegExp.test(date)) {
    return 0;
  }
  throw new Error(`Failed to get seconds from date: ${date}.`);
};
exports.getSeconds = getSeconds;
const getMilliseconds = date => {
  if (!date) {
    return date;
  }
  if (date instanceof Date) {
    return date.getMilliseconds();
  }
  date = date.toString();
  if (hourRegExp.test(date)) {
    const _date$split7 = date.split(':'),
      _date$split8 = _slicedToArray(_date$split7, 4),
      millisecondString = _date$split8[3];
    return parseInt(millisecondString, 10);
  }
  if (hourOptionalSecondsRegExp.test(date)) {
    return 0;
  }
  throw new Error(`Failed to get milliseconds from date: ${date}.`);
};
exports.getMilliseconds = getMilliseconds;
const getHoursMinutes = date => {
  if (!date) {
    return date;
  }
  const hours = `0${(0, exports.getHours)(date)}`.slice(-2);
  const minutes = `0${(0, exports.getMinutes)(date)}`.slice(-2);
  return `${hours}:${minutes}`;
};
exports.getHoursMinutes = getHoursMinutes;
const getHoursMinutesSeconds = date => {
  if (!date) {
    return date;
  }
  const hours = `0${(0, exports.getHours)(date)}`.slice(-2);
  const minutes = `0${(0, exports.getMinutes)(date)}`.slice(-2);
  const seconds = `0${(0, exports.getSeconds)(date)}`.slice(-2);
  return `${hours}:${minutes}:${seconds}`;
};
exports.getHoursMinutesSeconds = getHoursMinutesSeconds;
const convert12to24 = (hour12, amPm) => {
  let hour24 = parseInt(hour12, 10);
  if (amPm === 'am' && hour24 === 12) {
    hour24 = 0;
  } else if (amPm === 'pm' && hour24 < 12) {
    hour24 += 12;
  }
  return hour24;
};
exports.convert12to24 = convert12to24;
const convert24to12 = hour24 => {
  const hour12 = hour24 % 12 || 12;
  return [hour12, hour24 < 12 ? 'am' : 'pm'];
};
exports.convert24to12 = convert24to12;
const _map = [...Array(7)].map((el, index) => index),
  _map2 = _slicedToArray(_map, 7),
  /* eslint-disable @typescript-eslint/no-unused-vars */
  SUNDAY = _map2[0],
  MONDAY = _map2[1],
  TUESDAY = _map2[2],
  WEDNESDAY = _map2[3],
  THURSDAY = _map2[4],
  FRIDAY = _map2[5],
  SATURDAY
  /* eslint-enable @typescript-eslint/no-unused-vars */ = _map2[6];
/* Simple getters - getting a property of a given point in time */
const getYear = date => {
  if (date instanceof Date) {
    return date.getFullYear();
  }
  if (typeof date === 'number') {
    return date;
  }
  const year = parseInt(date, 10);
  if (!Number.isNaN(year)) {
    return year;
  }
  throw new Error(`Failed to get year from date: ${date}.`);
};
exports.getYear = getYear;
const getMonth = date => date.getMonth() + 1;
exports.getMonth = getMonth;
const getMonthIndex = date => date.getMonth();
exports.getMonthIndex = getMonthIndex;
const getDay = date => date.getDate();
exports.getDay = getDay;
const getDayOfWeek = (date, calendarType = 'ISO 8601') => {
  const weekday = date.getDay();
  switch (calendarType) {
    case 'ISO 8601':
      // Shifts days of the week so that Monday is 0, Sunday is 6
      return (weekday + 6) % 7;
    case 'Arabic':
      return (weekday + 1) % 7;
    case 'Hebrew':
    case 'US':
      return weekday;
    default:
      throw new Error('Unsupported calendar type.');
  }
};
exports.getDayOfWeek = getDayOfWeek;
/* Complex getters - getting a property somehow related to a given point in time */
const getBeginOfCenturyYear = date => {
  const year = (0, exports.getYear)(date) - 1;
  return year + -year % 100 + 1;
};
exports.getBeginOfCenturyYear = getBeginOfCenturyYear;
const getBeginOfCentury = date => {
  const beginOfCenturyYear = (0, exports.getBeginOfCenturyYear)(date);
  return new Date(beginOfCenturyYear, 0, 1);
};
exports.getBeginOfCentury = getBeginOfCentury;
const getEndOfCentury = date => {
  const beginOfCenturyYear = (0, exports.getBeginOfCenturyYear)(date);
  return new Date(beginOfCenturyYear + 100, 0, 1, 0, 0, 0, -1);
};
exports.getEndOfCentury = getEndOfCentury;
const getCenturyRange = date => [(0, exports.getBeginOfCentury)(date), (0, exports.getEndOfCentury)(date)];
exports.getCenturyRange = getCenturyRange;
const getBeginOfPreviousCentury = date => {
  const previousCenturyYear = (0, exports.getYear)(date) - 100;
  return (0, exports.getBeginOfCentury)(previousCenturyYear);
};
exports.getBeginOfPreviousCentury = getBeginOfPreviousCentury;
const getEndOfPreviousCentury = date => {
  const previousCenturyYear = (0, exports.getYear)(date) - 100;
  return (0, exports.getEndOfCentury)(previousCenturyYear);
};
exports.getEndOfPreviousCentury = getEndOfPreviousCentury;
const getBeginOfNextCentury = date => {
  const nextCenturyYear = (0, exports.getYear)(date) + 100;
  return (0, exports.getBeginOfCentury)(nextCenturyYear);
};
exports.getBeginOfNextCentury = getBeginOfNextCentury;
const getBeginOfDecadeYear = date => {
  const year = (0, exports.getYear)(date) - 1;
  return year + -year % 10 + 1;
};
exports.getBeginOfDecadeYear = getBeginOfDecadeYear;
const getBeginOfDecade = date => {
  const beginOfDecadeYear = (0, exports.getBeginOfDecadeYear)(date);
  return new Date(beginOfDecadeYear, 0, 1);
};
exports.getBeginOfDecade = getBeginOfDecade;
const getEndOfDecade = date => {
  const beginOfDecadeYear = (0, exports.getBeginOfDecadeYear)(date);
  return new Date(beginOfDecadeYear + 10, 0, 1, 0, 0, 0, -1);
};
exports.getEndOfDecade = getEndOfDecade;
const getDecadeRange = date => [(0, exports.getBeginOfDecade)(date), (0, exports.getEndOfDecade)(date)];
exports.getDecadeRange = getDecadeRange;
const getBeginOfPreviousDecade = (date, offset = 10) => {
  const previousDecadeYear = (0, exports.getBeginOfDecadeYear)(date) - offset;
  return (0, exports.getBeginOfDecade)(previousDecadeYear);
};
exports.getBeginOfPreviousDecade = getBeginOfPreviousDecade;
const getEndOfPreviousDecade = (date, offset = 10) => {
  const previousDecadeYear = (0, exports.getBeginOfDecadeYear)(date) - offset;
  return (0, exports.getEndOfDecade)(previousDecadeYear);
};
exports.getEndOfPreviousDecade = getEndOfPreviousDecade;
const getBeginOfNextDecade = (date, offset = 10) => {
  const nextDecadeYear = (0, exports.getBeginOfDecadeYear)(date) + offset;
  return (0, exports.getBeginOfDecade)(nextDecadeYear);
};
exports.getBeginOfNextDecade = getBeginOfNextDecade;
/**
 * Returns the beginning of a given year.
 *
 * @param {Date} date Date.
 */
const getBeginOfYear = date => {
  const year = (0, exports.getYear)(date);
  return new Date(year, 0, 1);
};
exports.getBeginOfYear = getBeginOfYear;
/**
 * Returns the end of a given year.
 *
 * @param {Date} date Date.
 */
const getEndOfYear = date => {
  const year = (0, exports.getYear)(date);
  return new Date(year + 1, 0, 1, 0, 0, 0, -1);
};
exports.getEndOfYear = getEndOfYear;
/**
 * Returns an array with the beginning and the end of a given year.
 *
 * @param {Date} date Date.
 */
const getYearRange = date => [(0, exports.getBeginOfYear)(date), (0, exports.getEndOfYear)(date)];
exports.getYearRange = getYearRange;
const getBeginOfPreviousYear = (date, offset = 1) => {
  const previousYear = (0, exports.getYear)(date) - offset;
  return (0, exports.getBeginOfYear)(previousYear);
};
exports.getBeginOfPreviousYear = getBeginOfPreviousYear;
const getEndOfPreviousYear = (date, offset = 1) => {
  const previousYear = (0, exports.getYear)(date) - offset;
  return (0, exports.getEndOfYear)(previousYear);
};
exports.getEndOfPreviousYear = getEndOfPreviousYear;
const getBeginOfNextYear = (date, offset = 1) => {
  const nextYear = (0, exports.getYear)(date) + offset;
  return (0, exports.getBeginOfYear)(nextYear);
};
exports.getBeginOfNextYear = getBeginOfNextYear;
/**
 * Returns the beginning of a given month.
 *
 * @param {Date} date Date.
 */
const getBeginOfMonth = date => {
  const year = (0, exports.getYear)(date);
  const monthIndex = (0, exports.getMonthIndex)(date);
  return new Date(year, monthIndex, 1);
};
exports.getBeginOfMonth = getBeginOfMonth;
/**
 * Returns the end of a given month.
 *
 * @param {Date} date Date.
 */
const getEndOfMonth = date => {
  const year = (0, exports.getYear)(date);
  const monthIndex = (0, exports.getMonthIndex)(date);
  return new Date(year, monthIndex + 1, 1, 0, 0, 0, -1);
};
exports.getEndOfMonth = getEndOfMonth;
/**
 * Returns the beginning of a given week.
 *
 * @param {Date} date Date.
 * @param {String} calendarType Calendar type. Can be ISO 8601 or US.
 */
const getBeginOfWeek = (date, calendarType = 'ISO 8601') => {
  const year = (0, exports.getYear)(date);
  const monthIndex = (0, exports.getMonthIndex)(date);
  const day = date.getDate() - (0, exports.getDayOfWeek)(date, calendarType);
  return new Date(year, monthIndex, day);
};
exports.getBeginOfWeek = getBeginOfWeek;
/**
 * Returns an array with the beginning and the end of a given month.
 *
 * @param {Date} date Date.
 */
const getMonthRange = date => [(0, exports.getBeginOfMonth)(date), (0, exports.getEndOfMonth)(date)];
exports.getMonthRange = getMonthRange;
const getDifferentMonth = (date, offset) => {
  const year = (0, exports.getYear)(date);
  const previousMonthIndex = (0, exports.getMonthIndex)(date) + offset;
  return new Date(year, previousMonthIndex, 1);
};
const getBeginOfPreviousMonth = (date, offset = 1) => {
  const previousMonth = getDifferentMonth(date, -offset);
  return (0, exports.getBeginOfMonth)(previousMonth);
};
exports.getBeginOfPreviousMonth = getBeginOfPreviousMonth;
const getEndOfPreviousMonth = (date, offset = 1) => {
  const previousMonth = getDifferentMonth(date, -offset);
  return (0, exports.getEndOfMonth)(previousMonth);
};
exports.getEndOfPreviousMonth = getEndOfPreviousMonth;
const getBeginOfNextMonth = (date, offset = 1) => {
  const nextMonth = getDifferentMonth(date, offset);
  return (0, exports.getBeginOfMonth)(nextMonth);
};
exports.getBeginOfNextMonth = getBeginOfNextMonth;
const getBeginOfDay = date => {
  const year = (0, exports.getYear)(date);
  const monthIndex = (0, exports.getMonthIndex)(date);
  const day = (0, exports.getDay)(date);
  return new Date(year, monthIndex, day);
};
exports.getBeginOfDay = getBeginOfDay;
const getEndOfDay = date => {
  const year = (0, exports.getYear)(date);
  const monthIndex = (0, exports.getMonthIndex)(date);
  const day = (0, exports.getDay)(date);
  return new Date(year, monthIndex, day + 1, 0, 0, 0, -1);
};
exports.getEndOfDay = getEndOfDay;
/**
 * Returns an array with the beginning and the end of a given day.
 *
 * @param {Date} date Date.
 */
const getDayRange = date => [(0, exports.getBeginOfDay)(date), (0, exports.getEndOfDay)(date)];
exports.getDayRange = getDayRange;
/**
 * Gets week number according to ISO 8601 or US standard.
 * In ISO 8601, Arabic and Hebrew week 1 is the one with January 4.
 * In US calendar week 1 is the one with January 1.
 *
 * @param {Date} date Date.
 * @param {String} calendarType Calendar type. Can be ISO 8601 or US.
 */
const getWeekNumber = (date, calendarType = 'ISO 8601') => {
  const calendarTypeForWeekNumber = calendarType === 'US' ? 'US' : 'ISO 8601';
  const beginOfWeek = (0, exports.getBeginOfWeek)(date, calendarTypeForWeekNumber);
  let year = (0, exports.getYear)(date) + 1;
  let dayInWeekOne;
  let beginOfFirstWeek;
  // Look for the first week one that does not come after a given date
  do {
    dayInWeekOne = new Date(year, 0, calendarTypeForWeekNumber === 'ISO 8601' ? 4 : 1);
    beginOfFirstWeek = (0, exports.getBeginOfWeek)(dayInWeekOne, calendarTypeForWeekNumber);
    year -= 1;
  } while (date.getTime() - beginOfFirstWeek.getTime() < 0);
  return Math.round((beginOfWeek.getTime() - beginOfFirstWeek.getTime()) / (8.64e7 * 7)) + 1;
};
exports.getWeekNumber = getWeekNumber;
/**
 * Returns the beginning of a given range.
 *
 * @param {String} rangeType Range type (e.g. 'day')
 * @param {Date} date Date.
 */
const getBegin = (rangeType, date) => {
  switch (rangeType) {
    case 'century':
      return (0, exports.getBeginOfCentury)(date);
    case 'decade':
      return (0, exports.getBeginOfDecade)(date);
    case 'year':
      return (0, exports.getBeginOfYear)(date);
    case 'month':
      return (0, exports.getBeginOfMonth)(date);
    case 'day':
      return (0, exports.getBeginOfDay)(date);
    default:
      throw new Error(`Invalid rangeType: ${rangeType}`);
  }
};
exports.getBegin = getBegin;
/**
 * Returns the end of a given range.
 *
 * @param {String} rangeType Range type (e.g. 'day')
 * @param {Date} date Date.
 */
const getEnd = (rangeType, date) => {
  switch (rangeType) {
    case 'century':
      return (0, exports.getEndOfCentury)(date);
    case 'decade':
      return (0, exports.getEndOfDecade)(date);
    case 'year':
      return (0, exports.getEndOfYear)(date);
    case 'month':
      return (0, exports.getEndOfMonth)(date);
    case 'day':
      return (0, exports.getEndOfDay)(date);
    default:
      throw new Error(`Invalid rangeType: ${rangeType}`);
  }
};
exports.getEnd = getEnd;
const getEndPrevious = (rangeType, date) => {
  switch (rangeType) {
    case 'century':
      return (0, exports.getEndOfPreviousCentury)(date);
    case 'decade':
      return (0, exports.getEndOfPreviousDecade)(date);
    case 'year':
      return (0, exports.getEndOfPreviousYear)(date);
    case 'month':
      return (0, exports.getEndOfPreviousMonth)(date);
    default:
      throw new Error(`Invalid rangeType: ${rangeType}`);
  }
};
exports.getEndPrevious = getEndPrevious;
const getEndPrevious2 = (rangeType, date) => {
  switch (rangeType) {
    case 'decade':
      return (0, exports.getEndOfPreviousDecade)(date, 100);
    case 'year':
      return (0, exports.getEndOfPreviousYear)(date, 10);
    case 'month':
      return (0, exports.getEndOfPreviousMonth)(date, 12);
    default:
      throw new Error(`Invalid rangeType: ${rangeType}`);
  }
};
exports.getEndPrevious2 = getEndPrevious2;
/**
 * Returns an array with the beginning and the end of a given range.
 *
 * @param {String} rangeType Range type (e.g. 'day')
 * @param {Date} date Date.
 */
const getRange = (rangeType, date) => {
  switch (rangeType) {
    case 'century':
      return (0, exports.getCenturyRange)(date);
    case 'decade':
      return (0, exports.getDecadeRange)(date);
    case 'year':
      return (0, exports.getYearRange)(date);
    case 'month':
      return (0, exports.getMonthRange)(date);
    case 'day':
      return (0, exports.getDayRange)(date);
    default:
      throw new Error(`Invalid rangeType: ${rangeType}`);
  }
};
exports.getRange = getRange;
/**
 * Creates a range out of two values, ensuring they are in order and covering entire period ranges.
 *
 * @param {String} rangeType Range type (e.g. 'day')
 * @param {Date} date1 First date.
 * @param {Date} date2 Second date.
 */
const getValueRange = (rangeType, date1, date2) => {
  const rawNextValue = [date1, date2].sort((a, b) => a.getTime() - b.getTime());
  return [(0, exports.getBegin)(rangeType, rawNextValue[0]), (0, exports.getEnd)(rangeType, rawNextValue[1])];
};
exports.getValueRange = getValueRange;
/**
 * Returns a number of days in a month of a given date.
 *
 * @param {Date} date Date.
 */
const getDaysInMonth = date => {
  const year = (0, exports.getYear)(date);
  const monthIndex = (0, exports.getMonthIndex)(date);
  return new Date(year, monthIndex + 1, 0).getDate();
};
exports.getDaysInMonth = getDaysInMonth;
const toYearLabel = ([start, end]) => `${(0, exports.getYear)(start)} – ${(0, exports.getYear)(end)}`;
/**
 * Returns a string labelling a century of a given date.
 * For example, for 2017 it will return 2001-2100.
 *
 * @param {Date|String|Number} date Date or a year as a string or as a number.
 */
const getCenturyLabel = date => toYearLabel((0, exports.getCenturyRange)(date));
exports.getCenturyLabel = getCenturyLabel;
/**
 * Returns a string labelling a century of a given date.
 * For example, for 2017 it will return 2011-2020.
 *
 * @param {Date|String|Number} date Date or a year as a string or as a number.
 */
const getDecadeLabel = date => toYearLabel((0, exports.getDecadeRange)(date));
exports.getDecadeLabel = getDecadeLabel;
/**
 * Returns a boolean determining whether a given date is on Saturday or Sunday.
 *
 * @param {Date} date Date.
 * @param {String} calendarType
 */
const isWeekend = (date, calendarType = 'ISO 8601') => {
  const weekday = date.getDay();
  switch (calendarType) {
    case 'Arabic':
    case 'Hebrew':
      return weekday === FRIDAY || weekday === SATURDAY;
    case 'ISO 8601':
    case 'US':
      return weekday === SATURDAY || weekday === SUNDAY;
    default:
      throw new Error('Unsupported calendar type.');
  }
};
exports.isWeekend = isWeekend;
/**
 * Returns local month in ISO-like format (YYYY-MM).
 */
const getISOLocalMonth = value => {
  if (!value) {
    return value;
  }
  const date = new Date(value);
  if (Number.isNaN(date.getTime())) {
    throw new Error(`Invalid date: ${value}`);
  }
  const year = (0, exports.getYear)(date);
  const month = `0${(0, exports.getMonth)(date)}`.slice(-2);
  return `${year}-${month}`;
};
exports.getISOLocalMonth = getISOLocalMonth;
/**
 * Returns local date in ISO-like format (YYYY-MM-DD).
 */
const getISOLocalDate = value => {
  if (!value) {
    return value;
  }
  const date = new Date(value);
  if (Number.isNaN(date.getTime())) {
    throw new Error(`Invalid date: ${value}`);
  }
  const year = (0, exports.getYear)(date);
  const month = `0${(0, exports.getMonth)(date)}`.slice(-2);
  const day = `0${(0, exports.getDay)(date)}`.slice(-2);
  return `${year}-${month}-${day}`;
};
exports.getISOLocalDate = getISOLocalDate;
function setSecondsToZero(date) {
  return new Date(new Date(date).setSeconds(0, 0));
}
exports.setSecondsToZero = setSecondsToZero;