"use strict";

var __createBinding = void 0 && (void 0).__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  var desc = Object.getOwnPropertyDescriptor(m, k);
  if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
    desc = {
      enumerable: true,
      get: function () {
        return m[k];
      }
    };
  }
  Object.defineProperty(o, k2, desc);
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});
var __setModuleDefault = void 0 && (void 0).__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, "default", {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o["default"] = v;
});
var __importStar = void 0 && (void 0).__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
  __setModuleDefault(result, mod);
  return result;
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DraggableBackground = void 0;
const react_1 = __importStar(require("react"));
const index_1 = require("./index");
const DRAG_SPEED = 1.0;
class DraggableBackground extends react_1.Component {
  constructor(props) {
    super(props);
    this.onMouseMove = e => {
      var _a, _b, _c, _d, _e, _f, _g;
      const _this$props = this.props,
        horizontal = _this$props.horizontal,
        vertical = _this$props.vertical;
      if (this.state.dragging) {
        let x = (_a = this.containerRef.current) === null || _a === void 0 ? void 0 : _a.scrollLeft;
        if (horizontal && this.containerRef.current) {
          x = ((_c = (_b = this.containerRef.current) === null || _b === void 0 ? void 0 : _b.scrollLeft) !== null && _c !== void 0 ? _c : 0) - (-((_d = this.lastClientX) !== null && _d !== void 0 ? _d : 0) + (this.lastClientX = e.clientX * DRAG_SPEED));
          this.containerRef.current.scrollLeft = x;
        }
        let y = (_e = this.containerRef.current) === null || _e === void 0 ? void 0 : _e.scrollTop;
        if (vertical && this.containerRef.current) {
          y = ((_f = this.containerRef.current) === null || _f === void 0 ? void 0 : _f.scrollTop) - (-((_g = this.lastClientY) !== null && _g !== void 0 ? _g : 0) + (this.lastClientY = e.clientY * DRAG_SPEED));
          this.containerRef.current.scrollTop = y;
        }
        this.props.onDrag(x !== null && x !== void 0 ? x : 0, y !== null && y !== void 0 ? y : 0);
      }
    };
    this.onMouseUp = () => {
      if (this.state.dragging) {
        this.setState({
          dragging: false
        });
      }
    };
    this.onMouseDown = e => {
      const _this$props2 = this.props,
        horizontal = _this$props2.horizontal,
        vertical = _this$props2.vertical;
      if (!this.state.dragging) {
        this.setState({
          dragging: true
        });
        if (horizontal) {
          this.lastClientX = e.clientX * DRAG_SPEED;
        }
        if (vertical) {
          this.lastClientY = e.clientY * DRAG_SPEED;
        }
        e.preventDefault();
      }
    };
    // use click capture to access the event in bubble phase before it propagates to Link
    // check the scroll position to see if the user has been scrolling the element
    // if they have stopPropagation of the event and update the scroll state
    // if not let the event pass through to the Link component that will handle the click
    this.onClickCapture = e => {
      var _a, _b, _c, _d;
      const _this$props3 = this.props,
        horizontal = _this$props3.horizontal,
        vertical = _this$props3.vertical;
      if (((_a = this.containerRef.current) === null || _a === void 0 ? void 0 : _a.scrollLeft) !== this.scrollLeft || ((_b = this.containerRef.current) === null || _b === void 0 ? void 0 : _b.scrollTop) !== this.scrollTop) {
        e.preventDefault();
        e.stopPropagation();
        if (horizontal) {
          this.scrollLeft = (_c = this.containerRef.current) === null || _c === void 0 ? void 0 : _c.scrollLeft;
        }
        if (vertical) {
          this.scrollTop = (_d = this.containerRef.current) === null || _d === void 0 ? void 0 : _d.scrollTop;
        }
      }
    };
    this.state = {
      dragging: false
    };
    this.scrollLeft = 0;
    this.scrollTop = 0;
    this.containerRef = props.dragRef || react_1.default.createRef();
  }
  componentDidMount() {
    if (typeof window !== `undefined`) {
      window.addEventListener('mouseup', this.onMouseUp);
      window.addEventListener('mousemove', this.onMouseMove);
    }
  }
  componentDidUpdate() {
    var _a, _b;
    if (this.scrollLeft === 0 && this.scrollTop === 0) {
      this.scrollLeft = (_a = this.containerRef.current) === null || _a === void 0 ? void 0 : _a.scrollLeft;
      this.scrollTop = (_b = this.containerRef.current) === null || _b === void 0 ? void 0 : _b.scrollTop;
    }
  }
  componentWillUnmount() {
    if (typeof window !== `undefined`) {
      window.removeEventListener('mouseup', this.onMouseUp);
      window.removeEventListener('mousemove', this.onMouseMove);
    }
  }
  render() {
    const dragging = this.state.dragging;
    const children = this.props.children;
    return react_1.default.createElement(index_1.DraggableBackgroundStyles.DraggableWrapper, {
      isDragging: dragging,
      onClickCapture: this.onClickCapture,
      onMouseDown: this.onMouseDown,
      onMouseUp: this.onMouseUp,
      onMouseMove: this.onMouseMove,
      ref: this.containerRef
    }, children);
  }
}
exports.DraggableBackground = DraggableBackground;
DraggableBackground.defaultProps = {
  onDrag: () => {},
  horizontal: true,
  vertical: true
};