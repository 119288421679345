"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useLeftColumnWidth = void 0;
const utils_1 = require("../utils");
const useLeftColumnWidth = () => {
  const _ref = (0, utils_1.useGlobalNav)(),
    size = _ref.size;
  let width = '240';
  switch (size) {
    case 'Default':
      width = '240';
      break;
    case 'Minimal':
      width = '48';
      break;
    case 'Hidden':
      width = '0';
  }
  return {
    xs: '0px',
    l: `${width}px`
  };
};
exports.useLeftColumnWidth = useLeftColumnWidth;