"use strict";

var __createBinding = void 0 && (void 0).__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  var desc = Object.getOwnPropertyDescriptor(m, k);
  if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
    desc = {
      enumerable: true,
      get: function () {
        return m[k];
      }
    };
  }
  Object.defineProperty(o, k2, desc);
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});
var __setModuleDefault = void 0 && (void 0).__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, "default", {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o["default"] = v;
});
var __importStar = void 0 && (void 0).__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
  __setModuleDefault(result, mod);
  return result;
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RadioGroup = void 0;
const react_1 = __importStar(require("react"));
const Form_1 = require("../Form");
const foundations_1 = require("../../foundations");
const Radio_1 = require("../Radio");
const RadioGroup = ({
  allDisabled,
  name,
  onChange,
  options = [],
  selected,
  optionsContainer: Container,
  label
}) => {
  const mappedOptions = options.map((option, index) => {
    const disabled = option.disabled,
      error = option.error,
      label = option.label,
      value = option.value,
      subLabel = option.subLabel,
      tooltip = option.tooltip;
    return react_1.default.createElement(Radio_1.Radio, {
      key: `Radio${index}`,
      error: error,
      tabIndex: index,
      label: label,
      subLabel: subLabel,
      name: name,
      value: value,
      disabled: disabled || allDisabled,
      selected: value === selected,
      onChange: onChange,
      tooltip: tooltip
    });
  });
  return react_1.default.createElement(react_1.Fragment, null, label && react_1.default.createElement(foundations_1.Box, {
    mb: "m"
  }, react_1.default.createElement(Form_1.FormLabel, null, label)), Container ? react_1.default.createElement(Container, null, mappedOptions) : react_1.default.createElement(react_1.Fragment, null, mappedOptions));
};
exports.RadioGroup = RadioGroup;