"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.InspectorPanelItem = exports.InspectorPanel = void 0;
var InspectorPanel_1 = require("./InspectorPanel");
Object.defineProperty(exports, "InspectorPanel", {
  enumerable: true,
  get: function () {
    return InspectorPanel_1.InspectorPanel;
  }
});
var InspectorPanelItem_1 = require("./components/InspectorPanelItem");
Object.defineProperty(exports, "InspectorPanelItem", {
  enumerable: true,
  get: function () {
    return InspectorPanelItem_1.InspectorPanelItem;
  }
});