"use strict";

var __createBinding = void 0 && (void 0).__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  var desc = Object.getOwnPropertyDescriptor(m, k);
  if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
    desc = {
      enumerable: true,
      get: function () {
        return m[k];
      }
    };
  }
  Object.defineProperty(o, k2, desc);
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});
var __setModuleDefault = void 0 && (void 0).__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, "default", {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o["default"] = v;
});
var __importStar = void 0 && (void 0).__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
  __setModuleDefault(result, mod);
  return result;
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Timeline = void 0;
const react_1 = __importStar(require("react"));
const lodash_1 = require("lodash");
const utils_core_1 = require("@oqton/utils-core");
const Link_1 = require("../Link");
const dates_1 = require("../../utils/dates");
const hooks_1 = require("../../hooks");
const foundations_1 = require("../../foundations");
const TimelineStyles_1 = require("./TimelineStyles");
const getScale_1 = require("./utils/getScale");
const components_1 = require("./components");
const Timeline = ({
  precision = 'DAY',
  startDate: initialStartDate,
  endDate: initialEndDate,
  nodes = [],
  name,
  onDrag = () => {},
  dragRef = react_1.default.createRef(),
  showNow = true,
  showLabels = true,
  allowSelecting = false,
  renderRowImage,
  handleClick = () => {}
}) => {
  const _ref = (0, hooks_1.useTranslation)(),
    t = _ref.t;
  const startDate = initialStartDate ? (0, dates_1.setSecondsToZero)(initialStartDate) : undefined;
  const endDate = initialEndDate ? (0, dates_1.setSecondsToZero)(initialEndDate) : undefined;
  const boundaryDates = (0, react_1.useMemo)(() => {
    var _a, _b;
    // check if start and end date are specified
    if (!startDate || !endDate) {
      // calculate lower and upper time bounds
      const sortedTimes = (0, lodash_1.sortBy)((0, lodash_1.flatMap)(nodes, n => (0, lodash_1.flatMap)(n === null || n === void 0 ? void 0 : n.values, val => [val === null || val === void 0 ? void 0 : val.startTime, val === null || val === void 0 ? void 0 : val.endTime])), time => time ? new Date(time) : 0);
      return {
        startDate: (_a = startDate || sortedTimes[0]) !== null && _a !== void 0 ? _a : new Date(),
        endDate: (_b = endDate || sortedTimes[sortedTimes.length - 1]) !== null && _b !== void 0 ? _b : new Date()
      };
    }
    return {
      startDate,
      endDate
    };
  }, [endDate, nodes, startDate]);
  const scale = (0, react_1.useMemo)(() => {
    const _startDate = typeof boundaryDates.startDate === 'string' ? new Date(boundaryDates.startDate) : boundaryDates.startDate;
    const _endDate = typeof boundaryDates.endDate === 'string' ? new Date(boundaryDates.endDate) : boundaryDates.endDate;
    return (0, getScale_1.getScale)(precision, _startDate, _endDate, TimelineStyles_1.SINGLE_UNIT_SPACE_IN_PX);
  }, [boundaryDates.endDate, boundaryDates.startDate, precision]);
  const calculateNowPositionInPixels = (0, react_1.useCallback)(() => {
    var _a;
    const now = (0, dates_1.setSecondsToZero)(showNow ? Date.now() : boundaryDates.startDate);
    const px = Math.round((0, utils_core_1.getTimeDurationInMs)((_a = scale.timeUnits[0]) !== null && _a !== void 0 ? _a : 0, now) * scale.msInPixels);
    return {
      x: px,
      label: (0, foundations_1.formatDateTime)(now)
    };
  }, [boundaryDates.startDate, scale.msInPixels, scale.timeUnits, showNow]);
  (0, react_1.useLayoutEffect)(() => {
    const _calculateNowPosition = calculateNowPositionInPixels(),
      x = _calculateNowPosition.x;
    if (dragRef.current) {
      // scroll in focus of now on start
      dragRef.current.scrollLeft = x - 200;
    }
    // eslint-disable-next-line
  }, [dragRef, precision]);
  // Process the values of each node to split the arrays in to levels
  const splitValues = nodes.map(node => {
    var _a;
    return (0, utils_core_1.splitOverlapping)((_a = (0, lodash_1.compact)(node.values)) !== null && _a !== void 0 ? _a : []);
  });
  const totalRows = splitValues.reduce((sum, v) => sum + (v.length || 1), 0);
  const height = totalRows * TimelineStyles_1.ROW_HEIGHT + nodes.length * TimelineStyles_1.VERTICAL_SPACING - TimelineStyles_1.VERTICAL_SPACING;
  return react_1.default.createElement(foundations_1.Flex, null, showLabels && react_1.default.createElement(foundations_1.Box, {
    flex: "0 0 156px"
  }, react_1.default.createElement(components_1.TimelineTitle, {
    name: name
  }), nodes.map((node, index) => {
    var _a, _b, _c, _d;
    const nodeRowLabel = react_1.default.createElement(components_1.RowLabel, {
      name: node.name,
      key: `#${(0, lodash_1.padStart)(index.toString(), 3, '0')}`,
      image: renderRowImage && node.image ? renderRowImage(node.image, node.name) : undefined,
      height: (((_b = (_a = splitValues[index]) === null || _a === void 0 ? void 0 : _a.length) !== null && _b !== void 0 ? _b : 0) === 0 ? 1 : (_d = (_c = splitValues[index]) === null || _c === void 0 ? void 0 : _c.length) !== null && _d !== void 0 ? _d : 0) * TimelineStyles_1.ROW_HEIGHT
    });
    return react_1.default.createElement(foundations_1.Box, {
      frameStyle: "bgStackPrimary",
      flex: "0 0 156px",
      mb: "xs",
      key: index
    }, node.href ? react_1.default.createElement(Link_1.Link, {
      href: node.href
    }, nodeRowLabel) : nodeRowLabel);
  })), react_1.default.createElement(components_1.DraggableBackground, {
    onDrag: onDrag,
    dragRef: dragRef,
    vertical: false
  }, react_1.default.createElement(TimelineStyles_1.TimeIndicatorStripesBackground, {
    height: height,
    timeUnits: scale.timeUnits.length
  }), scale.timeUnits.map((timeUnit, index) => react_1.default.createElement(TimelineStyles_1.TimeIndicator, {
    key: timeUnit.toString(),
    index: index
  }, (0, foundations_1.formatDateTime)(timeUnit))), react_1.default.createElement(foundations_1.Box, {
    mt: TimelineStyles_1.POS_TOP
  }, nodes.map((node, index) => react_1.default.createElement(foundations_1.Box, {
    mb: "xs",
    key: index
  }, react_1.default.createElement(components_1.TimelineBar, {
    rows: splitValues[index],
    scale: scale,
    onSelected: handleClick,
    allowSelecting: allowSelecting
  })))), showNow && react_1.default.createElement(components_1.NowIndicator, {
    getLeftPosition: calculateNowPositionInPixels,
    height: height,
    label: t('designSystem:components.timeline.now')
  })));
};
exports.Timeline = Timeline;