"use strict";

var __createBinding = void 0 && (void 0).__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  var desc = Object.getOwnPropertyDescriptor(m, k);
  if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
    desc = {
      enumerable: true,
      get: function () {
        return m[k];
      }
    };
  }
  Object.defineProperty(o, k2, desc);
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});
var __setModuleDefault = void 0 && (void 0).__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, "default", {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o["default"] = v;
});
var __importStar = void 0 && (void 0).__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
  __setModuleDefault(result, mod);
  return result;
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ComparisonValue = exports.ComparisonIcon = exports.ComparisonContainer = exports.MetricValue = exports.Title = exports.Card = void 0;
const styled_components_1 = __importStar(require("styled-components"));
const Text_1 = require("../Text");
const Heading_1 = require("../Heading");
const foundations_1 = require("../../foundations");
exports.Card = (0, styled_components_1.default)(foundations_1.Flex)`
  height: 124px;
  min-width: 320px;

  padding: ${p => p.theme.spacing.l}px;

  flex-direction: column;
  justify-content: center;

  border-radius: 6px;
  background-color: ${p => p.theme.colors.white};
  box-shadow: ${p => p.theme.boxShadows.secondary};
`;
exports.Title = (0, styled_components_1.default)(Heading_1.Heading)`
  font-weight: 500;
  color: ${p => {
  var _a;
  return (_a = p.theme.colors) === null || _a === void 0 ? void 0 : _a.tertiary;
}};
`;
const getValueStyles = ({
  size,
  theme
}) => {
  switch (size) {
    case 'S':
      return (0, styled_components_1.css)`
        height: unset;
        font-weight: ${theme.typography.fontWeights.bold} !important;
        font-size: ${theme.typography.fontSizes.xxl}px !important;
        line-height: ${theme.typography.fontSizes.xxl + 4}px !important;
      `;
    case 'M':
      return (0, styled_components_1.css)`
        height: unset;
        font-weight: ${theme.typography.fontWeights.bold} !important;
        font-size: ${theme.typography.fontSizes.xxxl}px !important;
        line-height: ${theme.typography.fontSizes.xxxl + 8}px !important;
      `;
    case 'L':
      return (0, styled_components_1.css)`
        height: unset;
        font-weight: ${theme.typography.fontWeights.bold} !important;
        font-size: ${theme.typography.fontSizes.xxxxl}px !important;
        line-height: ${theme.typography.fontSizes.xxxxl + 16}px !important;
      `;
  }
};
exports.MetricValue = (0, styled_components_1.default)(Text_1.Text)`
  * {
    ${p => getValueStyles({
  size: p.size,
  theme: p.theme
})};
  }
`;
exports.ComparisonContainer = (0, styled_components_1.default)(foundations_1.Flex)(({
  size,
  theme
}) => {
  switch (size) {
    case 'S':
      return {
        'font-size': theme.typography.fontSizes['m+'],
        'line-height': theme.spacing.m
      };
    case 'M':
      return {
        'font-size': theme.typography.fontSizes['l+'],
        'line-height': theme.spacing.l
      };
    case 'L':
      return {
        'font-size': theme.typography.fontSizes.xl,
        'line-height': theme.spacing.xl
      };
  }
});
exports.ComparisonIcon = (0, styled_components_1.default)(foundations_1.Box)`
  font-weight: ${p => p.theme.typography.fontWeights.bold};
  color: ${p => p.status ? p.theme.colors.status.success : p.theme.colors.status.error};
`;
exports.ComparisonValue = (0, styled_components_1.default)(foundations_1.Box)`
  font-weight: ${p => p.theme.typography.fontWeights.medium};
`;