"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CurrencyUnitDefinition = void 0;
const UnitDefinition_1 = require("../UnitDefinition");
const Formatters_1 = require("../Formatters");
/**
 * Currency Unit Definition.
 */
class CurrencyUnitDefinition extends UnitDefinition_1.UnitDefinition {
    constructor(config) {
        super(config.precision === undefined ? { ...config, precision: 2 } : config);
        this.scale = config.scale == null ? 1 : config.scale;
    }
    formatAsTokens(input, precision = this.precision, withSymbol = true) {
        const { symbol, scale } = this;
        if (input == null || isNaN(+input)) {
            return [];
        }
        return (0, Formatters_1.formatCurrencyAsTokens)(input * scale, precision, withSymbol ? symbol : undefined);
    }
}
exports.CurrencyUnitDefinition = CurrencyUnitDefinition;
