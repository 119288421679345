"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VolumeUnitDefinition = void 0;
const ConvertableUnitDefinition_1 = require("./ConvertableUnitDefinition");
class VolumeUnitDefinition extends ConvertableUnitDefinition_1.ConvertableUnitDefinition {
    convertTo(targetUnit) {
        return super.convertTo(targetUnit);
    }
}
exports.VolumeUnitDefinition = VolumeUnitDefinition;
