"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.breakpoints = void 0;
// need to be pixel values in the styled-system v5.1.4
exports.breakpoints = {
  xs: '0px',
  s: '599px',
  m: '768px',
  l: '1200px',
  xl: '1780px'
};