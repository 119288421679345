"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WidgetThumbnailQA = void 0;
const testutils_1 = require("../../testutils");
class WidgetThumbnailQA extends testutils_1.QA {
  get name() {
    return this.element.getAttribute('data-testtype');
  }
}
exports.WidgetThumbnailQA = WidgetThumbnailQA;
WidgetThumbnailQA.componentName = 'Thumbnail';
WidgetThumbnailQA.selector = "[data-testid='icon']";