"use strict";

var __createBinding = void 0 && (void 0).__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  var desc = Object.getOwnPropertyDescriptor(m, k);
  if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
    desc = {
      enumerable: true,
      get: function () {
        return m[k];
      }
    };
  }
  Object.defineProperty(o, k2, desc);
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});
var __setModuleDefault = void 0 && (void 0).__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, "default", {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o["default"] = v;
});
var __importStar = void 0 && (void 0).__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
  __setModuleDefault(result, mod);
  return result;
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DateInputField = void 0;
const react_1 = __importStar(require("react"));
const utils_core_1 = require("@oqton/utils-core");
const DateTimeComponents_1 = require("../../../Shared/DateTimeComponents");
const DateInputField = ({
  disabled,
  itemRef,
  name,
  value,
  onChange,
  placeholder,
  required,
  fullDate,
  onFocus
}) => {
  const inputRef = (0, react_1.useRef)(null);
  const hiddenRef = (0, react_1.useRef)(null);
  const isYearInput = name === 'year';
  (0, react_1.useLayoutEffect)(() => {
    const current = inputRef.current;
    if (current) current.style.width = isYearInput ? '36px' : '22px';
    if (itemRef) itemRef(current, name);
  }, [itemRef, name, inputRef, isYearInput]);
  const handleOnChange = event => {
    const value = event.currentTarget.value;
    onChange && onChange({
      value: parseInt(value.slice(0, isYearInput ? 4 : 2)),
      event
    });
  };
  const getMinMax = () => {
    switch (name) {
      case 'year':
        return null;
      case 'month':
        return {
          min: 1,
          max: 12
        };
      case 'day':
        return {
          min: 1,
          max: fullDate ? utils_core_1.date.getDaysInMonth({
            date: fullDate
          }) : 31
        };
      default:
        throw new Error(`unknown unit: ${name}`);
    }
  };
  const handleKeyDown = event => {
    //User has full control if not using up- or down-arrow
    if (event.key !== 'ArrowUp' && event.key !== 'ArrowDown') return;
    const eventKey = event.key === 'ArrowDown' ? 'down' : 'up';
    const value = parseInt(event.currentTarget.value) + (eventKey === 'down' ? -1 : 1);
    const values = getMinMax();
    if (!values) return;
    const exceedMax = value > values.max;
    const exceedMin = value < values.min;
    if (exceedMax || exceedMin) {
      event.preventDefault();
      event.currentTarget.value = (exceedMax ? values.min : values.max).toString();
    }
  };
  return react_1.default.createElement(react_1.default.Fragment, null, react_1.default.createElement(DateTimeComponents_1.Hidden, {
    ref: hiddenRef
  }, value !== null ? value : placeholder), react_1.default.createElement(DateTimeComponents_1.StyledInput, {
    key: "input",
    type: "number",
    value: value !== null ? value : '',
    name: name,
    disabled: disabled,
    required: required,
    placeholder: placeholder,
    isYearInput: isYearInput,
    ref: inputRef,
    onChange: handleOnChange,
    onKeyDown: handleKeyDown,
    autoComplete: "off",
    "data-testid": `${name}-input`,
    onFocus: onFocus
  }));
};
exports.DateInputField = DateInputField;