"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PercentUnitDefinition = void 0;
const FormatToken_1 = require("../FormatToken");
const NumberUnitDefinition_1 = require("./NumberUnitDefinition");
/**
 * Percentage Unit Definition.
 */
class PercentUnitDefinition extends NumberUnitDefinition_1.NumberUnitDefinition {
    formatAsString(input, precision = this.precision, withSymbol = true) {
        return (0, FormatToken_1.joinFormatTokens)(this.formatAsTokens(input, precision, withSymbol), '') || '-';
    }
}
exports.PercentUnitDefinition = PercentUnitDefinition;
