"use strict";

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
var __createBinding = void 0 && (void 0).__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  var desc = Object.getOwnPropertyDescriptor(m, k);
  if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
    desc = {
      enumerable: true,
      get: function () {
        return m[k];
      }
    };
  }
  Object.defineProperty(o, k2, desc);
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});
var __setModuleDefault = void 0 && (void 0).__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, "default", {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o["default"] = v;
});
var __importStar = void 0 && (void 0).__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
  __setModuleDefault(result, mod);
  return result;
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MessageInput = void 0;
const react_1 = __importStar(require("react"));
const Button_1 = require("../../../Button");
const foundations_1 = require("../../../../foundations");
const components_1 = require("./components");
const styles_1 = require("./styles");
const MessageInput = ({
  handleSubmit,
  handleFileChange,
  disabled,
  isLoading,
  input
}) => {
  const fileInputRef = (0, react_1.useRef)(null);
  const _ref = (0, react_1.useState)(''),
    _ref2 = _slicedToArray(_ref, 2),
    value = _ref2[0],
    setValue = _ref2[1];
  const onSubmit = (0, react_1.useCallback)(() => {
    if (disabled || isLoading || (input === null || input === void 0 ? void 0 : input.files.length) === 0 && !value) return;
    handleSubmit(!!value ? value : undefined);
    setValue('');
  }, [disabled, handleSubmit, input === null || input === void 0 ? void 0 : input.files.length, isLoading, value]);
  const isDisabled = (0, react_1.useMemo)(() => {
    if (disabled || isLoading) return true;
    if ((input === null || input === void 0 ? void 0 : input.files.length) === 0 && !value) return true;
    return input === null || input === void 0 ? void 0 : input.files.some(file => file.progress !== 100);
  }, [disabled, input === null || input === void 0 ? void 0 : input.files, isLoading, value]);
  return react_1.default.createElement(styles_1.Wrapper, {
    mt: "m",
    mr: "xs"
  }, react_1.default.createElement(styles_1.StyledFileInput, {
    multiple: true,
    type: "file",
    ref: fileInputRef,
    onChange: handleFileChange
  }), react_1.default.createElement(Button_1.Button, {
    mb: "xxs",
    mx: "xxs",
    icon: "Upload",
    mode: "tertiary",
    onClick: e => {
      var _a;
      return (_a = fileInputRef.current) === null || _a === void 0 ? void 0 : _a.click();
    }
  }), react_1.default.createElement(styles_1.InputContainer, {
    px: "m",
    pb: "xs",
    pt: "s"
  }, react_1.default.createElement(components_1.FileContainer, {
    files: input === null || input === void 0 ? void 0 : input.files
  }), react_1.default.createElement(foundations_1.Flex, null, react_1.default.createElement(styles_1.StyledInput, {
    textArea: true,
    hideLabel: true,
    value: value,
    placeholder: "Type a message...",
    style: {
      margin: 0,
      height: 'min-content'
    },
    onChange: val => setValue(val.currentTarget.value),
    onKeyPress: e => {
      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault();
        onSubmit();
      }
    }
  }), react_1.default.createElement(styles_1.StyledButton, {
    icon: "SubmitForReview",
    mode: "tertiary",
    onClick: onSubmit,
    disabled: isDisabled
  }))));
};
exports.MessageInput = MessageInput;