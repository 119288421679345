"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Cancellation = void 0;
var Cancellation;
(function (Cancellation) {
    /**
     * Create either a DOMException in a browser host environment,
     * or an Error with the name set to `AbortError`.
     * @param message The message for the abort error.
     */
    function createAbortError(message = 'Cancelled') {
        if (typeof globalThis.DOMException === 'function') {
            return new globalThis.DOMException(message, 'AbortError');
        }
        const error = new Error(message);
        error.name = 'AbortError';
        return error;
    }
    Cancellation.createAbortError = createAbortError;
    /**
     * Create a promise which can be cancelled using the given abort signal.
     * @param executor The function which will receive `resolve`, `reject` and `onCancel` callbacks.
     * @param signal The abort signal.
     */
    function createPromise(executor, signal) {
        let cleanup;
        return new Promise((resolve, reject) => {
            if (signal.aborted) {
                reject(createAbortError());
                return;
            }
            const cancellationCallbacks = [];
            const handler = () => {
                for (const callback of cancellationCallbacks) {
                    callback();
                }
                reject(createAbortError());
            };
            signal.addEventListener('abort', handler);
            cleanup = () => {
                signal.removeEventListener('abort', handler);
            };
            const onCancel = (callback) => {
                cancellationCallbacks.push(callback);
            };
            executor(resolve, reject, onCancel);
        }).then(value => {
            cleanup === null || cleanup === void 0 ? void 0 : cleanup();
            return value;
        }, error => {
            cleanup === null || cleanup === void 0 ? void 0 : cleanup();
            throw error;
        });
    }
    Cancellation.createPromise = createPromise;
    /**
     * Chain an AbortController to a parent AbortSignal, so that when the parent is
     * cancelled the child will be cancelled too.
     * @param signal The parent abort signal.
     * @param controller The child abort controller.
     */
    function chain(signal, controller) {
        const handler = () => controller.abort();
        controller.signal.addEventListener('abort', () => {
            signal.removeEventListener('abort', handler);
        });
        signal.addEventListener('abort', handler);
    }
    Cancellation.chain = chain;
})(Cancellation || (exports.Cancellation = Cancellation = {}));
