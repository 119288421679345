"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Strings = void 0;
const assert_1 = require("./assert");
const Path_1 = require("./Path");
var Strings;
(function (Strings) {
    /**
     * Cast a value to a string.
     * @param input The value to cast.
     */
    function cast(input) {
        return String(input);
    }
    Strings.cast = cast;
    /**
     * Creates a function which can interpolate specic parameters within the given pattern.
     * @param pattern
     */
    function template(pattern) {
        const tokens = tokenizePattern(pattern);
        return ((params) => {
            const parts = [];
            for (const token of tokens) {
                if (typeof token === 'string') {
                    parts.push(token);
                }
                else if (token.type === 'Path') {
                    parts.push(cast(Path_1.Path.get(params, token.value)));
                }
                else {
                    assert_1.Assert.never(token.type, `Unsupported token type: ${token.type}`);
                }
            }
            return parts.join('');
        });
    }
    Strings.template = template;
    /**
     * Interpolate the given string with the supplied parameters.
     * @param input The string pattern.
     * @param params The parameters to interpolate.
     */
    function interpolate(input, params) {
        const parts = [];
        for (const token of tokenizePattern(input)) {
            if (typeof token === 'string') {
                parts.push(token);
            }
            else if (token.type === 'Path') {
                parts.push(cast(Path_1.Path.get(params, token.value)));
            }
            else {
                assert_1.Assert.never(token.type, `Unsupported token type: ${token.type}`);
            }
        }
        return parts.join('');
    }
    Strings.interpolate = interpolate;
    /**
     * Tokenize a string pattern.
     * @param input The pattern to tokenize.
     */
    function tokenizePattern(input) {
        const tokens = [];
        let start = 0;
        let inExpression = false;
        for (let i = 0; i < input.length; i++) {
            const char = input.charCodeAt(i);
            if (inExpression) {
                if (char === 35 /* # */) {
                    // we were in an invalid expression
                    inExpression = false;
                    i--;
                    continue;
                }
                else if (char === 125 /* } */) {
                    tokens.push({
                        type: 'Path',
                        value: input.slice(start + 2, i)
                    });
                    start = i + 1;
                    inExpression = false;
                }
            }
            else if (char === 35 /* # */) {
                if (i < input.length - 1 && input.charCodeAt(i + 1) === 123 /* { */) {
                    if (i > start) {
                        if (tokens.length > 0 && typeof tokens[tokens.length - 1] === 'string') {
                            tokens[tokens.length - 1] += input.slice(start, i);
                        }
                        else {
                            tokens.push(input.slice(start, i));
                        }
                    }
                    start = i;
                    i++;
                    inExpression = true;
                }
            }
        }
        if (start <= input.length - 1) {
            if (tokens.length > 0 && typeof tokens[tokens.length - 1] === 'string') {
                tokens[tokens.length - 1] += input.slice(start);
            }
            else {
                tokens.push(input.slice(start));
            }
        }
        return tokens;
    }
    Strings.tokenizePattern = tokenizePattern;
    /**
     * Removes indent from a string such that the same amount of leading
     * whitespace is removed from each line.
     * @param input The string to dedent.
     * @returns The dedented string.
     */
    function dedent(input) {
        const lines = input.split(/\r?\n/);
        let minIndent;
        for (let i = 0; i < lines.length; i++) {
            const line = lines[i];
            for (let j = 0; j < line.length; j++) {
                if (line.charCodeAt(j) !== 32) {
                    if (j === 0) {
                        // minimum indent is 0, so no dedent is possible.
                        return input;
                    }
                    if (minIndent === undefined || minIndent > j) {
                        minIndent = j;
                    }
                    break;
                }
            }
        }
        if (minIndent === undefined) {
            return input;
        }
        const output = [];
        for (let i = 0; i < lines.length; i++) {
            const line = lines[i];
            if (line.length === 0) {
                output.push('');
            }
            else {
                output.push(line.slice(minIndent));
            }
        }
        return output.join('\n');
    }
    Strings.dedent = dedent;
    const INDENT_LEVELS = [
        '',
        ' ',
        '  ',
        '   ',
        '    ',
        '     ',
        '      ',
        '       ',
        '        ',
        '         ',
        '          ',
        '           ',
        '            ',
        '             ',
        '              ',
        '               ',
        '                ',
        '                 '
    ];
    /**
     * Indent a string by a certain level.
     * @param input The string to indent.
     * @param level The indentation level.
     */
    function indent(input, level = 2) {
        if (input.length === 0) {
            return '';
        }
        const lines = input.split(/\r?\n/);
        let prefix;
        if (level < INDENT_LEVELS.length) {
            prefix = INDENT_LEVELS[level];
        }
        else {
            prefix = Array.from({ length: level }, () => ' ').join('');
        }
        const output = [];
        for (let i = 0; i < lines.length; i++) {
            const line = lines[i];
            if (line.length === 0) {
                output.push('');
            }
            else {
                output.push(`${prefix}${line}`);
            }
        }
        return output.join('\n');
    }
    Strings.indent = indent;
    /**
     * Capitalize a string.
     * @param input The string to capitalize.
     */
    function capitalize(input) {
        if (input.length === 0)
            return input;
        if (input.length === 1)
            return input.toUpperCase();
        return `${input.slice(0, 1).toUpperCase()}${input.slice(1)}`;
    }
    Strings.capitalize = capitalize;
    function capitalizeWords(input) {
        return input
            .split(/\s+/g)
            .map(word => capitalize(word))
            .join(' ');
    }
    Strings.capitalizeWords = capitalizeWords;
    function handlePreserveConsecutiveUppercase(decamelized, separator) {
        // Lowercase all single uppercase characters. As we
        // want to preserve uppercase sequences, we cannot
        // simply lowercase the separated string at the end.
        // `data_For_USACounties` → `data_for_USACounties`
        decamelized = decamelized.replace(/([\p{Uppercase_Letter}\d](?![\p{Uppercase_Letter}\d]))/gu, $0 => {
            return $0.toLowerCase();
        });
        // Remaining uppercase sequences will be separated from lowercase sequences.
        // `data_For_USACounties` → `data_for_USA_counties`
        return decamelized.replace(/(\p{Uppercase_Letter}+)(\p{Uppercase_Letter}\p{Lowercase_Letter}+)/gu, (_, $1, $2) => {
            return $1 + separator + $2.toLowerCase();
        });
    }
    function decamelize(text, { separator = '_', preserveConsecutiveUppercase = false } = {}) {
        if (!(typeof text === 'string' && typeof separator === 'string')) {
            throw new TypeError('The `text` and `separator` arguments should be of type `string`');
        }
        // Checking the second character is done later on. Therefore process shorter strings here.
        if (text.length < 2) {
            return preserveConsecutiveUppercase ? text : text.toLowerCase();
        }
        const replacement = `$1${separator}$2`;
        // Split lowercase sequences followed by uppercase character.
        // `dataForUSACounties` → `data_For_USACounties`
        // `myURLstring → `my_URLstring`
        const decamelized = text.replace(/([\p{Lowercase_Letter}\d])(\p{Uppercase_Letter})/gu, replacement);
        if (preserveConsecutiveUppercase) {
            return handlePreserveConsecutiveUppercase(decamelized, separator);
        }
        // Split multiple uppercase characters followed by one or more lowercase characters.
        // `my_URLstring` → `my_url_string`
        return decamelized
            .replace(/(\p{Uppercase_Letter}+)(\p{Uppercase_Letter}\p{Lowercase_Letter}+)/gu, replacement)
            .toLowerCase();
    }
    Strings.decamelize = decamelize;
    function humanize(input) {
        const output = decamelize(input)
            .toLowerCase()
            .replace(/[_-]+/g, ' ')
            .replace(/\s{2,}/g, ' ')
            .trim();
        return output.charAt(0).toUpperCase() + output.slice(1);
    }
    Strings.humanize = humanize;
    /**
     * Determine whether the given string solely consists of alpha numeric characters.
     * @param input The input string.
     */
    function isAlphaNumeric(input) {
        for (let i = 0; i < input.length; i++) {
            const char = input.charCodeAt(i);
            if (char >= 48 && char <= 57)
                continue; // 0 - 9
            if (char >= 65 && char <= 90)
                continue; // A - Z
            if (char >= 97 && char <= 122)
                continue; // a - z
            return false;
        }
        return true;
    }
    Strings.isAlphaNumeric = isAlphaNumeric;
    /**
     * Escape the text for use in a regular expression
     */
    function escapeRegExp(text) {
        return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    }
    Strings.escapeRegExp = escapeRegExp;
    /**
     * Unescape the escaped test in a regular expression
     */
    function unescapeRegExp(text) {
        return text.split('\\').join('');
    }
    Strings.unescapeRegExp = unescapeRegExp;
})(Strings || (exports.Strings = Strings = {}));
