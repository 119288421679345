"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.cleanVariableName = void 0;
/*
 * Enforce remove invalid characters from the name of all variables
 * @warning Data can save these values so changing this can e.g. break an existing filter
 */
function cleanVariableName(name) {
    return name.replace(/[^A-Z0-9]+/gi, '');
}
exports.cleanVariableName = cleanVariableName;
