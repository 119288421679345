"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateString = exports.isEmptyString = exports.validPhoneNumber = void 0;
function validPhoneNumber(phone) {
    // source; https://stackoverflow.com/a/33561517
    const regx = new RegExp(/^[+]?[\s./0-9]*[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/g);
    return regx.test(phone);
}
exports.validPhoneNumber = validPhoneNumber;
const isEmptyString = (stringParam) => {
    return !stringParam || stringParam.replace(/\s/g, '') === '';
};
exports.isEmptyString = isEmptyString;
const BLOCKED_PROPERTY_NAMES = ['__proto__', 'constructor'];
function generateStringGetDefaultValue(path) {
    throw new Error(`Cannot read property at path: ${path.join('.')}`);
}
const generateString = (template, context, getDefaultValue = generateStringGetDefaultValue) => {
    if ((0, exports.isEmptyString)(template)) {
        return template;
    }
    context = context || {};
    const evaluate = (expression) => {
        let current = context;
        const path = expression.split('.');
        for (const part of path) {
            if (part in current &&
                Object.prototype.hasOwnProperty.call(current, part) &&
                !BLOCKED_PROPERTY_NAMES.includes(part)) {
                current = current[part];
            }
            else {
                return getDefaultValue(path);
            }
        }
        if (typeof current !== 'number' && typeof current !== 'string') {
            throw new Error(`Invalid expression target: ${expression}`);
        }
        return String(current);
    };
    return template.replace(/\${\s*([^}]*?)\s*}/g, (_match, expression) => evaluate(expression));
};
exports.generateString = generateString;
