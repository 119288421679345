import React from 'react';

import ReactDOM from 'react-dom/client';

import { Box, Button, Flex, Heading, InputText, Select, Theme } from '@oqton/design-system';
import { Modal } from './Modal';
import { BackgroundGradient } from './BackgroundGradient';

interface IClusters {
  [key: string]: {
    title: string;
    api: string;
    clusterDomain: string;
  };
}

export const CLUSTERS: IClusters = {
  cloud: {
    api: 'https://api.cloud.oqton.ai',
    title: 'Europe (cloud)',
    clusterDomain: 'cloud.oqton.ai'
  },
  eu2: {
    api: 'https://api.eu2.oqton.ai',
    title: 'Europe 2 (eu2)',
    clusterDomain: 'eu2.oqton.ai'
  },
  eu3: {
    api: 'https://api.eu3.oqton.ai',
    title: 'Europe 3 (eu3)',
    clusterDomain: 'eu3.oqton.ai'
  },
  eu4: {
    api: 'https://api.eu4.oqton.ai',
    title: 'Europe 4 (eu4)',
    clusterDomain: 'eu4.oqton.ai'
  },
  cn3: {
    api: 'https://api.cn3.oqton.com.cn',
    title: 'China',
    clusterDomain: 'cn3.oqton.com.cn'
  }
};

const App = () => {
  const [cluster, setCluster] = React.useState(Object.keys(CLUSTERS)[0] || '');
  const [tenant, setTenant] = React.useState('');

  return (
    <Theme>
      <BackgroundGradient>
        <Modal>
          <Box style={{ textAlign: 'center' }}>
            <Heading>Welcome</Heading>
          </Box>
          <Box mt="xl">
            <Select
              label="Cluster"
              onChange={(v: any) => setCluster(v.value)}
              options={Object.keys(CLUSTERS).map(c => ({
                label: CLUSTERS[c]!.title,
                value: c
              }))}
              value={cluster}
            />
          </Box>
          <Flex mt="xl">
            <InputText
              label="Tenant"
              value={tenant}
              onChange={e => setTenant((e.target as HTMLTextAreaElement).value)}
            />
            <InputText label="" value={`.${CLUSTERS[cluster]!.clusterDomain}`} disabled />
          </Flex>
          <Box mt="m">
            <Button
              disabled={!tenant}
              size="l"
              width="100%"
              // TODO: setting window.location.href should be avoided, since it circumvents the safe goto and goback functionality as defined in the router
              // eslint-disable-next-line @oqton/no-external-router
              onClick={() => (window.location.href = `https://${tenant}.${CLUSTERS[cluster]!.clusterDomain}`)}
            >
              Login
            </Button>
          </Box>
        </Modal>
      </BackgroundGradient>
    </Theme>
  );
};
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLDivElement);

root.render(<App />);
