"use strict";

var __createBinding = void 0 && (void 0).__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  var desc = Object.getOwnPropertyDescriptor(m, k);
  if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
    desc = {
      enumerable: true,
      get: function () {
        return m[k];
      }
    };
  }
  Object.defineProperty(o, k2, desc);
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});
var __setModuleDefault = void 0 && (void 0).__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, "default", {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o["default"] = v;
});
var __importStar = void 0 && (void 0).__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
  __setModuleDefault(result, mod);
  return result;
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SecondaryPagination = void 0;
const react_1 = __importStar(require("react"));
const lodash_1 = require("lodash");
const Button_1 = require("../../../../Button");
const foundations_1 = require("../../../../../foundations");
const PageButton = ({
  selectedPage,
  onSelectPage,
  pages,
  page,
  index
}) => {
  const button = (0, react_1.useMemo)(() => react_1.default.createElement(Button_1.Button, {
    children: page + 1,
    onClick: () => onSelectPage(page),
    mode: selectedPage === page ? 'primary' : 'secondary'
  }), [onSelectPage, page, selectedPage]);
  if ((0, lodash_1.isNumber)(pages[index - 1]) && pages[index - 1] + 1 !== page) {
    return react_1.default.createElement(react_1.default.Fragment, null, react_1.default.createElement(foundations_1.Flex, {
      alignItems: "flex-end",
      pb: "xxxs",
      children: "..."
    }), button);
  }
  return button;
};
const SecondaryPagination = ({
  onSelectPage,
  selectedPage,
  maxRadius = 1,
  nPages
}) => {
  const hasPreviousPage = (0, react_1.useMemo)(() => selectedPage !== 0, [selectedPage]);
  const hasNextPage = (0, react_1.useMemo)(() => selectedPage !== nPages, [nPages, selectedPage]);
  const pages = (0, react_1.useMemo)(() => {
    const _range = (0, lodash_1.range)(selectedPage - maxRadius, Math.min(selectedPage + maxRadius + 1, nPages)).filter(n => n >= 0);
    if (_range[0] !== 0) _range.unshift(0);
    if ((0, lodash_1.last)(_range) !== nPages) _range.push(nPages);
    return _range;
  }, [maxRadius, nPages, selectedPage]);
  return react_1.default.createElement(foundations_1.Flex, {
    justifyContent: "center",
    gap: "xs"
  }, react_1.default.createElement(Button_1.Button, {
    mode: "secondary",
    icon: "CaretLeft",
    disabled: !hasPreviousPage,
    onClick: () => hasPreviousPage && onSelectPage(selectedPage - 1)
  }), pages.map((page, index) => react_1.default.createElement(PageButton, {
    key: `pageButton-${index}`,
    selectedPage: selectedPage,
    onSelectPage: onSelectPage,
    pages: pages,
    page: page,
    index: index
  })), react_1.default.createElement(Button_1.Button, {
    mode: "secondary",
    icon: "CaretRight",
    disabled: !hasNextPage,
    onClick: () => hasNextPage && onSelectPage(selectedPage + 1)
  }));
};
exports.SecondaryPagination = SecondaryPagination;