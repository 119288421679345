"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Timestamp = void 0;
var Timestamp;
(function (Timestamp) {
    /**
     * Determine whether the given input is a valid timestamp.
     * @param input The input to check.
     */
    function isTimestamp(input) {
        if (typeof input !== 'string')
            return false;
        return /^((\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z)))$/.test(input);
    }
    Timestamp.isTimestamp = isTimestamp;
    /**
     * Create a timestamp at this instant.
     */
    function now() {
        return new Date().toISOString();
    }
    Timestamp.now = now;
})(Timestamp || (exports.Timestamp = Timestamp = {}));
