"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CheckboxQA = void 0;
const testutils_1 = require("../../testutils");
const VisibilityControls_1 = require("../VisibilityControls");
class CheckboxInnerQA extends testutils_1.QA {
  get isActive() {
    return this.element.dataset.isActive === 'true';
  }
}
CheckboxInnerQA.componentName = 'Checkbox';
CheckboxInnerQA.selector = "[data-testid='CheckboxInner']";
class CheckboxQA extends testutils_1.QA {
  get isActive() {
    return this.get(CheckboxInnerQA).isActive;
  }
  keydown() {
    this.fireEvent('keyDown', {
      keyCode: 32
    });
  }
  keyup() {
    this.fireEvent('keyUp', {
      keyCode: 32
    });
  }
  click() {
    this.fireEvent('click');
  }
  get visibilityControls() {
    return this.get(VisibilityControls_1.VisibilityControlsQA);
  }
}
exports.CheckboxQA = CheckboxQA;
CheckboxQA.componentName = 'Checkbox';
CheckboxQA.selector = "[data-testid='Checkbox']";