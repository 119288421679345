"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.eos = void 0;
exports.eos = {
  brandLogo: {
    url: '',
    svg: 'https://assets.website-files.com/5dc1062341558a0136941a10/5dcf2001629c84466e4ac39f_eos-link3d-white-logo-additive-manufacturing.png'
  },
  colors: {
    backgroundTertiary: '#fff',
    inversePrimary: '#fff',
    buttonColors: {
      secondary: {
        text: 'red',
        border: 'red'
      }
    }
  },
  gradients: {
    menu: `background: linear-gradient(0deg, #333 0%, #000 100%);`
  },
  typography: {},
  spacing: {}
};