"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Predicate = void 0;
var Predicate;
(function (Predicate) {
    /**
     * Is the given value defined?
     * @param candidate The candidate value.
     */
    function isDefined(candidate) {
        return candidate !== undefined;
    }
    Predicate.isDefined = isDefined;
    /**
     * Is the given value a boolean?
     * @param candidate The candidate value.
     */
    function isBoolean(candidate) {
        return typeof candidate === 'boolean';
    }
    Predicate.isBoolean = isBoolean;
    /**
     * Is the given value a number?
     * @param candidate The candidate value.
     */
    function isNumber(candidate) {
        return typeof candidate === 'number';
    }
    Predicate.isNumber = isNumber;
    /**
     * Is the given value an integer?
     * @param candidate The candidate value.
     */
    function isInteger(candidate) {
        return typeof candidate === 'number' && Number.isInteger(candidate);
    }
    Predicate.isInteger = isInteger;
    /**
     * Is the given value a string?
     * @param candidate The candidate value.
     */
    function isString(candidate) {
        return typeof candidate === 'string';
    }
    Predicate.isString = isString;
    /**
     * Is the given value an object?
     * @param candidate The candidate value.
     */
    function isObject(candidate) {
        return typeof candidate === 'object' && candidate !== null;
    }
    Predicate.isObject = isObject;
    /**
     * Is the given value a plain JS object?
     * @param candidate The candidate value.
     */
    function isPlainObject(candidate) {
        if (typeof candidate === 'object' && candidate !== null) {
            const proto = Object.getPrototypeOf(candidate);
            return proto === Object.prototype || proto === null;
        }
        return false;
    }
    Predicate.isPlainObject = isPlainObject;
    /**
     * Is the given value an array?
     * @param candidate The candidate value.
     */
    function isArray(candidate) {
        return Array.isArray(candidate);
    }
    Predicate.isArray = isArray;
    /**
     * Is the given value a readonly array?
     * @param candidate The candidate value.
     */
    function isReadonlyArray(candidate) {
        return Array.isArray(candidate);
    }
    Predicate.isReadonlyArray = isReadonlyArray;
    /**
     * Is the given value a function?
     * @param candidate The candidate value.
     */
    function isFunction(candidate) {
        return typeof candidate === 'function';
    }
    Predicate.isFunction = isFunction;
    /**
     * Is the given input a promise-like value?
     * @param input The candidate value
     */
    function isPromiseLike(input) {
        return (typeof input === 'object' &&
            input !== null &&
            typeof input.then === 'function' &&
            typeof input.catch === 'function');
    }
    Predicate.isPromiseLike = isPromiseLike;
    /**
     * Is the given input a generator?
     * @param input The candidate value.
     */
    function isGenerator(input) {
        return input != null && typeof input.next === 'function' && typeof input.throw === 'function';
    }
    Predicate.isGenerator = isGenerator;
    /**
     * Is the given input a generator?
     * @param input The candidate value.
     */
    function isGeneratorIterator(input) {
        if (input == null)
            return false;
        var constructor = input.constructor;
        if (!constructor)
            return false;
        if (constructor.name === 'GeneratorFunction' || constructor.displayName === 'GeneratorFunction')
            return true;
        return isGenerator(constructor.prototype);
    }
    Predicate.isGeneratorIterator = isGeneratorIterator;
    /**
     * Is the given input an object with the given property key?
     * @param input The object to check.
     * @param key They key to check for.
     */
    function hasProperty(input, key) {
        if (!isObject(input))
            return false;
        return input[key] !== undefined;
    }
    Predicate.hasProperty = hasProperty;
    /**
     * Are the two given values equal?
     * @param a The first value.
     * @param b The second value.
     */
    function isEqual(a, b) {
        if (a === b)
            return true;
        if (typeof a === 'object' && typeof b === 'object') {
            if (a === null || b === null)
                return false;
            if (Array.isArray(a)) {
                if (!Array.isArray(b))
                    return false;
                if (a.length !== b.length)
                    return false;
                for (let i = 0; i < a.length; i++) {
                    if (!isEqual(a[i], b[i]))
                        return false;
                }
                return true;
            }
            else if (Array.isArray(b)) {
                return false;
            }
            if (!isPlainObject(a) || !isPlainObject(b))
                return false;
            const aKeys = Object.keys(a);
            const bKeys = Object.keys(b);
            if (aKeys.length !== bKeys.length)
                return false;
            for (const aKey of aKeys) {
                if (!bKeys.includes(aKey))
                    return false;
                if (!isEqual(a[aKey], b[aKey])) {
                    return false;
                }
            }
            return true;
        }
        return false;
    }
    Predicate.isEqual = isEqual;
})(Predicate || (exports.Predicate = Predicate = {}));
