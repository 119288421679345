"use strict";

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
var __createBinding = void 0 && (void 0).__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  var desc = Object.getOwnPropertyDescriptor(m, k);
  if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
    desc = {
      enumerable: true,
      get: function () {
        return m[k];
      }
    };
  }
  Object.defineProperty(o, k2, desc);
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});
var __setModuleDefault = void 0 && (void 0).__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, "default", {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o["default"] = v;
});
var __importStar = void 0 && (void 0).__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
  __setModuleDefault(result, mod);
  return result;
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.InspectorPanel = exports.HeadingComponent = void 0;
const react_1 = __importStar(require("react"));
const styled_components_1 = __importStar(require("styled-components"));
const lodash_1 = require("lodash");
const ScrollColumn_1 = require("../ScrollColumn");
const foundations_1 = require("../../foundations");
const DropdownHeading_1 = require("../DropdownHeading");
const Heading_1 = require("../Heading");
const InspectorPanelStyles_1 = require("./InspectorPanelStyles");
const accordionLineHeight = 56;
const InspectorPanelItemHeader = styled_components_1.default.div`
  ${({
  theme,
  showCursor
}) => `
    background-color: ${theme.colors.backgroundTertiary};
    border-bottom: 1px solid ${theme.colors.borderSecondary};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: ${showCursor ? 'pointer' : 'default'};
    height: ${accordionLineHeight}px;
    align-items: center;
    margin-right: ${theme.spacing.xl}px;
  `}
`;
InspectorPanelItemHeader.displayName = "InspectorPanelItemHeader";
const IconContainer = styled_components_1.default.div`
  ${({
  theme
}) => `
    align-self: center;
    padding-left: ${theme.spacing.xs}px;
    height: ${theme.spacing.l}px;
  `}
`;
IconContainer.displayName = "IconContainer";
const InspectorPanelItemContentWrapper = styled_components_1.default.div`
  ${({
  active,
  theme
}) => `
    height: calc(100% - ${accordionLineHeight}px);
    display: ${active ? 'block' : 'none'};
    padding-top: 12px;
    margin-left: -${theme.spacing.l}px;
  `}
`;
InspectorPanelItemContentWrapper.displayName = "InspectorPanelItemContentWrapper";
const ScrollContentWrapper = styled_components_1.default.div`
  ${({
  theme
}) => `
    padding-left: ${theme.spacing.l}px;
  `}
`;
ScrollContentWrapper.displayName = "ScrollContentWrapper";
exports.HeadingComponent = (0, styled_components_1.default)(props => react_1.default.createElement(Heading_1.Heading, _objectSpread(_objectSpread({}, props), {}, {
  level: 2,
  size: "m"
})))`
  line-height: ${accordionLineHeight}px;
  user-select: none;
  width: auto;
`;
const createButton = (text, disabled, icon, onClick, active) => ({
  disabled,
  rightIcon: icon,
  text,
  onClick,
  active
});
function InspectorPanel({
  steps = [],
  selectedStep,
  stepComponents,
  forceRender = false
}) {
  var _a, _b;
  let selectedPanel = steps[0];
  const containerRef = (0, react_1.useRef)();
  const _ref = (0, styled_components_1.useTheme)(),
    spacing = _ref.spacing;
  if (!!selectedStep) {
    selectedPanel = steps.find(step => step.key === selectedStep) || steps[0];
  }
  const _ref2 = (0, react_1.useState)(false),
    _ref3 = _slicedToArray(_ref2, 2),
    menuIsOpen = _ref3[0],
    setMenuIsOpen = _ref3[1];
  const menuItems = steps.map(step => createButton(step.title, false, step.iconComponent, step.onClick, step.key === selectedPanel.key));
  const panelItems = (0, lodash_1.map)(stepComponents, (component, step) => react_1.default.createElement(InspectorPanelItemContentWrapper, {
    key: step,
    active: step === selectedStep
  }, react_1.default.createElement(ScrollColumn_1.ScrollColumn, null, react_1.default.createElement(ScrollContentWrapper, null, component))));
  const length = menuItems.length;
  const currentStepIndex = steps.findIndex(step => step.key === selectedStep);
  const count = `${currentStepIndex + 1}/${length}`;
  return react_1.default.createElement(InspectorPanelStyles_1.Container, {
    ref: containerRef
  }, react_1.default.createElement(InspectorPanelItemHeader, {
    showCursor: menuItems.length > 1,
    onClick: e => {
      e.stopPropagation();
      setMenuIsOpen(prevState => !prevState);
    }
  }, react_1.default.createElement(foundations_1.Flex, {
    alignItems: "center"
  }, selectedPanel && react_1.default.createElement(react_1.default.Fragment, null, menuItems.length > 1 ? react_1.default.createElement(DropdownHeading_1.DropdownHeading, {
    icon: "CaretDown",
    align: "left",
    count: count,
    text: selectedPanel.title,
    onClose: () => setMenuIsOpen(false),
    isOpen: menuIsOpen,
    menuWidth: ((_a = containerRef === null || containerRef === void 0 ? void 0 : containerRef.current) === null || _a === void 0 ? void 0 : _a.clientWidth) ? ((_b = containerRef === null || containerRef === void 0 ? void 0 : containerRef.current) === null || _b === void 0 ? void 0 : _b.clientWidth) - spacing.m : 0,
    menuItems: [{
      items: menuItems
    }]
  }) : react_1.default.createElement(exports.HeadingComponent, null, selectedPanel.title))), selectedPanel && selectedPanel.iconComponent && react_1.default.createElement(IconContainer, null, selectedPanel.iconComponent)), !forceRender && panelItems.find(item => item.key === selectedStep), forceRender && panelItems);
}
exports.InspectorPanel = InspectorPanel;