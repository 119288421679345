import React from 'react';
export const Logo = (
  <svg width="182" height="36" viewBox="0 0 182 36" fill="#fff" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.5422 35.0105C14.0727 35.0105 10.6811 33.9841 7.79627 32.0612C4.91147 30.1383 2.66305 27.4052 1.33533 24.2075C0.0076006 21.0098 -0.339793 17.4911 0.337076 14.0964C1.01394 10.7018 2.68467 7.58356 5.13799 5.13614C7.59131 2.68872 10.717 1.022 14.1199 0.346763C17.5227 -0.328478 21.0499 0.0180804 24.2553 1.34261C27.4607 2.66715 30.2004 4.91017 32.128 7.78803C34.0555 10.6659 35.0843 14.0493 35.0843 17.5105C35.0786 22.15 33.2286 26.5979 29.9401 29.8786C26.6515 33.1592 22.1929 35.0048 17.5422 35.0105ZM17.5422 8.08743C15.674 8.08743 13.8477 8.64008 12.2944 9.6755C10.741 10.7109 9.53034 12.1826 8.81541 13.9044C8.10048 15.6263 7.91342 17.521 8.27789 19.3489C8.64236 21.1768 9.54198 22.8558 10.863 24.1736C12.184 25.4915 13.8671 26.3889 15.6994 26.7525C17.5317 27.1161 19.4309 26.9295 21.1569 26.2163C22.8829 25.5031 24.3581 24.2953 25.3961 22.7457C26.434 21.1961 26.988 19.3742 26.988 17.5105C26.988 16.273 26.7436 15.0477 26.2689 13.9044C25.7942 12.7612 25.0985 11.7224 24.2214 10.8474C23.3442 9.97237 22.3029 9.27827 21.1569 8.80472C20.0109 8.33116 18.7826 8.08743 17.5422 8.08743Z"
      fill="white"
    />
    <path
      d="M127.382 35.0107C123.912 35.0107 120.521 33.9844 117.636 32.0615C114.751 30.1385 112.503 27.4054 111.175 24.2077C109.847 21.01 109.5 17.4913 110.177 14.0967C110.854 10.702 112.525 7.5838 114.978 5.13638C117.431 2.68896 120.557 1.02225 123.96 0.347008C127.363 -0.328234 130.89 0.0183245 134.095 1.34286C137.3 2.66739 140.04 4.91041 141.968 7.78827C143.895 10.6661 144.924 14.0496 144.924 17.5107C144.918 22.1503 143.068 26.5982 139.78 29.8788C136.491 33.1595 132.033 35.005 127.382 35.0107ZM127.382 8.08767C125.514 8.08767 123.688 8.64033 122.134 9.67575C120.581 10.7112 119.37 12.1829 118.655 13.9047C117.94 15.6265 117.753 17.5212 118.118 19.3491C118.482 21.177 119.382 22.856 120.703 24.1739C122.024 25.4917 123.707 26.3892 125.539 26.7528C127.372 27.1164 129.271 26.9297 130.997 26.2165C132.723 25.5033 134.198 24.2955 135.236 22.7459C136.274 21.1963 136.828 19.3745 136.828 17.5107C136.828 15.0116 135.833 12.6148 134.061 10.8476C132.29 9.08046 129.887 8.08767 127.382 8.08767Z"
      fill="white"
    />
    <path
      d="M57.213 26.9337C50.9842 26.9337 46.1479 20.8841 48.28 14.366C48.7246 13.0209 49.4792 11.7985 50.4831 10.797C51.4871 9.79542 52.7124 9.04266 54.0608 8.59912C60.5946 6.4722 66.6588 11.2968 66.6588 17.5107C66.6588 20.6795 65.4497 23.4876 63.0559 25.1972C63.0447 25.2055 63.0364 25.2171 63.0321 25.2303C63.0278 25.2436 63.0277 25.2578 63.0319 25.2711C63.036 25.2844 63.0442 25.296 63.0553 25.3045C63.0663 25.313 63.0797 25.3178 63.0937 25.3184H73.0009C73.0412 25.3189 73.0809 25.3081 73.1153 25.2872C73.1497 25.2662 73.1774 25.2359 73.1953 25.1999C74.3314 22.8764 74.7552 20.2676 74.7552 17.5107C74.7511 14.8588 74.1442 12.2423 72.9801 9.85816C71.8161 7.47401 70.1252 5.38426 68.0348 3.74612C65.9443 2.10797 63.5086 0.964101 60.9111 0.400559C58.3135 -0.162983 55.6216 -0.13152 53.038 0.492581C49.903 1.22416 47.0354 2.8153 44.7588 5.08642C42.4822 7.35754 40.8873 10.2182 40.1539 13.3457C39.5283 15.9231 39.4968 18.6085 40.0617 21.1998C40.6266 23.7912 41.7732 26.221 43.4153 28.3064C45.0574 30.3919 47.1522 32.0787 49.5421 33.2399C51.932 34.4011 54.5547 35.0066 57.213 35.0107H79.0975C79.1255 35.0109 79.1532 35.0044 79.1782 34.9917C79.2032 34.9791 79.2247 34.9607 79.2411 34.938C79.2575 34.9153 79.2682 34.8891 79.2723 34.8614C79.2765 34.8338 79.2739 34.8056 79.2648 34.7791L76.7064 27.1168C76.6884 27.0638 76.6543 27.0176 76.6089 26.9848C76.5634 26.952 76.5088 26.9342 76.4527 26.9337H57.213Z"
      fill="white"
    />
    <path
      d="M107.142 0.54926C107.142 0.477856 107.114 0.409376 107.063 0.358885C107.013 0.308395 106.944 0.280029 106.873 0.280029H78.805C78.7335 0.280029 78.6648 0.308395 78.6142 0.358885C78.5636 0.409376 78.5352 0.477856 78.5352 0.54926V9.16464C78.5352 9.23605 78.5636 9.30453 78.6142 9.35502C78.6648 9.40551 78.7335 9.43388 78.805 9.43388H87.981C88.0525 9.43388 88.1212 9.46224 88.1718 9.51273C88.2224 9.56322 88.2508 9.6317 88.2508 9.70311V34.7416C88.2508 34.813 88.2793 34.8815 88.3299 34.9319C88.3805 34.9824 88.4491 35.0108 88.5207 35.0108H97.1569C97.2285 35.0108 97.2971 34.9824 97.3477 34.9319C97.3983 34.8815 97.4268 34.813 97.4268 34.7416V9.70311C97.4268 9.6317 97.4552 9.56322 97.5058 9.51273C97.5564 9.46224 97.6251 9.43388 97.6966 9.43388H106.873C106.944 9.43388 107.013 9.40551 107.063 9.35502C107.114 9.30453 107.142 9.23605 107.142 9.16464V0.54926Z"
      fill="white"
    />
    <path
      d="M180.551 35.0108H180.821C180.892 35.0108 180.961 34.9824 181.011 34.9319C181.062 34.8815 181.091 34.813 181.091 34.7416V0.54926C181.091 0.477856 181.062 0.409376 181.011 0.358885C180.961 0.308395 180.892 0.280029 180.821 0.280029H172.724C172.653 0.280029 172.584 0.308395 172.533 0.358885C172.483 0.409376 172.454 0.477856 172.454 0.54926V17.5108C172.455 17.5363 172.448 17.5613 172.434 17.5829C172.421 17.6046 172.401 17.6218 172.378 17.6327C172.355 17.6435 172.329 17.6474 172.304 17.6439C172.279 17.6404 172.255 17.6297 172.236 17.6131L151.358 0.460414C151.214 0.343701 151.034 0.280013 150.848 0.280029H150.594C150.523 0.280029 150.454 0.308395 150.403 0.358885C150.353 0.409376 150.324 0.477856 150.324 0.54926V34.7416C150.324 34.813 150.353 34.8815 150.403 34.9319C150.454 34.9824 150.523 35.0108 150.594 35.0108H158.69C158.762 35.0108 158.831 34.9824 158.881 34.9319C158.932 34.8815 158.96 34.813 158.96 34.7416V18.3185C158.96 18.293 158.967 18.268 158.98 18.2462C158.993 18.2245 159.013 18.207 159.036 18.1957C159.058 18.1844 159.084 18.1799 159.109 18.1825C159.135 18.1852 159.159 18.195 159.179 18.2108L180.06 34.8304C180.199 34.9421 180.372 35.0054 180.551 35.0108Z"
      fill="white"
    />
  </svg>
);
