"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Exception = void 0;
const Strings_1 = require("./Strings");
/**
 * Base class and utilities for representing and dealing with errors / exceptions.
 */
class Exception extends Error {
    /**
     * Initialize the exception.
     * @param name The name of the exception, e.g. `"Auth.BadPassword"`
     * @param pattern The message for the error, this can include string template patterns.
     * @param params The parameters associated with the error.
     */
    constructor(name, pattern, params) {
        super(Strings_1.Strings.interpolate(pattern, params));
        this.name = name;
        this.params = params;
    }
    toJSON() {
        return {
            '@type': 'Exception',
            name: this.name,
            message: this.message,
            params: this.params
        };
    }
}
exports.Exception = Exception;
(function (Exception) {
    /**
     * Define a named exception class.
     * @param name The name of the exception.
     * @param message The message for the exception.
     */
    function define(name, message) {
        return class OqtonException extends Exception {
            constructor(params) {
                super(name, message, params);
            }
            /**
             * Create an exception, then throw it.
             * @param params The parameters for the exception.
             */
            static throw(params) {
                const exception = new this(params);
                captureStackTrace(exception, this.throw);
                throw exception;
            }
        };
    }
    Exception.define = define;
    /**
     * Capture a stack trace.
     * @param error The error to populate.
     * @param creator The function which created the error.
     */
    function captureStackTrace(error, creator) {
        if (typeof Error.captureStackTrace === 'function') {
            Error.captureStackTrace(error, creator);
        }
    }
    Exception.captureStackTrace = captureStackTrace;
    /**
     * Creates an error with the given message, optionally using a specific
     * error constructor, and then throws the error.
     * The main purpose of this function is to work around JavaScript's
     * lack of throw expressions - it is not possible to throw an error directly
     * in an expression context, e.g. this is not valid JS: `() => throw new Error()`.
     * @example
     *  declare const user: {name?: string};
     *  const userName = user.name ?? Exception.raise('Must have a user name');
     *  console.log(userName);
     *
     * @param message The error message.
     * @param errorConstructor The error constructor, e.g. `TypeError`, defaults to `Error`.
     */
    function raise(message, errorConstructor = Error) {
        const error = new errorConstructor(message);
        captureStackTrace(error, raise);
        throw error;
    }
    Exception.raise = raise;
})(Exception || (exports.Exception = Exception = {}));
