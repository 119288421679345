"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.narrowerThan = exports.widerThan = void 0;
/**
 * Check if the current breakpoint is wider than the responsive target breakpoint
 * @param {String} responsiveTarget - The breakpoint we want to check if we are larger than
 * @param {String} responsiveKey - The current breakpoint
 * @param {Breakpoints} breakpoints
 * @return {Boolean} - Is the current window's breakpoint larger than the responsiveTarget breakpoint?
 */
const widerThan = (responsiveTarget, responsiveKey, breakpoints) => {
  const breakpointTargetWidth = breakpoints[responsiveTarget];
  const breakpointWidth = breakpoints[responsiveKey];
  if (breakpointTargetWidth === undefined) {
    console.error(`Unsupported breakpoint target "${responsiveTarget}"`);
    return false;
  }
  if (breakpointWidth === undefined) {
    console.error(`Unsupported breakpoint "${responsiveKey}"`);
    return false;
  }
  // parse the breakpoint pixels into floats for comparison
  return parseFloat(breakpointWidth) > parseFloat(breakpointTargetWidth);
};
exports.widerThan = widerThan;
/**
 * Check if the current breakpoint is smaller than the responsive target breakpoint
 * @param {String} responsiveTarget - The breakpoint we want to check if we are smaller than
 * @param {String} responsiveKey - The current breakpoint
 * @param {Breakpoints} breakpoints
 * @return {Boolean} - Is the current window's breakpoint smaller than the responsiveTarget breakpoint?
 */
const narrowerThan = (responsiveTarget, responsiveKey, breakpoints) => {
  const breakpointTargetWidth = breakpoints[responsiveTarget];
  const breakpointWidth = breakpoints[responsiveKey];
  if (!breakpointTargetWidth) {
    console.error(`Unsupported breakpoint target "${responsiveTarget}"`);
    return false;
  }
  if (!breakpointWidth) {
    console.error(`Unsupported breakpoint "${responsiveKey}"`);
    return false;
  }
  // parse the breakpoint pixels into floats for comparison
  return parseFloat(breakpointWidth) < parseFloat(breakpointTargetWidth);
};
exports.narrowerThan = narrowerThan;