"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SelectQA = void 0;
const testutils_1 = require("../../testutils");
const VisibilityControls_1 = require("../VisibilityControls");
class SelectQA extends testutils_1.QA {
  get visibilityControls() {
    return this.get(VisibilityControls_1.VisibilityControlsQA);
  }
}
exports.SelectQA = SelectQA;
SelectQA.componentName = 'Checkbox';
SelectQA.selector = "[data-testid='Select']";