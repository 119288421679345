"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
var __createBinding = void 0 && (void 0).__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  var desc = Object.getOwnPropertyDescriptor(m, k);
  if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
    desc = {
      enumerable: true,
      get: function () {
        return m[k];
      }
    };
  }
  Object.defineProperty(o, k2, desc);
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});
var __setModuleDefault = void 0 && (void 0).__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, "default", {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o["default"] = v;
});
var __importStar = void 0 && (void 0).__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
  __setModuleDefault(result, mod);
  return result;
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Checkbox = void 0;
const react_1 = __importStar(require("react"));
const inputs_1 = require("../../utils/inputs");
const foundations_1 = require("../../foundations");
const __1 = require("..");
const styleHelpers_1 = require("../../utils/styleHelpers");
const useTheme_1 = require("../../hooks/useTheme");
const index_1 = require("./index");
exports.Checkbox = react_1.default.memo(props => {
  const checked = props.checked,
    disabled = props.disabled,
    indeterminate = props.indeterminate,
    label = props.label,
    _props$name = props.name,
    name = _props$name === void 0 ? 'checkbox' : _props$name,
    tooltip = props.tooltip,
    labelSize = props.labelSize,
    value = props.value,
    _props$onChange = props.onChange,
    onChange = _props$onChange === void 0 ? () => {} : _props$onChange,
    isHidden = props.isHidden,
    isLocked = props.isLocked,
    onClickHidden = props.onClickHidden,
    onClickLock = props.onClickLock,
    testId = props.testId,
    checkboxColor = props.checkboxColor,
    stopPropagation = props.stopPropagation,
    inverted = props.inverted,
    noPadding = props.noPadding,
    readonly = props.readonly,
    icons = props.icons;
  const _ref = (0, react_1.useState)(false),
    _ref2 = _slicedToArray(_ref, 2),
    active = _ref2[0],
    setActive = _ref2[1];
  const _ref3 = (0, useTheme_1.useTheme)(),
    colors = _ref3.colors;
  const setIsActive = (0, react_1.useCallback)(isActive => {
    setActive(isActive);
  }, []);
  const toggleChecked = (0, react_1.useCallback)(e => {
    if (e) e.preventDefault(); // IMPORTANT, otherwise onClick can be triggered twice due to repaint
    if (readonly || disabled) return;
    onChange(!checked, {
      shiftKey: e ? e.shiftKey : false
    });
  }, [readonly, disabled, onChange, checked]);
  const getIcon = () => indeterminate ? react_1.default.createElement(__1.Icon, {
    name: "CheckboxIndeterminate",
    "data-test": "indeterminate"
  }) : react_1.default.createElement(__1.Icon, {
    name: "CheckboxCheck",
    "data-test": "check"
  });
  return react_1.default.createElement(foundations_1.Flex, {
    flexDirection: "row"
  }, react_1.default.createElement(index_1.CheckboxStyles.StyledCheckboxContainer, _objectSpread({
    noPadding: noPadding,
    color: disabled ? colors.secondary : colors.primary,
    "data-testid": "Checkbox",
    checkboxBorder: disabled ? colors.checkboxColors.borderDisabled : checkboxColor ? colors[checkboxColor] : colors.checkboxColors.border,
    checkboxBorderFocus: colors.checkboxColors.borderFocused,
    checkboxOutline: disabled ? colors.checkboxColors.outlineDisabled : colors.checkboxColors.outline,
    htmlFor: name,
    tabIndex: disabled ? undefined : 0,
    onClick: e => {
      stopPropagation && e.stopPropagation();
      toggleChecked(e);
    },
    onKeyDown: e => {
      e.persist();
      if ((0, inputs_1.isSpaceOrEnter)(e.keyCode) && !disabled) {
        setIsActive(true);
      }
    },
    onKeyUp: e => {
      e.persist();
      if ((0, inputs_1.isSpaceOrEnter)(e.keyCode) && !disabled) {
        setIsActive(false);
        toggleChecked(e);
      }
    }
  }, (0, styleHelpers_1.getSpaceProps)(props)), react_1.default.createElement(foundations_1.Flex, {
    alignItems: "center",
    flexDirection: inverted ? 'row-reverse' : 'row'
  }, react_1.default.createElement(index_1.CheckboxStyles.StyledCheckbox, {
    checked: checked,
    color: disabled ? colors.tertiary : colors.secondary,
    backgroundColor: disabled ? colors.checkboxColors.backgroundDisabled : colors.checkboxColors.background,
    checkboxOutlineActive: disabled || !active ? colors.checkboxColors.outlineDisabled : colors.checkboxColors.outlineActive,
    "data-testid": "CheckboxInner",
    "data-is-active": active,
    checkboxBorderFocus: colors.checkboxColors.borderFocused,
    tabIndex: disabled ? undefined : -1
  }, getIcon()), label && react_1.default.createElement(__1.Tooltip, {
    content: tooltip,
    placement: "top",
    arrow: true,
    disabled: !tooltip
  }, react_1.default.createElement(index_1.CheckboxStyles.StyledCheckboxLabel, {
    tabIndex: disabled ? undefined : -1,
    fontSize: labelSize,
    inverted: inverted
  }, label))), react_1.default.createElement("input", {
    checked: checked,
    disabled: disabled,
    readOnly: readonly,
    name: name,
    type: "checkbox",
    value: value,
    onChange: e => {
      if (readonly || disabled) return;
      onChange(e.currentTarget.checked, e);
    },
    "data-testid": testId
  })), react_1.default.createElement(index_1.CheckboxStyles.StyledCheckboxIcons, null, (icons === null || icons === void 0 ? void 0 : icons.length) && icons.map(icon => react_1.default.createElement(__1.Icon, {
    name: icon,
    disabled: disabled
  })), react_1.default.createElement(__1.VisibilityControls, {
    disabled: disabled,
    isHidden: isHidden,
    isLocked: isLocked,
    onClickHidden: onClickHidden,
    onClickLock: onClickLock
  })));
});