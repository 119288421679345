"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.returnValueFromAsyncGenerator = void 0;
async function returnValueFromAsyncGenerator(generator) {
    while (true) {
        const { done, value } = await generator.next();
        if (done)
            return value;
    }
}
exports.returnValueFromAsyncGenerator = returnValueFromAsyncGenerator;
