"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var _Subject_subscribers, _Subject_closed;
Object.defineProperty(exports, "__esModule", { value: true });
exports.Subject = void 0;
const zen_observable_ts_1 = require("zen-observable-ts");
__exportStar(require("zen-observable-ts"), exports);
class Subject extends zen_observable_ts_1.Observable {
    constructor() {
        super(subscriber => {
            __classPrivateFieldGet(this, _Subject_subscribers, "f").push(subscriber);
            return () => {
                const index = __classPrivateFieldGet(this, _Subject_subscribers, "f").indexOf(subscriber);
                if (index >= 0) {
                    __classPrivateFieldGet(this, _Subject_subscribers, "f").splice(index, 1);
                }
            };
        });
        _Subject_subscribers.set(this, []);
        _Subject_closed.set(this, false);
    }
    next(value) {
        if (!__classPrivateFieldGet(this, _Subject_closed, "f")) {
            for (const subscriber of __classPrivateFieldGet(this, _Subject_subscribers, "f")) {
                subscriber.next(value);
            }
        }
    }
    error(err) {
        if (!__classPrivateFieldGet(this, _Subject_closed, "f")) {
            __classPrivateFieldSet(this, _Subject_closed, true, "f");
            while (__classPrivateFieldGet(this, _Subject_subscribers, "f").length > 0) {
                __classPrivateFieldGet(this, _Subject_subscribers, "f").shift().error(err);
            }
        }
    }
    complete() {
        if (!__classPrivateFieldGet(this, _Subject_closed, "f")) {
            __classPrivateFieldSet(this, _Subject_closed, true, "f");
            while (__classPrivateFieldGet(this, _Subject_subscribers, "f").length > 0) {
                __classPrivateFieldGet(this, _Subject_subscribers, "f").shift().complete();
            }
        }
    }
    asObservable() {
        return new zen_observable_ts_1.Observable(subscriber => this.subscribe(subscriber));
    }
}
exports.Subject = Subject;
_Subject_subscribers = new WeakMap(), _Subject_closed = new WeakMap();
