"use strict";

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.dateAsString = exports.isValidDate = exports.updateTime = exports.formatDateValue = exports.formatTimeValue = exports.parsePartValueToDate = exports.parseDateString = exports.parseTimeString = exports.DatePickerFormats = exports.TimePickerFormats = exports.timeMaskYearsMonths = exports.timeMaskYearsMonthsDays = exports.timeMaskHoursMinSecs = exports.timeMaskHoursMins = exports.getRanges = exports.DATERANGES = exports.TIMERANGES = exports.TIME_VALUE_MAX = exports.DATE_DIVISIONS = exports.TIME_DIVISIONS = exports.DATE_SEPARATOR = exports.TIME_SEPARATOR = void 0;
exports.TIME_SEPARATOR = ':';
exports.DATE_SEPARATOR = '/';
exports.TIME_DIVISIONS = {
  day: 'day',
  hour: 'hour',
  minute: 'minute',
  second: 'second'
};
exports.DATE_DIVISIONS = {
  year: 'year',
  month: 'month',
  day: 'day'
};
exports.TIME_VALUE_MAX = {
  days: 5,
  hoursStandard: 12,
  hoursIntl: 23,
  minute: 59,
  second: 59
};
exports.TIMERANGES = {
  HH: {
    startRange: 0,
    endRange: 2
  },
  MM: {
    startRange: 3,
    endRange: 5
  },
  SS: {
    startRange: 6,
    endRange: 8
  }
};
exports.DATERANGES = {
  YYYY: {
    startRange: 0,
    endRange: 4
  },
  MM: {
    startRange: 5,
    endRange: 7
  },
  DD: {
    startRange: 8,
    endRange: 10
  }
};
const getRanges = format => {
  const splitTime = format.split(':');
  const splitDate = format.split('/');
  if (splitTime.length > 1) {
    return exports.TIMERANGES;
  }
  if (splitDate.length > 1) {
    return exports.DATERANGES;
  }
};
exports.getRanges = getRanges;
const timeMaskHoursMins = value => {
  const chars = value.split('');
  const hours = [/[0-2H]/, chars[0] === '2' ? /[0-3H]/ : /[0-9H]/];
  const minutes = [/[0-5M]/, /[0-9M]/];
  return hours.concat(exports.TIME_SEPARATOR).concat(minutes);
};
exports.timeMaskHoursMins = timeMaskHoursMins;
const timeMaskHoursMinSecs = value => {
  const chars = value.split('');
  const hours = [/[0-2H]/, chars[0] === '2' ? /[0-3H]/ : /[0-9H]/];
  const minutes = [/[0-5M]/, /[0-9M]/];
  const seconds = [/[0-5S]/, /[0-9S]/];
  return hours.concat(exports.TIME_SEPARATOR).concat(minutes).concat(exports.TIME_SEPARATOR).concat(seconds);
};
exports.timeMaskHoursMinSecs = timeMaskHoursMinSecs;
const timeMaskYearsMonthsDays = value => {
  const chars = value.split('');
  const years = [/[0-2Y]/, /[0-9Y]/, /[0-9Y]/, /[0-9Y]/];
  const months = [/[0-1M]/, chars[0] === '1' ? /[0-2M]/ : /[0-9M]/];
  const days = [/[0-3D]/, chars[0] === '3' ? /[0-1D]/ : /[0-9D]/];
  return years.concat(exports.DATE_SEPARATOR).concat(months).concat(exports.DATE_SEPARATOR).concat(days);
};
exports.timeMaskYearsMonthsDays = timeMaskYearsMonthsDays;
const timeMaskYearsMonths = value => {
  const chars = value.split('');
  const years = [/[0-2Y]/, /[0-9Y]/, /[0-9Y]/, /[0-9Y]/];
  const months = [/[0-1M]/, chars[0] === '1' ? /[0-2M]/ : /[0-9M]/];
  return years.concat(exports.DATE_SEPARATOR).concat(months).concat(exports.DATE_SEPARATOR);
};
exports.timeMaskYearsMonths = timeMaskYearsMonths;
exports.TimePickerFormats = {
  'HH:MM': {
    name: 'HH:MM',
    defaultValue: '00:00',
    mask: exports.timeMaskHoursMins
  },
  'HH:MM:SS': {
    name: 'HH:MM:SS',
    defaultValue: '00:00:00',
    mask: exports.timeMaskHoursMinSecs
  }
};
exports.DatePickerFormats = {
  'YYYY/MM/DD': {
    name: 'YYYY/MM/DD',
    defaultValue: '2000/01/01',
    mask: exports.timeMaskYearsMonthsDays
  },
  'YYYY/MM': {
    name: 'YYYY/MM',
    defaultValue: '2000/01',
    mask: exports.timeMaskYearsMonths
  }
};
const parseTimeString = value => {
  const _value$split = value.split(exports.TIME_SEPARATOR),
    _value$split2 = _slicedToArray(_value$split, 3),
    hours = _value$split2[0],
    minutes = _value$split2[1],
    seconds = _value$split2[2];
  return {
    hours: parseInt(hours, 10) || 0,
    minutes: parseInt(minutes, 10) || 0,
    seconds: parseInt(seconds, 10) || 0
  };
};
exports.parseTimeString = parseTimeString;
const parseDateString = value => {
  const _value$split3 = value.split(exports.DATE_SEPARATOR),
    _value$split4 = _slicedToArray(_value$split3, 3),
    years = _value$split4[0],
    months = _value$split4[1],
    days = _value$split4[2];
  return {
    years: parseInt(years, 10) || 0,
    months: parseInt(months, 10) || 0,
    days: parseInt(days, 10) || 0
  };
};
exports.parseDateString = parseDateString;
const parsePartValueToDate = value => {
  const _ref = (0, exports.parseDateString)(value),
    years = _ref.years,
    months = _ref.months,
    days = _ref.days;
  return new Date(years, months, days);
};
exports.parsePartValueToDate = parsePartValueToDate;
// Set default time for each segment (YYYY, MM, DD) if no value is available
const formatTimeValue = (value, format) => {
  const valueSplit = value ? value.split(exports.TIME_SEPARATOR) : [];
  const defaultTimeSplit = exports.TimePickerFormats[format].defaultValue.split(exports.TIME_SEPARATOR);
  const mappedValues = defaultTimeSplit.map((val, index) => (valueSplit[index] || defaultTimeSplit[index]).toString()).join(exports.TIME_SEPARATOR);
  return mappedValues;
};
exports.formatTimeValue = formatTimeValue;
// Set default time for each segment (HH, MM, SS) if no value is available
const formatDateValue = (value, format) => {
  const valueSplit = value ? value.split(exports.DATE_SEPARATOR) : [];
  const defaultTimeSplit = exports.DatePickerFormats[format].defaultValue.split(exports.DATE_SEPARATOR);
  const mappedValues = defaultTimeSplit.map((val, index) => (valueSplit[index] || defaultTimeSplit[index]).toString()).join(exports.DATE_SEPARATOR);
  return mappedValues;
};
exports.formatDateValue = formatDateValue;
const updateTime = (division, value, time, format) => {
  const formattedTime = (0, exports.formatTimeValue)(time, format);
  const timeSplit = formattedTime.split(exports.TIME_SEPARATOR);
  switch (division) {
    case exports.TIME_DIVISIONS.hour:
      timeSplit[0] = value.toString();
      break;
    case exports.TIME_DIVISIONS.minute:
      timeSplit[1] = value.toString();
      break;
    case exports.TIME_DIVISIONS.second:
      timeSplit[2] = value.toString();
      break;
    default:
      break;
  }
  // send it along to parent component through onChange()
  return timeSplit.join(exports.TIME_SEPARATOR);
};
exports.updateTime = updateTime;
const isValidDate = d => d instanceof Date && !Number.isNaN(d);
exports.isValidDate = isValidDate;
const dateAsString = date => {
  if (date) {
    const mm = date.getMonth() + 1; // getMonth() is zero-based
    const dd = date.getDate();
    return `${date.getFullYear()}/${(mm > 9 ? '' : '0') + mm}/${(dd > 9 ? '' : '0') + dd}`;
  }
  return 'YYYY/MM/DD';
};
exports.dateAsString = dateAsString;