"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createFrameStyles = void 0;
const createFrameStyles = colors => ({
  bgStackPrimary: `
    background: ${colors.backgroundPrimary};
    border-radius: 6px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.12);
  `,
  bgStackSecondary: `
    background: ${colors.backgroundPrimary};
    border-radius: 6px;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
  `,
  stackSecondary: `
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
  `,
  bgStackPrimaryWithBorders: `
    background: ${colors.backgroundPrimary};
    border-radius: 6px;
    border: 1px solid ${colors.borderTertiary};
    box-shadow: inset 0 0 10px 0 ${colors.inversePrimary},
      0 2px 10px 0 rgba(0, 0, 0, 0.05);
  `,
  bgStackPrimaryWithBordersFocused: `
    background: ${colors.backgroundPrimary};
    border-radius: 6px;
    border: 1px solid ${colors.accent};
    outline: none;
    box-shadow: 0px 0px 6px 0px ${colors.accent};
  `,
  bgStackPrimaryWidget: `
    background: ${colors.backgroundPrimary};
    border-radius: 6px;
    border: 1px solid ${colors.borderTertiary};
    box-shadow: inset 0px 1px 2px 0px rgba(0, 0, 0, 0.02);
  `,
  bgStackPrimaryWidgetWithBordersSelected: `
    background: ${colors.backgroundPrimary};
    border-radius: 6px;
    border: 0;
    box-shadow: 0px 0px 0px 4px rgba(59, 179, 219, 0.3);
  `,
  bgStackTertiary: `
    background: ${colors.bgStackTertiary};
    border-radius: 6px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.02);
  `,
  bgStackTertiaryWithBorders: `
    background: ${colors.backgroundSecondary};
    border-radius: 6px;
    border: 1px solid ${colors.borderTertiary};
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.02);
  `,
  bgStackTertiaryWithBordersFocused: `
    background: ${colors.backgroundSecondary};
    border-radius: 6px;
    border: 1px solid ${colors.accent};
    outline: none;
    box-shadow: 0px 0px 6px 0px ${colors.accent};
  `,
  transparent: `
    background: transparent;
    border-radius: 6px;
    outline: none;
  `,
  transparentWithBordersSelected: `
    background: transparent;
    border-radius: 6px;
    border: 1px solid ${colors.accent};
    outline: none;
    box-shadow: 0px 0px 6px 0px ${colors.accent};
  `,
  spacer: `
    background: transparent;
    border-radius: 6px;
    border: 2px dashed ${colors.tertiary};
    outline: none;
  `,
  spacerSelected: `
    background: transparent;
    border-radius: 2px;
    border: 2px dashed ${colors.accent};
    outline: none;
  `,
  sliderKnob: `
    background: ${colors.inversePrimary};
    border: 1px solid ${colors.borderSecondary};
    box-shadow: 0px 1px 3px 0px rgba(187, 196, 197, 0.5);
    outline: none;
  `,
  sliderBar: `
    background: ${colors.inversePrimary};
    border-radius: 2px;
    border: 1px solid ${colors.borderSecondary};
    outline: none;
  `
});
exports.createFrameStyles = createFrameStyles;