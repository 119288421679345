"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.joinFormatTokens = void 0;
/**
 * Join a list of format tokens producing a string.
 * @param tokens The tokens to join.
 * @param separator
 */
function joinFormatTokens(tokens, separator = ' ') {
    return tokens.map(token => (token.type === 'value' ? token.value : token.symbol)).join(separator);
}
exports.joinFormatTokens = joinFormatTokens;
