"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Sets = void 0;
var Sets;
(function (Sets) {
    /**
     * Find the intersection of the given sets, returning a new set.
     * @param inputs The sets to intersect.
     */
    function intersect(...inputs) {
        const output = new Set();
        if (inputs.length === 0) {
            return output;
        }
        if (inputs.length === 1) {
            return inputs[0];
        }
        for (const entry of inputs[0]) {
            output.add(entry);
        }
        for (let i = 1; i < inputs.length; i++) {
            const input = inputs[i];
            for (const entry of output) {
                if (!input.has(entry)) {
                    output.delete(entry);
                }
            }
        }
        return output;
    }
    Sets.intersect = intersect;
    /**
     * Find the union of the given sets, returning a new set.
     * @param inputs The sets to union.
     */
    function union(...inputs) {
        const output = new Set();
        for (const input of inputs) {
            for (const entry of input) {
                output.add(entry);
            }
        }
        return output;
    }
    Sets.union = union;
    function subtract(a, b) {
        const output = new Set();
        for (const value of a) {
            if (!b.has(value)) {
                output.add(value);
            }
        }
        return output;
    }
    Sets.subtract = subtract;
})(Sets || (exports.Sets = Sets = {}));
