"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.versionlessUrn = exports.convertSecsToDurationString = exports.convertSecsToTimeString = exports.convertSecsToDuration = exports.getTotalSeconds = exports.between = exports.max = exports.min = exports.isValidNumber = void 0;
const isValidNumber = a => typeof a === 'number' && !Number.isNaN(a);
exports.isValidNumber = isValidNumber;
const min = (...args) => Math.min(...args.filter(exports.isValidNumber));
exports.min = min;
const max = (...args) => Math.max(...args.filter(exports.isValidNumber));
exports.max = max;
const between = (value, minValue, maxValue) => {
  if (minValue && minValue > value) {
    return minValue;
  }
  if (maxValue && maxValue < value) {
    return maxValue;
  }
  return value;
};
exports.between = between;
const getHours = val => val * 60 * 60;
const getMinutes = val => val * 60;
const getTotalSeconds = (division, val, value) => {
  let time = null;
  if (division === 'hour') {
    time = getHours(parseInt(val, 10));
  } else if (division === 'minute') {
    time = getMinutes(parseInt(val, 10));
  } else if (division === 'second') {
    time = parseInt(val, 10);
  }
  const duration = (0, exports.convertSecsToDuration)(value);
  if (!duration) {
    return time;
  }
  const mergedTime = _objectSpread(_objectSpread({}, duration), {
    [division]: val
  });
  return getHours(parseInt(mergedTime.hour, 10)) + getMinutes(parseInt(mergedTime.minute, 10)) + parseInt(mergedTime.second, 10);
};
exports.getTotalSeconds = getTotalSeconds;
const convertSecsToDuration = value => {
  if (value === null) {
    return null;
  }
  let s = value;
  let m = Math.floor(s / 60);
  s = s % 60;
  let h = Math.floor(m / 60);
  let d = Math.floor(h / 24);
  m = m % 60;
  h = h % 24;
  h += d * 24;
  const hour = h.toString().padStart(2, '0');
  const minute = `0${m || 0}`.slice(-2);
  const second = `0${s || 0}`.slice(-2);
  return {
    hour,
    minute,
    second
  };
};
exports.convertSecsToDuration = convertSecsToDuration;
const convertSecsToTimeString = value => {
  const duration = (0, exports.convertSecsToDuration)(value);
  if (!duration) {
    return null;
  }
  return `${duration.hour}:${duration.minute}:${duration.second}`;
};
exports.convertSecsToTimeString = convertSecsToTimeString;
const convertSecsToDurationString = value => {
  const duration = (0, exports.convertSecsToDuration)(value);
  if (!duration) {
    return null;
  }
  return `${duration.hour !== '00' ? duration.hour + 'h' : ''} ${duration.minute !== '00' ? duration.minute + 'm' : ''} ${duration.second !== '00' ? duration.second + 's' : ''}`;
};
exports.convertSecsToDurationString = convertSecsToDurationString;
function versionlessUrn(urn) {
  if (typeof urn !== 'string') {
    return urn;
  }
  let colonCount = 0;
  for (let i = 0; i < urn.length; i += 1) {
    if (urn.charCodeAt(i) === 58) {
      colonCount += 1;
    }
    if (colonCount === 7) {
      return urn.slice(0, i);
    }
  }
  return urn;
}
exports.versionlessUrn = versionlessUrn;