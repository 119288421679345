"use strict";

const _excluded = ["type", "children", "inputFieldRef", "disabled", "readonly", "error", "warning", "label", "unit", "onBlur", "onFocus", "onChange", "onValidValueChange", "tooltip", "value", "validator", "textArea", "style", "placeholder", "isHidden", "isLocked", "onClickHidden", "onClickLock", "testId", "autoFocus", "required", "hideLabel", "color", "icons"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }
function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }
var __createBinding = void 0 && (void 0).__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  var desc = Object.getOwnPropertyDescriptor(m, k);
  if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
    desc = {
      enumerable: true,
      get: function () {
        return m[k];
      }
    };
  }
  Object.defineProperty(o, k2, desc);
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});
var __setModuleDefault = void 0 && (void 0).__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, "default", {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o["default"] = v;
});
var __importStar = void 0 && (void 0).__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
  __setModuleDefault(result, mod);
  return result;
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.InputText = void 0;
const react_1 = __importStar(require("react"));
const lodash_1 = require("lodash");
const index_1 = require("../index");
const useAutofocus_1 = require("../../hooks/useAutofocus");
const Input_1 = require("../Input");
const foundations_1 = require("../../foundations");
const components_1 = require("./components");
const mergeRefs_1 = require("./mergeRefs");
exports.InputText = react_1.default.memo(props => {
  const _props$type = props.type,
    type = _props$type === void 0 ? 'text' : _props$type,
    children = props.children,
    inputFieldRef = props.inputFieldRef,
    _props$disabled = props.disabled,
    disabled = _props$disabled === void 0 ? false : _props$disabled,
    _props$readonly = props.readonly,
    readonly = _props$readonly === void 0 ? false : _props$readonly,
    error = props.error,
    warning = props.warning,
    _props$label = props.label,
    label = _props$label === void 0 ? '' : _props$label,
    unit = props.unit,
    onBlur = props.onBlur,
    onFocus = props.onFocus,
    onChange = props.onChange,
    onValidValueChange = props.onValidValueChange,
    tooltip = props.tooltip,
    value = props.value,
    validator = props.validator,
    _props$textArea = props.textArea,
    textArea = _props$textArea === void 0 ? false : _props$textArea,
    style = props.style,
    placeholder = props.placeholder,
    isHidden = props.isHidden,
    isLocked = props.isLocked,
    onClickHidden = props.onClickHidden,
    onClickLock = props.onClickLock,
    testId = props.testId,
    autoFocus = props.autoFocus,
    required = props.required,
    _props$hideLabel = props.hideLabel,
    hideLabel = _props$hideLabel === void 0 ? false : _props$hideLabel,
    color = props.color,
    icons = props.icons,
    rest = _objectWithoutProperties(props, _excluded);
  const _ref = (0, react_1.useState)(false),
    _ref2 = _slicedToArray(_ref, 2),
    focused = _ref2[0],
    setFocused = _ref2[1];
  const _ref3 = (0, react_1.useState)(value),
    _ref4 = _slicedToArray(_ref3, 2),
    displayValue = _ref4[0],
    setDisplayValue = _ref4[1];
  const ref = (0, useAutofocus_1.useAutofocus)();
  const validationError = (0, react_1.useMemo)(() => {
    if ((0, lodash_1.isFunction)(validator)) {
      const validity = validator(String(focused ? displayValue : value));
      if (validity instanceof Input_1.ValidationError) return validity;
    }
  }, [validator, focused, displayValue, value]);
  const inputRef = (0, react_1.useRef)(null);
  const mergedInputRef = (0, mergeRefs_1.mergeRefs)(inputRef, inputFieldRef, autoFocus && ref);
  // In case of no label, pass a non-breaking space into the label to keep error text aligned vertically
  const labelOrNBSP = label ? required ? label + ' *' : label : '';
  const focusInput = (0, react_1.useCallback)(() => {
    const current = inputRef.current;
    if (current) {
      current.focus();
    }
  }, [inputRef]);
  const handleFocus = (0, react_1.useCallback)(e => {
    setFocused(true);
    setDisplayValue(value);
    if (onFocus) {
      onFocus(e);
    }
  }, [onFocus, value]);
  const handleBlur = (0, react_1.useCallback)(e => {
    setFocused(false);
    if (onBlur) {
      onBlur(e);
    }
  }, [onBlur]);
  const handleChange = (0, react_1.useMemo)(() => !(0, lodash_1.isFunction)(validator) ? onChange : e => {
    setDisplayValue(e.currentTarget.value); // always display user input
    if (onValidValueChange) {
      const val = validator(e.currentTarget.value);
      if (!(val instanceof Input_1.ValidationError)) onValidValueChange(val !== undefined ? val : e.currentTarget.value);
    }
    if (onChange) onChange(e); // always call the original onChange, regardless of value validity
  }, [validator, onChange, onValidValueChange]);
  const handleWheel = (0, react_1.useCallback)(() => {
    const current = inputRef.current;
    if (current) current.blur();
  }, [inputRef]);
  // combine different possible error.
  let finalError = error;
  if (validationError) finalError = error ? `${error} and ${validationError.error}` : validationError.error;
  const inputProps = {
    inputRef,
    warning,
    error: finalError,
    disabled,
    readOnly: readonly,
    onFocus: handleFocus,
    onBlur: handleBlur,
    onChange: handleChange,
    onWheel: handleWheel,
    min: validator && validator.min,
    max: validator && validator.max,
    minLength: validator && validator.minLength,
    maxLength: validator && validator.maxLength,
    value: validator && focused ? displayValue : value,
    placeholder,
    color
  };
  const labelProps = {
    error: finalError,
    disabled,
    label: labelOrNBSP,
    labelColor: color
  };
  const getLabel = () => {
    if (tooltip) {
      return react_1.default.createElement(index_1.InputTooltip, {
        content: tooltip,
        placement: "top-start",
        arrow: true
      }, react_1.default.createElement(index_1.InputLabel, _objectSpread({
        tooltip: !!tooltip,
        unit: !!unit
      }, labelProps)));
    }
    return react_1.default.createElement(index_1.InputLabel, _objectSpread({
      unit: !!unit
    }, labelProps));
  };
  const InputComponent = textArea ? index_1.Textarea : index_1.BaseInput;
  return react_1.default.createElement(index_1.InputStyles.Wrapper, {
    style: style,
    textArea: textArea,
    finalError: finalError,
    warning: warning,
    "data-testid": "InputText"
  }, !hideLabel && react_1.default.createElement(foundations_1.Flex, {
    alignItems: "center",
    height: "12px",
    onClick: focusInput
  }, getLabel()), react_1.default.createElement(InputComponent, _objectSpread(_objectSpread({
    autoComplete: "none",
    focused: focused,
    ref: mergedInputRef,
    type: type,
    "data-testid": testId,
    required: required
  }, inputProps), rest)), react_1.default.createElement(components_1.InputIcons, null, (icons === null || icons === void 0 ? void 0 : icons.length) && icons.map(icon => react_1.default.createElement(index_1.Icon, {
    name: icon,
    disabled: disabled
  })), react_1.default.createElement(index_1.VisibilityControls, {
    disabled: disabled,
    isHidden: isHidden,
    isLocked: isLocked,
    onClickHidden: onClickHidden,
    onClickLock: onClickLock
  }), warning || finalError ? react_1.default.createElement(index_1.InputValidationIcon, {
    type: finalError ? 'error' : 'warning'
  }) : null), unit && displayValue && react_1.default.createElement(index_1.InputUnit, {
    unit: unit,
    value: displayValue,
    isDisabled: disabled
  }), finalError && react_1.default.createElement(index_1.InputValidation, {
    type: "error"
  }, finalError), !finalError && warning && react_1.default.createElement(index_1.InputValidation, {
    type: "warning"
  }, warning));
});