"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DateTimeUnitDefinition = void 0;
const UnitDefinition_1 = require("../UnitDefinition");
const Formatters_1 = require("../Formatters");
/**
 * Date/Time Unit Definition.
 */
class DateTimeUnitDefinition extends UnitDefinition_1.UnitDefinition {
    formatAsTokens(input, precision = this.precision) {
        if (input == null) {
            return [];
        }
        return (0, Formatters_1.formatDateTimeAsTokens)(input, precision);
    }
}
exports.DateTimeUnitDefinition = DateTimeUnitDefinition;
