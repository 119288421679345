"use strict";

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
var __createBinding = void 0 && (void 0).__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  var desc = Object.getOwnPropertyDescriptor(m, k);
  if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
    desc = {
      enumerable: true,
      get: function () {
        return m[k];
      }
    };
  }
  Object.defineProperty(o, k2, desc);
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});
var __setModuleDefault = void 0 && (void 0).__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, "default", {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o["default"] = v;
});
var __importStar = void 0 && (void 0).__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
  __setModuleDefault(result, mod);
  return result;
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Spotlight = void 0;
const react_1 = __importStar(require("react"));
const Button_1 = require("../Button");
const Portal_1 = require("../Portal");
const Heading_1 = require("../Heading");
const WindowTitle_1 = require("../WindowTitle");
const ScrollColumn_1 = require("../ScrollColumn");
const hooks_1 = require("../../hooks");
const theme_1 = require("../../theme");
const foundations_1 = require("../../foundations");
const styles_1 = require("./styles");
const Spotlight = ({
  children,
  isFullScreen = false,
  onCancel,
  onClose,
  windowTitle,
  isMultiColumn,
  buttons,
  headerButtons,
  isOpen = false,
  title = '',
  bodyWidth = '384px',
  noPadding = false,
  hideCancelButton = false,
  elevation,
  bodyOverrides,
  alignCenter
}) => {
  const _ref = (0, react_1.useState)(false),
    _ref2 = _slicedToArray(_ref, 2),
    renderChildren = _ref2[0],
    setRenderChildren = _ref2[1];
  const _ref3 = (0, hooks_1.useTranslation)(),
    t = _ref3.t;
  const _ref4 = (0, theme_1.useResponsiveContext)(),
    isCurrentBreakpointLargerThan = _ref4.isCurrentBreakpointLargerThan;
  const fadeOutTimeout = (0, react_1.useRef)();
  (0, react_1.useEffect)(() => {
    if (isOpen && !renderChildren) {
      clearTimeout(fadeOutTimeout.current);
      setRenderChildren(true);
    }
    if (!isOpen && renderChildren) {
      fadeOutTimeout.current = setTimeout(() => setRenderChildren(false), styles_1.TRANSITION_MILLISECONDS);
    }
  }, [isOpen, renderChildren]);
  const forceBodyWidth = isCurrentBreakpointLargerThan('xs') && !isMultiColumn ? bodyWidth : `100%`;
  const cancelButton = isCurrentBreakpointLargerThan('xs') ? react_1.default.createElement(Button_1.Button, {
    size: "l",
    mode: "secondary",
    onClick: onCancel
  }, t('general:cancel')) : react_1.default.createElement(Button_1.Button, {
    size: "l",
    mode: "secondary",
    icon: "Cancel",
    onClick: onCancel
  });
  const closeButton = isCurrentBreakpointLargerThan('xs') ? react_1.default.createElement(Button_1.Button, {
    size: "l",
    mode: "secondary",
    onClick: onClose
  }, t('general:close')) : react_1.default.createElement(Button_1.Button, {
    size: "l",
    mode: "secondary",
    icon: "Cancel",
    onClick: onClose
  });
  function renderTop() {
    function renderTopLeft() {
      if (isCurrentBreakpointLargerThan('xs')) {
        return react_1.default.createElement("div", null);
      }
      return react_1.default.createElement(Heading_1.Heading, null, title);
    }
    function renderTopRight() {
      return react_1.default.createElement(foundations_1.Flex, {
        position: "fixed",
        style: {
          zIndex: elevation ? foundations_1.elevation[elevation] + 1 : foundations_1.elevation.modalLift
        }
      }, headerButtons && react_1.default.createElement(foundations_1.Box, null, headerButtons), !hideCancelButton && cancelButton, onClose && closeButton);
    }
    if (isCurrentBreakpointLargerThan('xs')) {
      return react_1.default.createElement(foundations_1.Flex, {
        flex: "0 0 92px",
        pt: "xl",
        pb: "l",
        "data-testid": "large-header",
        justifyContent: "space-between"
      }, react_1.default.createElement(styles_1.HeaderLeftRightContainer, null, renderTopLeft()), react_1.default.createElement(foundations_1.Box, {
        flex: "none"
      }, react_1.default.createElement(Heading_1.Heading, null, title)), react_1.default.createElement(styles_1.HeaderLeftRightContainer, {
        justifyContent: "flex-end"
      }, renderTopRight()));
    }
    return react_1.default.createElement(foundations_1.Grid, {
      gridTemplateColumns: "1fr 40px",
      style: {
        height: '92px'
      },
      "data-testid": "small-header",
      pt: "xl",
      pb: "l"
    }, react_1.default.createElement(foundations_1.Flex, {
      justifyContent: "center"
    }, renderTopLeft()), react_1.default.createElement(foundations_1.Flex, {
      justifyContent: "flex-end"
    }, renderTopRight()));
  }
  if (!renderChildren) return null;
  const maxHeight = isFullScreen ? 'calc(100vh - 128px)' : 'auto';
  return react_1.default.createElement(react_1.Fragment, null, windowTitle && react_1.default.createElement(WindowTitle_1.WindowTitle, {
    title: windowTitle
  }), react_1.default.createElement(Portal_1.Portal, null, react_1.default.createElement(styles_1.ModalBackground, {
    isOpen: isOpen,
    elevation: elevation
  }, react_1.default.createElement(foundations_1.Absolute, {
    display: "flex",
    overflow: "auto"
  }, react_1.default.createElement(foundations_1.Flex, {
    flex: "1",
    flexDirection: "column",
    ml: "xl",
    mr: "xl",
    pb: "xl"
  }, renderTop(), react_1.default.createElement(foundations_1.Flex, {
    maxHeight: maxHeight,
    flex: "1"
  }, react_1.default.createElement(foundations_1.Flex, {
    flexDirection: "column",
    flex: "1"
  }, react_1.default.createElement(foundations_1.Box, {
    flex: "1",
    position: "relative",
    style: {
      height: '100%'
    }
  }, react_1.default.createElement(styles_1.Container, {
    isOpen: isOpen,
    alignCenter: alignCenter
  }, react_1.default.createElement(styles_1.Body, {
    isFullScreen: isFullScreen,
    bodyWidth: forceBodyWidth,
    isOpen: isOpen,
    style: bodyOverrides
  }, react_1.default.createElement(styles_1.BodyContent, {
    noPadding: noPadding,
    isFullScreen: isFullScreen
  }, isFullScreen ? react_1.default.createElement(ScrollColumn_1.ScrollColumn, {
    primary: true
  }, children) : children), buttons && react_1.default.createElement(styles_1.ActionButtonContainer, {
    isFullScreen: isFullScreen
  }, buttons)))))))))));
};
exports.Spotlight = Spotlight;