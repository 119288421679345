"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getScale = void 0;
const utils_core_1 = require("@oqton/utils-core");
/**
 * Get the time units and pixel scale
 * @param {String} precision MINUTES, HOURS, DAYS (default)
 * @param {Date} startDate
 * @param {Date} endDate
 * @param {Number} singleUnitInPixels
 * @returns {{msInPixels: number, timeUnits: Array, startDate: string, endDate: string }}
 */
const getScale = (precision, startDate, endDate, singleUnitInPixels = 8) => {
  let timeUnits = null;
  let msInPixels = null;
  let splitPrecision;
  let start;
  let end;
  switch (precision) {
    case 'MINUTE':
      {
        timeUnits = (0, utils_core_1.getMinutesArray)(startDate, endDate);
        const MILLISECS_IN_MINUTE = 1000 * 60;
        msInPixels = singleUnitInPixels / MILLISECS_IN_MINUTE;
        splitPrecision = 'seconds';
        start = utils_core_1.date.getStartOf({
          date: startDate,
          unit: 'minute'
        });
        end = utils_core_1.date.getEndOf({
          date: endDate,
          unit: 'minute'
        });
        break;
      }
    case 'HOUR':
      {
        timeUnits = (0, utils_core_1.getHoursArray)(startDate, endDate);
        const MILLISECS_IN_HOUR = 1000 * 60 * 60;
        msInPixels = singleUnitInPixels / MILLISECS_IN_HOUR;
        splitPrecision = 'minutes';
        start = utils_core_1.date.getStartOf({
          date: startDate,
          unit: 'hour'
        });
        end = utils_core_1.date.getEndOf({
          date: endDate,
          unit: 'hour'
        });
        break;
      }
    case 'DAY':
    default:
      {
        timeUnits = (0, utils_core_1.getDaysArray)(startDate, endDate);
        const MILLISECS_IN_DAY = 1000 * 60 * 60 * 24;
        msInPixels = singleUnitInPixels / MILLISECS_IN_DAY;
        splitPrecision = 'hours';
        start = utils_core_1.date.getStartOf({
          date: startDate,
          unit: 'day'
        });
        end = utils_core_1.date.getEndOf({
          date: endDate,
          unit: 'day'
        });
        break;
      }
  }
  // calculate the total width for the time range using the min start and max end in the time units
  const pixelWidth = (0, utils_core_1.getTimeDurationInMs)(utils_core_1.date.getDate(utils_core_1.date.getStartOf({
    date: timeUnits[0],
    unit: 'day'
  })), utils_core_1.date.getDate(utils_core_1.date.getEndOf({
    date: timeUnits[timeUnits.length - 1],
    unit: 'day'
  }))) * msInPixels;
  return {
    pixelWidth,
    msInPixels,
    timeUnits,
    start: utils_core_1.date.getDate(start),
    end: utils_core_1.date.getDate(end),
    startDate,
    endDate,
    precision,
    /** When the precision is days for example the split precision would be by the hour * */
    splitPrecision
  };
};
exports.getScale = getScale;