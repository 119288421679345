"use strict";
/**
 * File with helper functions to deal with urls.
 * E.g. check if a url is external.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.isExternalLink = void 0;
/**
 * Check if the given url is external
 */
const isExternalLink = (link) => /^(https|http|ftp|file|irc)?:\/\/|^www|^\/\/|^(mailto|data):|^cn|^redirect/i.test(link);
exports.isExternalLink = isExternalLink;
