"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getBottomMenuColor = void 0;
const getBottomMenuColor = theme => {
  let menuColor = theme.colors.gradients.menu;
  const hashtag = menuColor.includes('0deg') ? menuColor.indexOf('#') : menuColor.lastIndexOf('#');
  return menuColor.slice(hashtag, hashtag + 7);
};
exports.getBottomMenuColor = getBottomMenuColor;