"use strict";

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
var __createBinding = void 0 && (void 0).__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  var desc = Object.getOwnPropertyDescriptor(m, k);
  if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
    desc = {
      enumerable: true,
      get: function () {
        return m[k];
      }
    };
  }
  Object.defineProperty(o, k2, desc);
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});
var __setModuleDefault = void 0 && (void 0).__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, "default", {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o["default"] = v;
});
var __importStar = void 0 && (void 0).__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
  __setModuleDefault(result, mod);
  return result;
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useGlobalNav = exports.GlobalNavContextProvider = void 0;
const react_1 = __importStar(require("react"));
const __1 = require("../../../");
const getLocalStorageSize_1 = require("./getLocalStorageSize");
// Set the default value and setter function for the context
const GlobalNavContext = (0, react_1.createContext)({});
// As described in the GlobalNavBlock, this Provider must be a parent of the GlobalNavBlock
// in order for the GlobalNavBlock to work
// This component helps from writing duplicate menu open/close handlers for each layout
function GlobalNavContextProvider({
  children,
  isFullScreen,
  designSystem = false,
  disableHiddenView = false
}) {
  const initSize = (0, react_1.useMemo)(getLocalStorageSize_1.getLocalStorageSize, []);
  const _ref = (0, react_1.useState)(initSize),
    _ref2 = _slicedToArray(_ref, 2),
    size = _ref2[0],
    setSize = _ref2[1];
  const _ref3 = (0, react_1.useState)(true),
    _ref4 = _slicedToArray(_ref3, 2),
    displayShadow = _ref4[0],
    setDisplayShadow = _ref4[1];
  const _ref5 = (0, __1.useResponsiveContext)(),
    isCurrentBreakpointLargerThan = _ref5.isCurrentBreakpointLargerThan;
  const enforceHidden = !isCurrentBreakpointLargerThan('s');
  (0, react_1.useEffect)(() => {
    if (initSize === 'Hidden' && disableHiddenView) setSize('Minimal');
    setSize(initSize);
  }, [initSize, disableHiddenView]);
  (0, react_1.useEffect)(() => {
    if (size !== null) localStorage.setItem('localSize', size);
  }, [size]);
  // Default to menu being toggled closed (false)
  // Until a user clicks on the menu button, the breakpoint will determine whether to render the GlobalNav
  const _ref6 = (0, react_1.useState)(false),
    _ref7 = _slicedToArray(_ref6, 2),
    open = _ref7[0],
    setOpen = _ref7[1];
  // set the scrolltop for menu to 0 and track in state
  // so we can persist the scroll top on reload of globalnav
  const _ref8 = (0, react_1.useState)(0),
    _ref9 = _slicedToArray(_ref8, 2),
    scrollTop = _ref9[0],
    setScrollTop = _ref9[1];
  const globalNavRef = (0, react_1.useRef)(null);
  const navbarConfigRef = (0, react_1.useRef)(null);
  (0, react_1.useEffect)(() => {
    const current = globalNavRef.current;
    function handleDocumentClick(e) {
      var _a;
      // We nearly avoided a messy race condition. When the user clicks on the "Menu" button it fires two events
      // 1. this component's window event listener
      // 2. the menu button's click event
      // We can make sure to only set the menuIsToggled to "closed" if it was open, which works with the event order
      if (current && open) {
        //Prevent menu closing when clicking on the menu config button
        if (navbarConfigRef.current !== e.target && !((_a = navbarConfigRef.current) === null || _a === void 0 ? void 0 : _a.contains(e.target))) {
          setOpen(false);
        }
      }
    }
    document.addEventListener('click', handleDocumentClick, false);
    return () => {
      document.removeEventListener('click', handleDocumentClick, false);
    };
  }, [open]);
  const value = (0, react_1.useMemo)(() => ({
    size: enforceHidden ? 'Hidden' : size,
    setSize,
    displayShadow,
    setDisplayShadow,
    scrollTop,
    open,
    setOpen,
    globalNavRef,
    navbarConfigRef,
    setScrollTop,
    isFullScreen,
    designSystem,
    disableHiddenView
  }), [designSystem, disableHiddenView, displayShadow, isFullScreen, open, scrollTop, size, enforceHidden]);
  return react_1.default.createElement(GlobalNavContext.Provider, {
    value: value
  }, children);
}
exports.GlobalNavContextProvider = GlobalNavContextProvider;
const useGlobalNav = () => (0, react_1.useContext)(GlobalNavContext);
exports.useGlobalNav = useGlobalNav;