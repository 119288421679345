"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getShiftTimesForDay = void 0;
const utils_core_1 = require("@oqton/utils-core");
function getShiftTimesForDay(shift, date) {
  const shiftStartTime = utils_core_1.date.parseFormat({
    date: shift.startTime,
    format: 'HH:mm'
  });
  const shiftEndTime = utils_core_1.date.parseFormat({
    date: shift.endTime,
    format: 'HH:mm'
  });
  let shiftStart = utils_core_1.date.set({
    date,
    unit: 'hour',
    value: utils_core_1.date.get({
      date: shiftStartTime,
      unit: 'hour'
    })
  });
  shiftStart = utils_core_1.date.set({
    date: shiftStart,
    unit: 'minute',
    value: utils_core_1.date.get({
      date: shiftStartTime,
      unit: 'minute'
    })
  });
  shiftStart = utils_core_1.date.set({
    date: shiftStart,
    unit: 'second',
    value: utils_core_1.date.get({
      date: shiftStartTime,
      unit: 'second'
    })
  });
  let shiftEnd = utils_core_1.date.set({
    date,
    unit: 'hour',
    value: utils_core_1.date.get({
      date: shiftEndTime,
      unit: 'hour'
    })
  });
  shiftEnd = utils_core_1.date.set({
    date: shiftEnd,
    unit: 'minute',
    value: utils_core_1.date.get({
      date: shiftEndTime,
      unit: 'minute'
    })
  });
  shiftEnd = utils_core_1.date.set({
    date: shiftEnd,
    unit: 'second',
    value: utils_core_1.date.get({
      date: shiftEndTime,
      unit: 'second'
    })
  });
  return {
    shiftStart: utils_core_1.date.getDate(shiftStart),
    shiftEnd: utils_core_1.date.getDate(shiftEnd)
  };
}
exports.getShiftTimesForDay = getShiftTimesForDay;