"use strict";

var __createBinding = void 0 && (void 0).__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  var desc = Object.getOwnPropertyDescriptor(m, k);
  if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
    desc = {
      enumerable: true,
      get: function () {
        return m[k];
      }
    };
  }
  Object.defineProperty(o, k2, desc);
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});
var __setModuleDefault = void 0 && (void 0).__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, "default", {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o["default"] = v;
});
var __importStar = void 0 && (void 0).__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
  __setModuleDefault(result, mod);
  return result;
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Tab = exports.TabsWrapper = void 0;
const styled_components_1 = __importStar(require("styled-components"));
const foundations_1 = require("../../foundations");
exports.TabsWrapper = (0, styled_components_1.default)(foundations_1.Flex)`
  border-bottom: 1px solid ${({
  theme
}) => theme.colors.borderSecondary};
  ${p => p.sticky ? `position: sticky; top: ${isNaN(parseInt(p.sticky.toString())) ? '0' : p.sticky}px; z-index: 1` : ''};
`;
// TODO: Update tabs to use Typography "Tabs" text style
exports.Tab = (0, styled_components_1.default)(foundations_1.Box).attrs({
  as: 'button'
})`
  ${({
  theme,
  disabled,
  selected
}) => (0, styled_components_1.css)`
    appearance: none;
    text-decoration: none;
    text-align: center;
    vertical-align: middle;
    display: inline-block;
    margin: 0;
    background: none;
    border: none;
    border-bottom: 2px solid transparent;
    border-radius: 0;
    font-family: inherit;
    font-weight: 500;
    line-height: 16px;
    outline: none;
    opacity: 1;
    cursor: pointer;
    font-size: 13.5px;
    padding: ${theme.spacing.xs}px 0;
    color: ${theme.colors.secondary};
    :not(:last-child) {
      margin-right: ${theme.spacing.l}px;
    }

    :disabled {
      opacity: 1;
    }

    :focus,
    :not([disabled]):hover {
      box-shadow: none;
      color: ${theme.colors.primary};
    }

    &[aria-selected='true'] {
      border-bottom: 2px solid ${theme.colors.accent};
      color: ${theme.colors.primary};
    }

    ${selected ? (0, styled_components_1.css)`
          border-bottom: 2px solid ${theme.colors.accent};
          color: ${theme.colors.primary};
        ` : ''}

    &[disabled] {
      color: ${theme.colors.borderSecondary};
    }

    ${disabled ? (0, styled_components_1.css)`
          color: ${theme.colors.borderSecondary};
        ` : ''}
  `}
`;