"use strict";

const _excluded = ["align", "listWidth", "menuItems", "onSelect", "selected", "menuHorizontalAlign", "menuHorizontalOffset", "menuVerticalAlign", "menuVerticalOffset"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }
function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }
var __createBinding = void 0 && (void 0).__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  var desc = Object.getOwnPropertyDescriptor(m, k);
  if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
    desc = {
      enumerable: true,
      get: function () {
        return m[k];
      }
    };
  }
  Object.defineProperty(o, k2, desc);
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});
var __setModuleDefault = void 0 && (void 0).__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, "default", {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o["default"] = v;
});
var __importStar = void 0 && (void 0).__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
  __setModuleDefault(result, mod);
  return result;
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DropdownList = void 0;
const react_1 = __importStar(require("react"));
const dropdownHelpers_1 = require("../../utils/dropdownHelpers");
const ItemGroup_1 = require("./ItemGroup");
const index_1 = require("./index");
const DropdownList = _ref => {
  let _ref$align = _ref.align,
    align = _ref$align === void 0 ? 'right' : _ref$align,
    listWidth = _ref.listWidth,
    menuItems = _ref.menuItems,
    onSelect = _ref.onSelect,
    selected = _ref.selected,
    menuHorizontalAlign = _ref.menuHorizontalAlign,
    menuHorizontalOffset = _ref.menuHorizontalOffset,
    menuVerticalAlign = _ref.menuVerticalAlign,
    menuVerticalOffset = _ref.menuVerticalOffset,
    rest = _objectWithoutProperties(_ref, _excluded);
  const ref = (0, react_1.useRef)();
  const _ref2 = (0, react_1.useState)(-1),
    _ref3 = _slicedToArray(_ref2, 2),
    activeIndex = _ref3[0],
    setActiveIndex = _ref3[1];
  const newMenuItems = (0, dropdownHelpers_1.addIndex)(menuItems);
  (0, react_1.useEffect)(() => {
    // add key up to close menu on exit
    const current = ref.current;
    current === null || current === void 0 ? void 0 : current.focus();
    const closeOnEsc = e => {
      if (e.keyCode === 27) {
        onSelect();
      }
    };
    if (typeof window !== `undefined`) {
      document.addEventListener('keyup', closeOnEsc);
    }
    return () => {
      if (typeof window !== `undefined`) {
        document.removeEventListener('keyup', closeOnEsc);
      }
    };
  }, [onSelect]);
  const handleFocus = (0, react_1.useCallback)((e, index) => {
    if (ref.current && ref.current.querySelector) {
      const focusItem = ref.current.querySelector(`button#ListItemButton-${index}`);
      if (focusItem) {
        focusItem.focus();
        setActiveIndex(index);
      }
    }
  }, []);
  const handleOnKeyDown = (0, react_1.useCallback)(e => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    switch (e.key) {
      case 'Tab':
        {
          if (!e.shiftKey) {
            e.preventDefault();
            e.stopPropagation();
            const index = (0, dropdownHelpers_1.getNextSelectableItem)(activeIndex, menuItems);
            (_b = (_a = ref.current) === null || _a === void 0 ? void 0 : _a.querySelectorAll('button')[index]) === null || _b === void 0 ? void 0 : _b.focus();
            setActiveIndex(index);
          } else {
            e.preventDefault();
            e.stopPropagation();
            const index = (0, dropdownHelpers_1.getPreviousSelectableItem)(activeIndex, menuItems);
            (_d = (_c = ref.current) === null || _c === void 0 ? void 0 : _c.querySelectorAll('button')[index]) === null || _d === void 0 ? void 0 : _d.focus();
            setActiveIndex(index);
          }
          break;
        }
      case 'ArrowDown':
        {
          e.preventDefault();
          e.stopPropagation();
          const index = (0, dropdownHelpers_1.getNextSelectableItem)(activeIndex, menuItems);
          (_f = (_e = ref.current) === null || _e === void 0 ? void 0 : _e.querySelectorAll('button')[index]) === null || _f === void 0 ? void 0 : _f.focus();
          setActiveIndex(index);
          break;
        }
      case 'ArrowUp':
        {
          e.preventDefault();
          e.stopPropagation();
          const index = (0, dropdownHelpers_1.getPreviousSelectableItem)(activeIndex, menuItems);
          (_h = (_g = ref.current) === null || _g === void 0 ? void 0 : _g.querySelectorAll('button')[index]) === null || _h === void 0 ? void 0 : _h.focus();
          setActiveIndex(index);
          break;
        }
      default:
        break;
    }
  }, [activeIndex, menuItems]);
  return react_1.default.createElement(index_1.DropdownListStyles.PositionedList, _objectSpread({
    tabIndex: 0,
    ref: ref,
    onKeyDown: handleOnKeyDown,
    role: "menu",
    align: align,
    listWidth: listWidth,
    "data-testid": "dropdown-menu",
    menuHorizontalAlign: menuHorizontalAlign,
    menuHorizontalOffset: menuHorizontalOffset,
    menuVerticalAlign: menuVerticalAlign,
    menuVerticalOffset: menuVerticalOffset
  }, rest), newMenuItems.map((item, i) => react_1.default.createElement(ItemGroup_1.ItemGroup, _objectSpread({
    group: i,
    selected: selected,
    activeIndex: activeIndex,
    role: "menuitem",
    key: `menuitem-${i}`,
    onSelect: onSelect,
    listWidth: listWidth,
    handleFocus: handleFocus
  }, item))));
};
exports.DropdownList = DropdownList;