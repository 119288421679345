"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Fahrenheit = exports.Kelvin = exports.Celsius = void 0;
const TemperatureUnitDefinition_1 = require("./../UnitTypes/TemperatureUnitDefinition");
exports.Celsius = new TemperatureUnitDefinition_1.TemperatureUnitDefinition({
    name: 'CELSIUS',
    label: 'Celsius',
    symbol: '°C',
    scaleToSI: 1,
    offsetToSI: 273.15
});
exports.Kelvin = new TemperatureUnitDefinition_1.TemperatureUnitDefinition({
    name: 'KELVIN',
    label: 'Kelvin',
    symbol: 'K',
    scaleToSI: 1,
    offsetToSI: 0
});
exports.Fahrenheit = new TemperatureUnitDefinition_1.TemperatureUnitDefinition({
    name: 'FAHRENHEIT',
    label: 'Fahrenheit',
    symbol: '°F',
    scaleToSI: 5 / 9,
    offsetToSI: (459.67 * 5) / 9
});
