"use strict";

var __createBinding = void 0 && (void 0).__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  var desc = Object.getOwnPropertyDescriptor(m, k);
  if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
    desc = {
      enumerable: true,
      get: function () {
        return m[k];
      }
    };
  }
  Object.defineProperty(o, k2, desc);
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});
var __setModuleDefault = void 0 && (void 0).__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, "default", {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o["default"] = v;
});
var __importStar = void 0 && (void 0).__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
  __setModuleDefault(result, mod);
  return result;
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DropdownMenuPopover = void 0;
const react_1 = __importStar(require("react"));
const Portal_1 = require("../Portal");
const MenuInner_1 = require("./MenuInner");
exports.DropdownMenuPopover = (0, react_1.forwardRef)(({
  placement = 'TOP',
  children,
  onClose
}, ref) => {
  return react_1.default.createElement(Portal_1.Portal, {
    maintainPosition: true
  }, parentRect => {
    if (!parentRect || (parentRect === null || parentRect === void 0 ? void 0 : parentRect.top) === undefined) return null;
    return react_1.default.createElement(MenuInner_1.MenuInner, {
      onClose: onClose,
      ref: ref,
      placement: placement,
      parentRect: parentRect
    }, children);
  });
});