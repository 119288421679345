"use strict";

var __createBinding = void 0 && (void 0).__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  var desc = Object.getOwnPropertyDescriptor(m, k);
  if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
    desc = {
      enumerable: true,
      get: function () {
        return m[k];
      }
    };
  }
  Object.defineProperty(o, k2, desc);
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});
var __setModuleDefault = void 0 && (void 0).__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, "default", {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o["default"] = v;
});
var __importStar = void 0 && (void 0).__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
  __setModuleDefault(result, mod);
  return result;
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ListItem = void 0;
const react_1 = __importStar(require("react"));
const index_1 = require("../index");
const index_2 = require("./index");
const ListItem = props => {
  const ref = (0, react_1.useRef)();
  return react_1.default.createElement("li", {
    key: `listItem${props.dataIndex}`
  }, react_1.default.createElement(index_1.Link, {
    href: !props.disabled ? props.href : undefined,
    target: props.target,
    noLinkStyles: true
  }, react_1.default.createElement(index_2.DropdownListStyles.ListItemButton, {
    title: props.title,
    id: `ListItemButton-${props.dataIndex}`,
    ref: ref,
    "data-index": "0",
    selected: props.isSelected,
    "aria-disabled": props.disabled,
    type: "button",
    rightIcon: !!props.rightIcon,
    active: props.active,
    onMouseEnter: e => props.handleFocus && props.handleFocus(e, props.dataIndex),
    onClick: e => {
      if (props.onClick && !props.disabled) {
        e.stopPropagation();
        e.preventDefault();
        props.onClick();
        if (props.onSelect) {
          props.onSelect();
        }
      }
    }
  }, props.icon && react_1.default.createElement(index_2.DropdownListStyles.ListItemIcon, null, react_1.default.createElement(index_1.Icon, {
    name: props.icon,
    size: "m"
  })), typeof props.selected === 'number' && (props === null || props === void 0 ? void 0 : props.selected) >= 0 ? react_1.default.createElement(index_2.DropdownListStyles.ListItemIcon, {
    isSelected: props.isSelected
  }, props.isSelected ? react_1.default.createElement(index_1.Icon, {
    name: "Done",
    size: "m"
  }) : react_1.default.createElement("div", {
    style: {
      width: '16px'
    }
  })) : null, react_1.default.createElement(index_2.DropdownListStyles.ListItemText, {
    active: props.active,
    listWidth: props.listWidth
  }, props.text || props.children), !!props.rightIcon && props.rightIcon)));
};
exports.ListItem = ListItem;