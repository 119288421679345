"use strict";

const _excluded = ["children", "defaultDropdownMenuItems", "useMaxWidth"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }
function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }
var __createBinding = void 0 && (void 0).__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  var desc = Object.getOwnPropertyDescriptor(m, k);
  if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
    desc = {
      enumerable: true,
      get: function () {
        return m[k];
      }
    };
  }
  Object.defineProperty(o, k2, desc);
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});
var __setModuleDefault = void 0 && (void 0).__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, "default", {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o["default"] = v;
});
var __importStar = void 0 && (void 0).__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
  __setModuleDefault(result, mod);
  return result;
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CollapsibleButtonGroup = void 0;
const react_1 = __importStar(require("react"));
const DropdownButton_1 = require("../DropdownButton");
const hooks_1 = require("../../hooks");
const foundations_1 = require("../../foundations");
const ButtonGroup_1 = require("./ButtonGroup");
const utils_1 = require("./utils");
const useCollapsibleButtonGroupSizes_1 = require("./useCollapsibleButtonGroupSizes");
const createMenuItem = button => {
  return {
    text: button.props.text,
    onClick: button.props.onClick,
    href: button.props.href,
    disabled: button.props.disabled,
    icon: button.props.icon,
    children: button.props.children
  };
};
const validateMenuItem = button => {
  var _a, _b, _c, _d;
  return typeof ((_a = button === null || button === void 0 ? void 0 : button.props) === null || _a === void 0 ? void 0 : _a.text) === 'string' && button.props.text.length > 0 || (
  //To Allow buttons without text but with icon.
  (_b = button === null || button === void 0 ? void 0 : button.props) === null || _b === void 0 ? void 0 : _b.icon) || !!((_c = button === null || button === void 0 ? void 0 : button.props) === null || _c === void 0 ? void 0 : _c.children) ||
  // bit of a hack to allow wrapper components to work
  // @ts-expect-error
  (((_d = button === null || button === void 0 ? void 0 : button.type) === null || _d === void 0 ? void 0 : _d.name) || '').endsWith('Button');
};
function CollapsibleButtonGroup(_ref) {
  let children = _ref.children,
    _ref$defaultDropdownM = _ref.defaultDropdownMenuItems,
    defaultDropdownMenuItems = _ref$defaultDropdownM === void 0 ? [] : _ref$defaultDropdownM,
    useMaxWidth = _ref.useMaxWidth,
    rest = _objectWithoutProperties(_ref, _excluded);
  const _ref2 = (0, hooks_1.useResizeObserver)(),
    ref = _ref2.ref,
    width = _ref2.width;
  const _ref3 = (0, hooks_1.useTheme)(),
    spacing = _ref3.spacing;
  const _children = react_1.Children.map(children, child => {
    if (child) {
      return child;
    }
  });
  const buttons = (0, react_1.useMemo)(() => {
    return react_1.Children.toArray(_children);
  }, [_children]);
  const _ref4 = (0, useCollapsibleButtonGroupSizes_1.useCollapsibleButtonGroupSizes)({
      buttons,
      buttonSpacing: spacing.m
    }),
    element = _ref4.element,
    sizes = _ref4.sizes,
    hasCalculatedSizes = _ref4.hasCalculatedSizes;
  const _ref5 = (0, react_1.useMemo)(() => {
      let buttonsOutput = [];
      let itemsOutput = [];
      const startingButtons = react_1.Children.toArray(children).filter(b => validateMenuItem(b));
      // if no width then not much point showing anything
      if (width > 0 || useMaxWidth) {
        // work out the amount of buttons to keep
        const buttonsToKeep = (0, utils_1.getAmountOfButtonsToKeep)({
          widthsArray: sizes,
          buttonCount: startingButtons.length,
          hasDropdown: defaultDropdownMenuItems.length > 0,
          width,
          useMaxWidth
        });
        // get an array of buttons that need to be menu items
        const menuItemArray = startingButtons.splice(buttonsToKeep);
        // add the buttons as menu items first before any defaults
        if (menuItemArray.length > 0) {
          itemsOutput.push({
            items: menuItemArray.map(createMenuItem)
          });
        }
        // add the defaults in at the bottom
        if (defaultDropdownMenuItems.length > 0) {
          itemsOutput = [...itemsOutput, ...defaultDropdownMenuItems];
        }
        // set buttons to keep to button list
        buttonsOutput = startingButtons.splice(0, buttonsToKeep);
      }
      return [buttonsOutput, itemsOutput];
      // eslint-disable-next-line
    }, [width, _children, defaultDropdownMenuItems]),
    _ref6 = _slicedToArray(_ref5, 2),
    buttonList = _ref6[0],
    menuItems = _ref6[1];
  const buttonGroup = buttonList.map((button, i) => {
    return react_1.default.cloneElement(button, {
      mr: i === buttonList.length - 1 ? 0 : 'xs',
      size: 'l',
      mode: button.props.mode || 'secondary'
    });
  });
  // check that subItems have length too otherwise we get empty dropdown
  if (menuItems.length > 0 && menuItems.some(group => group.items.length > 0)) {
    buttonGroup.push(
    // @ts-expect-error
    react_1.default.createElement(DropdownButton_1.DropdownButton, {
      key: "dropdown",
      icon: "MoreVertical",
      mode: "secondary",
      menuItems: menuItems
    }));
  }
  return react_1.default.createElement(react_1.default.Fragment, null, react_1.default.createElement(foundations_1.Flex, _objectSpread({
    "data-testid": "collapsible-button-group",
    ref: ref,
    justifyContent: "flex-end",
    flex: 1
  }, rest), hasCalculatedSizes && react_1.default.createElement(ButtonGroup_1.ButtonGroup, null, buttonGroup)), element);
}
exports.CollapsibleButtonGroup = CollapsibleButtonGroup;