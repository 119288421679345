"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Millilitre = exports.Litre = exports.CubicInch = exports.CubicFeet = exports.CubicMillimetre = exports.CubicCentimetre = exports.CubicDecimetre = exports.CubicMetre = void 0;
const VolumeUnitDefinition_1 = require("./../UnitTypes/VolumeUnitDefinition");
exports.CubicMetre = new VolumeUnitDefinition_1.VolumeUnitDefinition({
    name: 'CUBICMETRE',
    label: 'CubicMetre',
    symbol: 'm³',
    scaleToSI: 1
});
exports.CubicDecimetre = new VolumeUnitDefinition_1.VolumeUnitDefinition({
    name: 'CUBICDECIMETRE',
    label: 'CubicDecimetre',
    symbol: 'dm³',
    scaleToSI: 0.001
});
exports.CubicCentimetre = new VolumeUnitDefinition_1.VolumeUnitDefinition({
    name: 'CUBICCENTIMETRE',
    label: 'CubicCentimetre',
    symbol: 'cm³',
    scaleToSI: 1e-6
});
exports.CubicMillimetre = new VolumeUnitDefinition_1.VolumeUnitDefinition({
    name: 'CUBICMILLIMETRE',
    label: 'CubicMillimetre',
    symbol: 'mm³',
    scaleToSI: 1e-9
});
exports.CubicFeet = new VolumeUnitDefinition_1.VolumeUnitDefinition({
    name: 'CUBICFEET',
    label: 'CubicFeet',
    symbol: 'ft³',
    scaleToSI: 0.02831685
});
exports.CubicInch = new VolumeUnitDefinition_1.VolumeUnitDefinition({
    name: 'CUBICINCH',
    label: 'CubicInch',
    symbol: 'in³',
    scaleToSI: 1.6387064e-5
});
exports.Litre = new VolumeUnitDefinition_1.VolumeUnitDefinition({
    name: 'LITRE',
    label: 'Litre',
    symbol: 'l',
    scaleToSI: 0.001
});
exports.Millilitre = new VolumeUnitDefinition_1.VolumeUnitDefinition({
    name: 'MILLILITRE',
    label: 'Millilitre',
    symbol: 'ml',
    scaleToSI: 1e-6
});
